import React from 'react';
import PropTypes from 'prop-types';
import { trim } from 'lodash';

//mui
import { Box, Grid, Typography, Button, TextField, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

//app
import { DataGridTable, Layout, BootstrapTooltip } from 'components';
import { consts } from 'globalsData';
import { theme } from 'theme';

ConfigurationView.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  searchText: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  rowCount: PropTypes.number,
  handlers: PropTypes.shape({
    handleOnSearch: PropTypes.func.isRequired,
    handleCreateConfiguration: PropTypes.func.isRequired,
    setSearchText: PropTypes.func.isRequired,
    onPageChange: PropTypes.func,
    handleSearchReset: PropTypes.func.isRequired,
    handleCreateSSOSettings: PropTypes.func.isRequired,
  }),
};

function ConfigurationView({ columns, rows, handlers, searchText, page, pageSize, rowCount }) {
  const { handleOnSearch, handleCreateConfiguration, onPageChange, handleSearchReset, setSearchText, handleCreateSSOSettings } = handlers;
  return (
    <Layout>
      <Grid container pb={4} alignItems="center">
        <Grid item xs={6}>
          <Typography variant="h1" fontWeight={700} fontSize="1.75rem">
            {consts.FEATURE_LIST_OBJ.configuration}
          </Typography>
        </Grid>
        <Grid item xs={6} textAlign="right">
          <Button
            variant="contained"
            sx={{
              backgroundColor: theme.palette.primary.main,
              textTransform: 'unset',
              borderRadius: '5px',
              fontWeight: 600,
              letterSpacing: '1px',
              height: '40px',
              mr: '1rem',
              boxShadow: 'none',
            }}
            onClick={(e) => {
              handleCreateConfiguration(e);
            }}
          >
            New Configuration
          </Button>
          <Button
            variant="outlined"
            sx={{
              color: theme.palette.primary.main,
              textTransform: 'unset',
              borderRadius: '5px',
              borderWidth: '2px',
              fontWeight: 600,
              letterSpacing: '1px',
              height: '40px',
              '&:hover': {
                borderWidth: '2px',
              },
            }}
            onClick={(e) => {
              handleCreateSSOSettings(e);
            }}
          >
            SAML SSO Settings
          </Button>
        </Grid>
      </Grid>
      <Box
        sx={{
          backgroundColor: theme.palette.primary.white,
          boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.2)',
        }}
      >
        <Grid
          container
          alignItems="center"
          p={1}
          borderTop="1px solid rgba(224, 224, 224, 1)"
          borderLeft="1px solid rgba(224, 224, 224, 1)"
          borderRight="1px solid rgba(224, 224, 224, 1)"
        >
          <Grid item xs={8}>
            <Typography fontWeight={700} color={theme.palette.primary.tableTopHeader} pl={1}>
              {consts.FEATURE_LIST_OBJ.configuration}
            </Typography>
          </Grid>
          <Grid item xs={4} textAlign="right" pr={1}>
            <Box component="form" onSubmit={handleOnSearch}>
              <BootstrapTooltip title="Search ID, Product, Service, Platform, Object Type">
                <TextField
                  variant="outlined"
                  size="small"
                  id="search-box"
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(trim(e.target.value));
                  }}
                  placeholder="Search"
                  inputProps={{
                    'data-testid': 'search-box',
                  }}
                  InputProps={{
                    endAdornment: (
                      <>
                        <IconButton
                          title="Clear"
                          aria-label="Clear"
                          size="small"
                          style={{
                            visibility: searchText ? 'visible' : 'hidden',
                            paddingRight: '1px',
                            margin: '0',
                            fontSize: '1.25rem',
                          }}
                          onClick={handleSearchReset}
                        >
                          <ClearIcon fontSize="small" color="action" />
                        </IconButton>
                        <IconButton type="submit" sx={{ p: '4px', fontSize: '1.25rem' }} disabled={!searchText} aria-label="search-icon">
                          <SearchIcon fontSize="small" color="action" />
                        </IconButton>
                      </>
                    ),
                  }}
                  sx={{
                    width: { xs: 1, sm: 'auto' },
                    '& .MuiSvgIcon-root': {
                      mr: 0.5,
                    },
                  }}
                />
              </BootstrapTooltip>
            </Box>
          </Grid>
        </Grid>
        <DataGridTable
          rows={rows}
          columns={columns}
          rowCount={rowCount}
          page={page}
          pageSize={pageSize}
          paginationMode="server"
          uniqRowId="id"
          height={330}
          isSearchable={false}
          checkboxSelection={false}
          emptyRowsText="No configuration(s) found"
          handlers={{ rowClickHandler: () => {}, onPageChange }}
        />
      </Box>
    </Layout>
  );
}

export default React.memo(ConfigurationView);
