import { api } from 'utils';
import { snackbarNotification, removeLoader, addLoader } from 'stores';

export const updateUserRole = (accessInfo, AdminEmailId) => async (dispatch) => {
  dispatch(addLoader({ key: 'updateUserRole', message: 'Updating User Role...' }));
  try {
    try {
      const res = await api.put({
        endpoint: `${process.env.REACT_APP_CBT_SERVICE}`,
        path: 'updateAdmin',
        params: { email: AdminEmailId },
        data: accessInfo,
      });
      return res;
    } catch (error) {
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      return error;
    }
  } finally {
    dispatch(removeLoader('updateUserRole'));
  }
};
