import { api } from 'utils';
import { consts } from 'globalsData';
import {
  snackbarNotification,
  removeLoader,
  addLoader,
  getSearchedConfigurationTableRowsSuccess,
  isSearchedTableSuccess,
  getOnEditSearchConfigResult,
} from 'stores';

export const searchConfiguration = (searchKey, paginationModel, editMode) => async (dispatch) => {
  dispatch(addLoader('searchingConfiguration'));

  try {
    try {
      const res = await api.get({
        endpoint: `${process.env.REACT_APP_CBT_SERVICE}`,
        path: 'admin/config/search',
        params: { searchKey, page: paginationModel.page, size: paginationModel.pageSize },
      });
      if (editMode === consts.EDIT_SEARCH) {
        dispatch(getOnEditSearchConfigResult(res?.data));
      } else {
        dispatch(getSearchedConfigurationTableRowsSuccess(res?.data));
        dispatch(isSearchedTableSuccess(true));
      }
      return res;
    } catch (error) {
      dispatch(snackbarNotification(error?.response?.data?.message || 'Failed search configuration(s)', 'error'));
      return error;
    }
  } finally {
    dispatch(removeLoader('searchingConfiguration'));
  }
};
