export const showModal = (payload) => {
  return {
    type: 'MODAL_SHOW',
    payload,
  };
};
export const hideModal = (type) => {
  return {
    type: 'MODAL_HIDE',
    payload: type,
  };
};
export const addLoader = (payload) => {
  return {
    type: 'LOADER_ADD',
    payload,
  };
};
export const removeLoader = (payload) => {
  return {
    type: 'LOADER_REMOVE',
    payload,
  };
};
export const expandSidebar = (payload) => {
  return {
    type: 'SIDEBAR_EXPAND',
    payload,
  };
};
export const collapseSidebar = () => {
  return {
    type: 'SIDEBAR_COLLAPSE',
  };
};

export const addSnackbarNotificaiton = (payload) => {
  return {
    type: 'NOTIFICATION_ADD',
    payload,
  };
};

export const hideSnackbarNotification = (payload) => {
  return {
    type: 'NOTIFICATION_HIDE',
    payload,
  };
};

export const snackbarNotification =
  (str = '', type = 'info', { data, delay, keepAfterUrlChange } = {}) =>
  (dispatch, getState) => {
    const defaultSnackbarNotification = {
      key: new Date().getTime(),
      visible: true,
      type,
      data,
      delay,
      message: str,
      keepAfterUrlChange,
    };
    const currentSnackbarNotification = getState().ui.notification.queue[0];
    if (!currentSnackbarNotification) {
      dispatch(addSnackbarNotificaiton(defaultSnackbarNotification));
    } else {
      dispatch(hideSnackbarNotification(currentSnackbarNotification.key));
      dispatch(addSnackbarNotificaiton(defaultSnackbarNotification));
    }
  };

export const removeSnackbarNotification = (payload) => {
  return {
    type: 'NOTIFICATION_REMOVE',
    payload,
  };
};
