import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { useNavigate } from 'react-router';

// app
import { HeaderView } from './Header.view';
import { expandSidebar, setUserLoggedIn } from 'stores';
import { config, consts, useMedia } from 'globalsData';

export default function Header() {
  const { mobile } = useMedia();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userDetails = useSelector((state) => get(state, 'user.userDetails', {}));
  const userEmailId = userDetails?.email;
  const drawerOpen = useSelector((state) => get(state, 'ui.sidebar.expanded', false));

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    dispatch(expandSidebar(!drawerOpen));
  };

  const appLogoClick = () => {
    navigate(`${config.routes.requestTracking.root}/${consts.MY_REQUEST}`);
  };

  const logout = () => {
    localStorage.clear();
    dispatch(setUserLoggedIn(false));
    navigate(config.routes.home.root);
  };

  return (
    <HeaderView
      anchorEl={anchorEl}
      userEmail={userEmailId || 'test@gmail.com'}
      mobile={mobile}
      handlers={{ handleMenu, handleClose, handleDrawerOpen, appLogoClick, logout }}
    />
  );
}
