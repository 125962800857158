import moment from 'moment';

//app
import { api } from 'utils';

const app = {
  escapeRegExp: (value) => {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  },
  getConfig: () => {
    return api.get('/config/config.json');
  },
  getEllipsisString: (fullString, ellipsisLength) => {
    if (!fullString || !ellipsisLength || isNaN(ellipsisLength) || typeof fullString !== 'string') return;
    if (fullString.length > ellipsisLength) return `${fullString.slice(0, ellipsisLength).trim()}...`;
    return fullString;
  },
  formatDateTime: (dateTime) => {
    const formattedDateTime = moment.utc(dateTime).local().format('MMMM Do YYYY, h:mm a').split(' ');
    const month = formattedDateTime[0];
    const date = formattedDateTime[1].split('th')[0];
    const year = formattedDateTime[2].split(',')[0];
    const time = `${formattedDateTime[3]} ${formattedDateTime[4].toUpperCase()}`;
    return `${month} ${date}, ${year} at ${time}`;
  },
};

export default app;
