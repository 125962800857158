import React from 'react';

//app
import { useMedia } from 'globalsData';

//mui
import { Box } from '@mui/material';

export default function Layout({ children }) {
  const { mobile } = useMedia();

  return (
    <Box py={2} px={!mobile ? 3 : 2}>
      {children}
    </Box>
  );
}
