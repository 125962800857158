const generic = {
  isInValidOrEmptyArray: (arr) => {
    const isArray = arr && Array.isArray(arr);
    const hasItems = isArray && arr.length > 0;

    return !isArray || !hasItems;
  },
  isValidArray: (arr, checkIfArrayHasItems) => {
    const isArray = arr && Array.isArray(arr);
    const hasItems = checkIfArrayHasItems ? isArray && arr.length > 0 : true;

    return Boolean(isArray && hasItems);
  },
  isValidObject: (obj, checkIfObjHasProperty) => {
    const isObject = obj && typeof obj === 'object' && !Array.isArray(obj);

    const hasProperty = checkIfObjHasProperty !== undefined ? isObject && obj.hasOwnProperty(checkIfObjHasProperty) : true;

    return isObject && hasProperty;
  },
  getAutocompleteValue: (value, options, optionKey) => {
    if (!value || Array.isArray(options) || !optionKey) return null;

    const result = options.find((option) => option[optionKey] === value);
    return result ? result : null;
  },
  getAutocompleteMultipleValues: (values, options, optionKey) => {
    if (!Array.isArray(values) || !Array.isArray(options) || !optionKey) return [];

    const result = values
      ?.map((value) => options.find((option) => option[optionKey] === value))
      .filter((value) => generic.isValidObject(value, optionKey));

    return result ? result : [];
  },
  isFunction: (method) => {
    return method && typeof method === 'function';
  },
  getLocalTimeFromTimeStamp: (timeStamp) => {
    if (!timeStamp || isNaN(timeStamp)) return '';
    const dateObject = new Date(timeStamp);
    return dateObject?.toLocaleString();
  },
  getDDMMYYYFromTimeStamp: (timeStamp) => {
    if (!timeStamp || isNaN(timeStamp)) return '';
    const dateObject = new Date(timeStamp);
    return dateObject?.toLocaleDateString('en-in');
  },
  getMMDDFromTimeStamp: (timeStamp) => {
    const date = generic.getDDMMYYYFromTimeStamp(timeStamp);
    const datearray = date.split('/');
    return `${datearray[1]}/${datearray[0]}/${datearray[2]}`;
  },
  capitalize: (str, lower = false) => {
    if (typeof str !== 'string') return '';
    if (lower === true) return str.toLowerCase();
    // return str.replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase());
    return (
      str
        .replace(/([A-Z])/g, ' $1')
        // uppercase the first character
        .replace(/^./, (rstr) => rstr.toUpperCase())
    );
  },
};

export default generic;
