import { api } from 'utils';
import { snackbarNotification, removeLoader, addLoader, getCurrentRequestDetailsSuccess, getCurrentRequestDetailsError } from 'stores';

export const getRequestDetails = (tpid) => async (dispatch) => {
  dispatch(addLoader({ key: 'loadingRequestDetails', message: 'Loading Request Details...' }));

  try {
    try {
      const res = await api.get({
        endpoint: `${process.env.REACT_APP_CBT_SERVICE}`,
        path: `target/platform/request/${tpid}`,
      });
      if (res?.status === 200) {
        dispatch(getCurrentRequestDetailsSuccess(res?.data));
      } else {
        dispatch(getCurrentRequestDetailsError('Failed to load request details'));
      }
      return res;
    } catch (error) {
      dispatch(getCurrentRequestDetailsError('Failed to load request details'));
      dispatch(snackbarNotification(error?.response?.data?.message || 'Failed to fetch request details', 'error'));
      return error;
    }
  } finally {
    dispatch(removeLoader('loadingRequestDetails'));
  }
};
