import { api } from 'utils';
import { snackbarNotification, removeLoader, addLoader, getFilteredRequestResult } from 'stores';
import jwtDecode from 'jwt-decode';
import { accessToken } from 'utils/api/api';

export const getProductServiceFilteredData = (product, service, filterFlag, paginationModel) => async (dispatch) => {
  const token = accessToken();
  const decoded = jwtDecode(token);
  const email = decoded?.sub;

  dispatch(addLoader({ key: 'loadingFilteredRequest', message: 'Loading Filtered Request(s)...' }));

  const getQueryParameters = () => {
    return {
      ...(product && { product }),
      ...(service && { service }),
      filterFlag,
      email,
      page: paginationModel.page,
      size: paginationModel.pageSize,
    };
  };

  try {
    try {
      const res = await api.get({
        endpoint: `${process.env.REACT_APP_CBT_SERVICE}`,
        path: 'request/filter',
        params: getQueryParameters(),
      });
      dispatch(getFilteredRequestResult(res?.data));
      return res;
    } catch (error) {
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      return error;
    }
  } finally {
    dispatch(removeLoader('loadingFilteredRequest'));
  }
};
