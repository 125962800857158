import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import * as Yup from 'yup';
import { v4 as uuidv4 } from 'uuid';

//app
import * as utils from 'utils';
import { AddEditRequestFormView } from './AddEditRequestForm.view';
import { consts } from 'globalsData';
import { createRequest, updateRequest, snackbarNotification, getObjectTypes, getObjectConfiguration } from 'stores';

AddEditRequestForm.propTypes = {
  data: PropTypes.object,
  submitHandler: PropTypes.func.isRequired,
  cancelButtonLabel: PropTypes.string.isRequired,
  cancelHandler: PropTypes.func.isRequired,
};

export default function AddEditRequestForm({ data, submitHandler, cancelButtonLabel, cancelHandler }) {
  const [dropdownValues, setDropdownValues] = useState({ product: '', service: '' });
  const [isDropDownValuesSelected, setIsDropDownValuesSelected] = useState({ product: true, service: true });
  const [currentProducts, setCurrentProducts] = useState([]);
  const [currentServices, setCurrentServices] = useState([]);
  const [issueTypeList, setIssueTypeList] = useState([]);
  const [currentIssueType, setCurrentIssueType] = useState('');
  const [mandatoryFieldList, setMandatoryFieldList] = useState([]);
  const [mandatoryFieldValues, setMandatoryFieldValues] = useState([]);
  const [currentConfigId, setCurrentConfigId] = useState(null);

  const dispatch = useDispatch();
  const requestProductsServices = useSelector((state) => get(state, 'requestTracking.requestProductsServices'));
  const userDetails = useSelector((state) => get(state, 'user.userDetails'));
  const userEmail = userDetails?.email;

  const requestId = data?.id;
  const isUpdateData = requestId ? Boolean(data?.product && data?.service) : false;
  const productDefultValue = isUpdateData ? data?.product : '';
  const serviceDefaultValue = isUpdateData ? data?.service : '';
  const summaryDefaultValue = isUpdateData ? data?.title : '';
  const requestDescriptionDefaultValue = isUpdateData ? data?.description : '';

  const getFiltereFieldDataToAppend = (allFields, objectType) => {
    const filteredField = allFields
      ?.flatMap((i) => i)
      ?.filter((item) => item?.cbtObjectTypeId?.objectType === objectType)
      ?.map((item) => {
        let filteredDataFieldsToAppend = { ...item, issueType: item?.cbtObjectTypeId?.objectType };
        delete filteredDataFieldsToAppend['cbtObjectTypeId'];
        return filteredDataFieldsToAppend;
      });

    const fieldIfDisplayFlagIsTrue = filteredField?.filter((d) => d?.displayFlag);

    if (fieldIfDisplayFlagIsTrue?.length) {
      return fieldIfDisplayFlagIsTrue?.map((a) => ({
        id: uuidv4(),
        fieldName: a?.fieldName,
        issueType: a?.issueType,
        fieldValue: '',
        fieldId: a?.fieldId,
        fieldType: a?.fieldType,
      }));
    } else {
      return fieldIfDisplayFlagIsTrue;
    }
  };

  const getAllProductsList = useCallback(() => {
    const productsList1 = requestProductsServices?.map((item) => ({ value: item.product.toLowerCase(), label: item?.product }));
    return productsList1;
  }, [requestProductsServices]);

  const getDefaultServiceList = useCallback(
    () =>
      requestProductsServices
        ?.find((item) => item.product === requestProductsServices[0]?.product)
        ?.services?.map((data) => ({ value: data.name.toLowerCase(), label: data.name })),
    [requestProductsServices]
  );

  useEffect(() => {
    if (requestProductsServices?.length) {
      setDropdownValues({
        product: requestProductsServices[0]?.product.toLowerCase(),
        service: requestProductsServices[0]?.services[0]?.name.toLowerCase(),
      });
      setCurrentProducts(getAllProductsList);
      setCurrentServices(getDefaultServiceList);
    }
  }, [getAllProductsList, getDefaultServiceList, requestProductsServices]);

  const getObjectFields = useCallback(
    (configId, issueTypeArgs) => {
      dispatch(getObjectConfiguration(configId))?.then((objeConfigRes) => {
        if (objeConfigRes?.status === 200) {
          if (objeConfigRes?.data?.length) {
            setMandatoryFieldList(getFiltereFieldDataToAppend(objeConfigRes?.data, issueTypeArgs));
          }
        } else {
          dispatch(snackbarNotification(objeConfigRes?.data?.statusMessage || 'Failed to get mandatory fields', 'error'));
        }
      });
    },
    [dispatch]
  );

  const getObjectTypesfromApi = useCallback(
    (product, service, currentIssueTypeArgs) => {
      dispatch(getObjectTypes(product, service))?.then((objTypesRes) => {
        if (objTypesRes?.status === 200) {
          if (objTypesRes?.data) {
            setIssueTypeList(
              objTypesRes?.data.issueTypes?.map((item) => ({
                id: uuidv4(),
                value: item,
                label: item,
              }))
            );
            setCurrentIssueType(objTypesRes?.data.issueTypes[0]);
            setCurrentConfigId(objTypesRes?.data?.configId);
            getObjectFields(objTypesRes?.data?.configId, currentIssueTypeArgs || objTypesRes?.data?.issueTypes[0]);
          }
        } else {
          dispatch(snackbarNotification(objTypesRes?.data?.statusMessage || 'Failed to get object types', 'error'));
        }
      });
    },
    [dispatch, getObjectFields]
  );

  useEffect(() => {
    if (requestProductsServices?.length && !isUpdateData) {
      getObjectTypesfromApi(requestProductsServices[0]?.product, requestProductsServices[0]?.services[0]?.name, null);
    }
  }, [dispatch, getObjectTypesfromApi, isUpdateData, requestProductsServices]);

  const fields = [
    {
      name: 'summary',
      id: 'summary',
      size: 'small',
      required: true,
      disabled: false,
      defaultValue: summaryDefaultValue,
      placeholder: 'Summary',
      validation: Yup.string().required('Summary cannot be empty'),
      sx: { textAlign: 'left', width: '100%', borderRadius: '2px' },
    },
    {
      name: 'requestDescription',
      id: 'requestDescription',
      size: 'small',
      required: true,
      disabled: false,
      defaultValue: requestDescriptionDefaultValue,
      multiline: true,
      rows: 4,
      validation: Yup.string().required('Request Description cannot be empty'),
      sx: { textAlign: 'left', width: '100%', borderRadius: '2px' },
    },
  ];

  const defaultValues = utils.form.getInitialValues(fields);
  const validationSchema = utils.form.getValidationSchema(fields);

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues,
    ...(validationSchema && { resolver: yupResolver(validationSchema) }),
  });

  const validateDropdown = useCallback(() => {
    if (!dropdownValues.product) {
      setIsDropDownValuesSelected((prev) => ({ ...prev, product: false }));
    } else {
      setIsDropDownValuesSelected((prev) => ({ ...prev, product: true }));
    }

    if (!dropdownValues.service) {
      setIsDropDownValuesSelected((prev) => ({ ...prev, service: false }));
    } else {
      setIsDropDownValuesSelected((prev) => ({ ...prev, service: true }));
    }
  }, [dropdownValues]);

  useEffect(() => {
    validateDropdown();
  }, [dropdownValues, validateDropdown]);

  const handleIssueTypesDropDown = (event) => {
    let issueType = event.target.value;
    setMandatoryFieldValues([]);
    getObjectFields(currentConfigId, issueType);
    setCurrentIssueType(issueType);
  };

  const handleOnChangeMandatoryfield = (mandatoryfield) => {
    if (mandatoryFieldValues?.length) {
      const filteredMandatoryValues = mandatoryFieldValues?.filter((item) => item?.id !== mandatoryfield?.id);
      const filterNullValues = [...filteredMandatoryValues, mandatoryfield]?.filter((s) => s?.fieldValue);
      setMandatoryFieldValues(filterNullValues);
    } else {
      setMandatoryFieldValues([mandatoryfield]);
    }
  };

  const onSubmit = (formData) => {
    if (!dropdownValues.product || !dropdownValues.service || !isDropDownValuesSelected.product || !isDropDownValuesSelected.service)
      return;

    const finalRequestData = {
      ...formData,
      product: requestId ? data.product : dropdownValues.product,
      service: requestId ? data.product : dropdownValues.service,
      ...(requestId && { id: requestId }),
    };

    if (data) {
      dispatch(updateRequest(finalRequestData, userEmail)).then((res) => {
        if (res?.status === 202 || res?.status === 200) {
          submitHandler(finalRequestData?.id);
        } else {
          dispatch(snackbarNotification(res?.response?.data?.message || 'Request updation failed', 'error'));
        }
      });
    } else {
      const filteredMandatoryFieldData = {};
      mandatoryFieldValues?.forEach((item) => {
        filteredMandatoryFieldData[item.fieldName] = item.fieldValue;
      });
      const finalRequestDataForCreate = { ...finalRequestData, ...filteredMandatoryFieldData, issueType: currentIssueType };
      dispatch(createRequest(finalRequestDataForCreate, userEmail)).then((res) => {
        if (res?.status === 201 || res?.status === 200) {
          submitHandler();
        } else {
          dispatch(snackbarNotification('Create request failed', 'error'));
        }
      });
    }
  };

  const handleDropdown = (event, dropdown) => {
    if (!event.target.value) {
      if (dropdown === consts.PRODUCT) {
        setDropdownValues((prev) => ({ ...prev, product: event.target.value }));
      } else {
        setDropdownValues((prev) => ({ ...prev, service: event.target.value }));
      }
      setDropdownValues({ product: event.target.value, service: event.target.value });
      return;
    }

    if (dropdown === consts.PRODUCT) {
      const filteredServices = requestProductsServices?.find((item) => item.product.toLowerCase() === event.target.value.toLowerCase());
      if (filteredServices?.services?.length) {
        const filteredCurrentServices = filteredServices?.services?.map((item) => ({ value: item.name.toLowerCase(), label: item.name }));
        setCurrentServices(filteredCurrentServices);
        const isServiceAvailable = filteredCurrentServices.find((i) => i.value === dropdownValues.service);
        if (isServiceAvailable) {
          setDropdownValues((prev) => ({
            ...prev,
            product: event.target.value,
          }));
          setMandatoryFieldValues([]);
          setMandatoryFieldList([]);
          getObjectTypesfromApi(event.target.value, dropdownValues?.service, null);
        } else {
          setMandatoryFieldValues([]);
          setMandatoryFieldList([]);
          setDropdownValues({ service: filteredCurrentServices[0].value, product: event.target.value });
          getObjectTypesfromApi(event.target.value, filteredCurrentServices[0].value, null);
        }
      }
    } else {
      const filteredProduct = requestProductsServices?.filter((item) => {
        const result = item?.services?.find((s) => s.name.toLowerCase() === event.target.value.toLowerCase());
        if (result) {
          return item;
        } else {
          return false;
        }
      });
      if (filteredProduct?.length) {
        const filteredCurrentProducts = filteredProduct
          .flatMap((i) => i)
          ?.map((a) => ({ value: a?.product.toLowerCase(), label: a?.product }));
        const isProductAvailable = filteredCurrentProducts.find((a) => a.value === dropdownValues.product);
        if (isProductAvailable) {
          setDropdownValues((prev) => ({
            ...prev,
            service: event.target.value,
          }));
          setMandatoryFieldValues([]);
          setMandatoryFieldList([]);
          getObjectTypesfromApi(dropdownValues?.product, event.target.value, null);
        } else {
          setMandatoryFieldValues([]);
          setMandatoryFieldList([]);
          setDropdownValues({ product: filteredCurrentProducts[0].value, service: event.target.value });
          getObjectTypesfromApi(filteredCurrentProducts[0].value, event.target.value, null);
        }
      }
    }
  };

  return (
    <AddEditRequestFormView
      fields={fields}
      products={currentProducts}
      services={currentServices}
      currentIssueType={currentIssueType}
      issueTypeList={issueTypeList}
      dropdownValues={dropdownValues}
      productDefultValue={productDefultValue}
      serviceDefaultValue={serviceDefaultValue}
      isDropDownValuesSelected={isDropDownValuesSelected}
      editFormData={data}
      mandatoryFieldValues={mandatoryFieldValues}
      mandatoryFieldList={mandatoryFieldList}
      cancelButtonLabel={cancelButtonLabel}
      formProps={{ errors, register, handleSubmit, control }}
      handlers={{
        onSubmit,
        handleDropdown,
        cancelHandler,
        handleIssueTypesDropDown,
        handleOnChangeMandatoryfield,
      }}
    />
  );
}
