import * as React from 'react';
import PropTypes from 'prop-types';

// app
import { ConfirmView } from './Confirm.view';

Confirm.propTypes = {
  confirmText: PropTypes.node,
  confirmButtonLabel: PropTypes.string,
  cancelButtonLabel: PropTypes.string,
  disableConfirmButton: PropTypes.bool,
  cancelHandler: PropTypes.func,
  submitHandler: PropTypes.func,
};

Confirm.defaultProps = {
  disableConfirmButton: false,
};

export default function Confirm({
  confirmText,
  confirmButtonLabel,
  cancelButtonLabel,
  disableConfirmButton,
  cancelHandler,
  submitHandler,
}) {
  const onSubmit = () => {
    submitHandler();
  };

  const handleClose = () => {
    cancelHandler();
  };

  return (
    <ConfirmView
      confirmText={confirmText}
      confirmButtonLabel={confirmButtonLabel}
      cancelButtonLabel={cancelButtonLabel}
      disableConfirmButton={disableConfirmButton}
      cancelHandler={cancelHandler}
      handlers={{ handleClose, onSubmit }}
    />
  );
}
