import React from 'react';
import PropTypes from 'prop-types';

// app
import * as utils from 'utils';
import { consts } from 'globalsData';
import SiemensLogo from 'assets/svg/logo.svg';

// mui
import { Box, Button, Grid, Typography, useTheme, TextField, FormControl, FormHelperText, styled } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

LoginScreenView.propTypes = {
  fields: PropTypes.array.isRequired,
  formProps: PropTypes.object.isRequired,
  showAdminForm: PropTypes.bool.isRequired,
  handlers: PropTypes.shape({
    onSubmit: PropTypes.func.isRequired,
    // handleCreateUser: PropTypes.func.isRequired,
    hanldeShowAdminForm: PropTypes.func.isRequired,
    handleSSOLogin: PropTypes.func.isRequired,
  }),
};

export default function LoginScreenView({ fields, formProps, handlers, showAdminForm }) {
  const theme = useTheme();
  const { register, handleSubmit, errors } = formProps;

  return (
    <Box
      sx={{
        height: '100vh',
      }}
    >
      <Grid container height="100%">
        <Grid
          item
          xs={12}
          md={7}
          sx={{ backgroundColor: theme.palette.primary.sidebarBackground }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box color={theme.palette.primary.white} width="calc(100% - 180px)">
            <Typography variant="h4" fontWeight={700} pb={2}>
              {consts.APP_TITLE}
            </Typography>
            <Typography variant="body2">
              The BacklogOne application is a software tool designed to help teams track and manage their backlog items. The backlog can be
              any work items that are not yet completed, such as tasks, bugs, user stories, or features.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={5}>
          <Box height="40%" display="flex" justifyContent="center" alignItems="center">
            <Box>
              <img src={SiemensLogo} alt="SiemensLogo" width={300} height={62} />
              <Typography variant="subtitle2" pt={1} textAlign="center" fontWeight="600">
                Authorised Users Only
              </Typography>
            </Box>
          </Box>
          <Box height="60%">
            {showAdminForm ? (
              <Box id="adminFormContainer">
                <Box px={10} mb={2}>
                  <Box
                    sx={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                      '&:hover': {
                        textDecoration: 'underline',
                      },
                    }}
                    onClick={() => {
                      handlers.hanldeShowAdminForm(false);
                    }}
                  >
                    <ArrowBackIosIcon fontSize="small" />
                    <Typography>Back</Typography>
                  </Box>
                </Box>
                <Box px={10} component="form" onSubmit={handleSubmit(handlers.onSubmit)}>
                  <FormControl sx={{ width: '100%', paddingBottom: '1rem' }}>
                    <Typography variant="body1" color={theme.palette.primary.textColor}>
                      Email
                    </Typography>
                    <InputField
                      {...register('email')}
                      {...utils.form.getFieldProp(fields, 'email')}
                      error={!!errors?.email?.message}
                      inputProps={{
                        'data-testid': 'email',
                      }}
                    />
                    {!!errors?.email?.message && (
                      <FormHelperText sx={{ fontSize: '14px', color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                        {errors.email.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                  <FormControl sx={{ width: '100%', paddingBottom: '1rem' }}>
                    <Typography variant="body1" color={theme.palette.primary.textColor}>
                      Password
                    </Typography>
                    <InputField
                      {...register('password')}
                      {...utils.form.getFieldProp(fields, 'password')}
                      error={!!errors?.password?.message}
                      inputProps={{
                        'data-testid': 'password',
                      }}
                    />
                    {!!errors?.password?.message && (
                      <FormHelperText sx={{ fontSize: '14px', color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                        {errors.password.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                  <Button
                    variant="contained"
                    type="submit"
                    id="login-btn"
                    sx={{
                      width: '100%',
                      backgroundColor: '#262626',
                      borderRadius: '5px',
                      textTransform: 'unset',
                      fontWeight: 'bold',
                      letterSpacing: '1px',
                    }}
                  >
                    Sign in
                  </Button>
                </Box>
                {/* <Box pt={4} textAlign="center">
                  <Typography variant="subtitle2" fontWeight={600}>
                    Don't have an account yet?{' '}
                    <Typography
                      variant="button"
                      gutterBottom
                      color={theme.palette.primary.main}
                      fontWeight={600}
                      textTransform="unset"
                      sx={{
                        cursor: 'pointer',
                        '&:hover': {
                          textDecoration: 'underline',
                        },
                      }}
                      onClick={(e) => {
                        handlers.handleCreateUser(e);
                      }}
                    >
                      Create One
                    </Typography>
                  </Typography>
                </Box> */}
              </Box>
            ) : (
              <Box id="signInButtonsContainer" px={10}>
                <Typography variant="body2" fontSize="1rem" mb={2} color={theme.palette.primary.textColor} textAlign="center">
                  Sign in with your identity provider
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    width: '100%',
                    backgroundColor: '#262626',
                    borderRadius: '5px',
                    textTransform: 'unset',
                    fontWeight: 'bold',
                    letterSpacing: '1px',
                    boxShadow: 'none',
                  }}
                  onClick={handlers.handleSSOLogin}
                >
                  SIEMENS - SSO
                </Button>
                <Box my={2} display="flex" alignItems="center">
                  <Box flex={1} borderBottom={`2px solid ${theme.palette.primary.textColor}`} />
                  <Typography variant="caption" flex={0.2} textAlign="center">
                    OR
                  </Typography>
                  <Box flex={1} borderBottom={`2px solid ${theme.palette.primary.textColor}`} />
                </Box>
                <Button
                  variant="contained"
                  onClick={() => {
                    handlers.hanldeShowAdminForm(true);
                  }}
                  sx={{
                    width: '100%',
                    backgroundColor: '#262626',
                    borderRadius: '5px',
                    textTransform: 'unset',
                    fontWeight: 'bold',
                    letterSpacing: '1px',
                    boxShadow: 'none',
                  }}
                >
                  Sign in with credentials
                </Button>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

const InputField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    borderRadius: '2px',
  },
}));
