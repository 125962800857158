import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

//app
import * as utils from 'utils';
import SignUpFormView from './SignUpForm.view';
import { snackbarNotification, signUp } from 'stores';

export default function SignUpForm({ submitHandler, cancelButtonLabel, cancelHandler }) {
  const [isValidConfirmPassword, setIsValidConfirmPassword] = useState(true);

  const dispatch = useDispatch();

  const fields = [
    {
      name: 'email',
      id: 'email',
      defaultValue: '',
      size: 'small',
      type: 'text',
      required: true,
      validation: Yup.string().email('Please enter valid email').required('Please enter an email'),
    },
    {
      name: 'password',
      id: 'password',
      size: 'small',
      type: 'password',
      defaultValue: '',
      required: true,
      validation: Yup.string().required('Please enter a password').min(8, 'Password must be at least 8 characters'),
    },
    {
      name: 'confirmPassword',
      id: 'confirmPassword',
      size: 'small',
      type: 'password',
      defaultValue: '',
      required: true,
      validation: Yup.string().required('Please enter confirmation the password'),
    },
  ];

  const defaultValues = utils.form.getInitialValues(fields);
  const validationSchema = utils.form.getValidationSchema(fields);

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm({
    defaultValues,
    ...(validationSchema && { resolver: yupResolver(validationSchema) }),
  });

  const watchConfirmPassword = watch('confirmPassword');

  const onSubmit = (formData) => {
    const { password } = formData;

    if (password !== watchConfirmPassword) {
      setIsValidConfirmPassword(false);
      return;
    } else {
      setIsValidConfirmPassword(true);
    }

    dispatch(signUp(formData)).then((res) => {
      if (res?.status === 201 || res?.status === 200) {
        if (res?.data === 'User already exist...') {
          dispatch(snackbarNotification(res?.data, 'error'));
        } else {
          submitHandler();
        }
      } else {
        dispatch(snackbarNotification(res?.response?.data?.message || 'Account creation failed', 'error'));
      }
    });
  };

  return (
    <SignUpFormView
      fields={fields}
      cancelButtonLabel={cancelButtonLabel}
      isValidConfirmPassword={isValidConfirmPassword}
      formProps={{ errors, register, handleSubmit }}
      handlers={{ onSubmit, cancelHandler }}
    />
  );
}
