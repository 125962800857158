export const getUserManagementTableRowsSuccess = (data) => {
  return {
    type: 'GET_USER_MANAGEMENT_TABLE_ROWS_SUCCESS',
    payload: data,
  };
};

export const getSearchedUserManagementTableRowsSuccess = (data) => {
  return {
    type: 'GET_SEARCHED_USER_MANAGEMENT_TABLE_ROWS_SUCCESS',
    payload: data,
  };
};

export const isUserManagementTableSearched = (data) => {
  return {
    type: 'IS_USER_MANAGEMENT_TABLE_SEARCHED',
    payload: data,
  };
};

export const getUserManagementTableCurrentPage = (data) => {
  return {
    type: 'GET_USER_MANAGEMENT_CURRENT_PAGE_NUMBER',
    payload: data,
  };
};
