const initialState = {
  myRequestTableData: {},
  openRequestTableData: {},
  closedRequestTableData: {},
  allRequestTableData: {},
  requestProductsServices: [],
  requestKPIData: {},
  isMyRequestTableDataLoading: false,
  currentTab: 'myRequest',
  currentPage: 0,
  currentRequestDetails: {},
  currentRequestDetailsError: '',
  isNavigatedFromDetailsPage: false,
  searchedEditedRequestData: {},
  filteredRequestResults: {},
  isValidRoute: true,
  requestIsSearched: false,
  requestFilteredDetails: { product: false, service: false },
};

const requestTrackingReducers = (state = initialState, action = {}) => {
  switch (action.type) {
    case 'GET_MY_REQUEST_TABLE_DATA_REQUEST':
      return {
        ...state,
        isMyRequestTableDataLoading: true,
      };
    case 'GET_MY_REQUEST_TABLE_DATA_SUCCESS':
      return {
        ...state,
        myRequestTableData: action.payload,
        isMyRequestTableDataLoading: false,
      };
    case 'GET_OPEN_REQUEST_TABLE_DATA_SUCCESS':
      return {
        ...state,
        openRequestTableData: action.payload,
      };
    case 'GET_CLOSED_REQUEST_TABLE_DATA_SUCCESS':
      return {
        ...state,
        closedRequestTableData: action.payload,
      };
    case 'GET_ALL_REQUEST_TABLE_DATA_SUCCESS':
      return {
        ...state,
        allRequestTableData: action.payload,
      };
    case 'GET_REQUEST_PRODUCTS_SERVICES':
      return {
        ...state,
        requestProductsServices: action.payload?.products,
      };
    case 'GET_REQUEST_KPI_DATA':
      return {
        ...state,
        requestKPIData: action.payload,
      };
    case 'GET_CURRENT_PAGE':
      return {
        ...state,
        currentPage: action.payload,
      };
    case 'GET_CURRENT_REQUEST_DETAILS':
      return {
        ...state,
        currentRequestDetails: action.payload,
      };
    case 'GET_CURRENT_REQUEST_DETAILS_ERROR':
      return {
        ...state,
        currentRequestDetailsError: action.payload,
      };
    case 'NAVIGATED_FROM_DETAILS_PAGE':
      return {
        ...state,
        isNavigatedFromDetailsPage: action.payload,
      };
    case 'GET_EDITED_SEARCH_REQUEST_RESULT':
      return {
        ...state,
        searchedEditedRequestData: action.payload,
      };
    case 'GET_FILTERED_REQUEST_RESULT':
      return {
        ...state,
        filteredRequestResults: action.payload,
      };
    case 'VALIDATE_ROUTE':
      return {
        ...state,
        isValidRoute: action.payload,
      };
    case 'GET_REQUEST_SEARCHING_STATUS':
      return {
        ...state,
        requestIsSearched: action.payload,
      };
    case 'GET_REQUEST_FILTER_STATUS':
      return {
        ...state,
        requestFilteredDetails: action.payload,
      };
    default:
      return state;
  }
};

export default requestTrackingReducers;
