import React from 'react';
import PropTypes from 'prop-types';
import { trim } from 'lodash';

//mui
import {
  Tab,
  Tabs,
  Box,
  Grid,
  Typography,
  Button,
  Card,
  CardContent,
  FormControl,
  Select,
  MenuItem,
  TextField,
  IconButton,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

// app
import { DataGridTable, Layout, BootstrapTooltip } from 'components';
import { consts, useMedia } from 'globalsData';
import { theme } from 'theme';

RequestTrackingView.propTypes = {
  currentTabIndex: PropTypes.number.isRequired,
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  tabData: PropTypes.array.isRequired,
  uniqRowId: PropTypes.string.isRequired,
  searchText: PropTypes.string.isRequired,
  requestList: PropTypes.array.isRequired,
  products: PropTypes.array.isRequired,
  services: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  rowCount: PropTypes.number,
  dropdownValues: PropTypes.shape({
    product: PropTypes.string.isRequired,
    service: PropTypes.string.isRequired,
  }),
  handlers: PropTypes.shape({
    rowClickHandler: PropTypes.func.isRequired,
    onTabChange: PropTypes.func.isRequired,
    handleCreateRquest: PropTypes.func.isRequired,
    handleDropdown: PropTypes.func.isRequired,
    onPageChange: PropTypes.func,
    onFilterChange: PropTypes.func.isRequired,
    onFilterReset: PropTypes.func.isRequired,
    handleSearchReset: PropTypes.func.isRequired,
  }),
};

function RequestTrackingView({
  currentTabIndex,
  columns,
  rows,
  tabData,
  uniqRowId,
  handlers,
  dropdownValues,
  searchText,
  requestList,
  products,
  services,
  page,
  pageSize,
  rowCount,
}) {
  const {
    rowClickHandler,
    handleCreateRquest,
    handleDropdown,
    onTabChange,
    setSearchText,
    handleOnSearch,
    onPageChange,
    onFilterChange,
    onFilterReset,
    handleSearchReset,
  } = handlers;
  const { tablet } = useMedia();
  return (
    <Layout>
      <Grid container pb={4} alignItems="center">
        <Grid item xs={6}>
          <Typography variant="h1" fontWeight={700} fontSize="1.75rem">
            {consts.FEATURE_LIST_OBJ.requestTracking}
          </Typography>
        </Grid>
        <Grid item xs={6} textAlign="right">
          <Button
            variant="contained"
            sx={{
              backgroundColor: theme.palette.primary.main,
              textTransform: 'unset',
              borderRadius: '5px',
              fontWeight: 600,
              letterSpacing: '1px',
              height: '40px',
              boxShadow: 'none',
            }}
            disabled={!products.length}
            onClick={(e) => {
              handleCreateRquest(e);
            }}
          >
            Create Request
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2} pb={4}>
        {requestList.map((req) => (
          <Grid item xs={6} md={3} key={req.label}>
            <Card sx={{ boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.2)' }}>
              <CardContent>
                <Typography fontWeight={600} color={theme.palette.primary.requestHeaderTextColor}>
                  {req.label}
                </Typography>
                <Typography fontWeight={700} fontSize="3rem" color={theme.palette.primary.requestCountTextColor}>
                  {req.value}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Grid container pb={4}>
        <Grid item xs={12} md={5} pb={`${tablet ? '1rem' : '0'}`}>
          <Typography pb={0.5}>Products</Typography>
          <FormControl sx={{ width: `${tablet ? '100%' : '98%'}`, backgroundColor: theme.palette.primary.white }}>
            <Select
              value={dropdownValues.product}
              sx={{ borderRadius: '2px', height: '40px' }}
              onChange={(e) => {
                handleDropdown(e, consts.PRODUCT);
              }}
              displayEmpty
              disabled={!products.length}
              MenuProps={{ style: { maxHeight: 500 } }}
              inputProps={{ 'data-testid': 'select-product' }}
            >
              <MenuItem value="">Select Product</MenuItem>
              {products.map((p) => (
                <MenuItem value={p.value} key={p.value}>
                  {p.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={5} pb={`${tablet ? '1rem' : '0'}`}>
          <Typography ml={`${tablet ? '0' : '2%'}`} pb={0.5}>
            Services
          </Typography>
          <FormControl sx={{ width: `${tablet ? '100%' : '98%'}`, backgroundColor: theme.palette.primary.white, float: 'right' }}>
            <Select
              value={dropdownValues.service}
              sx={{ borderRadius: '2px', height: '40px' }}
              onChange={(e) => {
                handleDropdown(e, consts.SERVICE);
              }}
              displayEmpty
              disabled={!dropdownValues.product}
              MenuProps={{ style: { maxHeight: 500 } }}
              inputProps={{ 'data-testid': 'select-service' }}
            >
              <MenuItem value="">Select Service</MenuItem>
              {services.map((s) => (
                <MenuItem value={s.value} key={s.value}>
                  {s.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={2} display="flex" justifyContent={`${tablet ? 'flex-start' : 'flex-end'}`} alignItems="flex-end">
          <Button
            variant="contained"
            sx={{
              height: '40px',
              fontWeight: 600,
              textTransform: 'unset',
              borderRadius: '5px',
              letterSpacing: '1px',
              mx: '0.5rem',
              boxShadow: 'none',
              ml: `${tablet ? '0' : '0.5rem'}`,
            }}
            onClick={onFilterChange}
            disabled={dropdownValues.product || dropdownValues.service ? false : true}
          >
            Filter
          </Button>
          <Button
            variant="contained"
            sx={{ height: '40px', fontWeight: 600, textTransform: 'unset', borderRadius: '5px', letterSpacing: '1px', boxShadow: 'none' }}
            onClick={onFilterReset}
            disabled={dropdownValues.product || dropdownValues.service ? false : true}
          >
            Reset
          </Button>
        </Grid>
      </Grid>
      <Box sx={{ boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.2)' }}>
        <Box
          sx={{
            backgroundColor: theme.palette.primary.white,
            borderTop: '1px solid rgba(224, 224, 224, 1)',
            borderLeft: '1px solid rgba(224, 224, 224, 1)',
            borderRight: '1px solid rgba(224, 224, 224, 1)',
          }}
        >
          <Grid container alignItems="center">
            <Grid item xs={tablet ? 12 : 8}>
              <Tabs
                value={currentTabIndex}
                onChange={onTabChange}
                aria-label="Request list tabs"
                sx={{
                  '& .MuiButtonBase-root.Mui-selected': {
                    fontWeight: 'bold',
                  },
                }}
              >
                {tabData.map((item) => (
                  <Tab {...item} key={item.id} sx={{ textTransform: 'unset', minHeight: '62px' }} />
                ))}
              </Tabs>
            </Grid>
            <Grid item xs={4} textAlign="right" pr={1} display={tablet ? 'none' : 'block'}>
              <Box component="form" onSubmit={handleOnSearch}>
                <BootstrapTooltip title="Search Product, Service, Request ID, Summary">
                  <TextField
                    variant="outlined"
                    size="small"
                    id="search-box"
                    value={searchText}
                    onChange={(e) => {
                      setSearchText(trim(e.target.value));
                    }}
                    inputProps={{
                      'data-testid': 'search-box',
                    }}
                    placeholder="Search"
                    InputProps={{
                      endAdornment: (
                        <>
                          <IconButton
                            title="Clear"
                            aria-label="Clear"
                            size="small"
                            style={{
                              visibility: searchText ? 'visible' : 'hidden',
                              paddingRight: '1px',
                              margin: '0',
                              fontSize: '1.25rem',
                            }}
                            onClick={handleSearchReset}
                          >
                            <ClearIcon fontSize="small" color="action" />
                          </IconButton>
                          <IconButton sx={{ p: '4px', fontSize: '1.25rem' }} disabled={!searchText} type="submit" aria-label="search-icon">
                            <SearchIcon fontSize="small" color="action" />
                          </IconButton>
                        </>
                      ),
                    }}
                    sx={{
                      width: { xs: 1, sm: 'auto' },
                      '& .MuiSvgIcon-root': {
                        mr: 0.5,
                      },
                    }}
                  />
                </BootstrapTooltip>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box>
          {[0, 1, 2, 3].map((tabIndex) => (
            <TabPanel value={currentTabIndex} index={tabIndex} key={tabIndex}>
              <DataGridTable
                rows={rows}
                columns={columns}
                rowCount={rowCount}
                uniqRowId={uniqRowId}
                height={330}
                page={page}
                pageSize={pageSize}
                paginationMode="server"
                isSearchable={false}
                checkboxSelection={false}
                emptyRowsText="No request(s) found"
                handlers={{ rowClickHandler, onPageChange }}
              />
            </TabPanel>
          ))}
        </Box>
      </Box>
    </Layout>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Box role="tabpanel" hidden={value !== index} id={`request-tabpanel-${index}`} aria-labelledby={`request-tab-${index}`} {...other}>
      {value === index && <Box>{children}</Box>}
    </Box>
  );
}

export default React.memo(RequestTrackingView);
