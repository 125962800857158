const config = {
  routes: {
    blank: {
      root: '/blank',
    },
    home: {
      root: '/',
    },
    configuration: {
      root: '/configuration',
    },
    requestTracking: {
      root: '/request-tracking',
    },
    userManagement: {
      root: '/user-management',
    },
  },
};

export default config;
