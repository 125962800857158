import { api } from 'utils';
import {
  snackbarNotification,
  removeLoader,
  addLoader,
  getMyRequestTableDataRequest,
  getMyRequestTableDataSuccess,
  getMyRequestTableDataError,
} from 'stores';
import jwtDecode from 'jwt-decode';
import { accessToken } from 'utils/api/api';

export const getMyRequestTableData = (paginationModel) => async (dispatch) => {
  const token = accessToken();
  const decoded = jwtDecode(token);
  const emailId = decoded?.sub;

  dispatch(getMyRequestTableDataRequest(''));
  dispatch(addLoader('fetching request(s)'));
  try {
    try {
      const res = await api.get({
        endpoint: `${process.env.REACT_APP_CBT_SERVICE}`,
        path: 'user/requests',
        params: { emailId, page: paginationModel.page, size: paginationModel.pageSize },
      });
      dispatch(getMyRequestTableDataSuccess(res?.data));
      return res;
    } catch (error) {
      dispatch(getMyRequestTableDataError(error));
      dispatch(snackbarNotification(error?.response?.data?.message || 'Failed to fetch request(s)', 'error'));
      return error;
    }
  } finally {
    dispatch(removeLoader('fetching request(s)'));
  }
};
