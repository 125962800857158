import { api } from 'utils';
import { snackbarNotification, removeLoader, addLoader, getClosedRequestTableDataSuccess } from 'stores';
import jwtDecode from 'jwt-decode';
import { accessToken } from 'utils/api/api';

export const getClosedRequestTableData = (paginationModel) => async (dispatch) => {
  const token = accessToken();
  const decoded = jwtDecode(token);
  const emailId = decoded?.sub;

  dispatch(addLoader('fetching request(s)'));
  try {
    try {
      const res = await api.get({
        endpoint: `${process.env.REACT_APP_CBT_SERVICE}`,
        path: 'user/requests/closed',
        params: { emailId, page: paginationModel.page, size: paginationModel.pageSize },
      });
      dispatch(getClosedRequestTableDataSuccess(res?.data));
      return res;
    } catch (error) {
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      return error;
    }
  } finally {
    dispatch(removeLoader('fetching request(s)'));
  }
};
