import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';

// app
import { hideSnackbarNotification, removeSnackbarNotification } from 'stores';
import { consts } from 'globalsData';

// mui
import { Button, Slide, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';

export default function SnackbarNotification() {
  const dispatch = useDispatch();
  const uiSnackbarNotificationQueue = useSelector((state) => get(state, 'ui.notification.queue', []));
  const notification = uiSnackbarNotificationQueue[0];

  const handleClose = (key) => (event, reason) => {
    if (reason === 'clickaway') return;
    dispatch(hideSnackbarNotification(key));
  };

  const handleExited = (key) => () => {
    dispatch(removeSnackbarNotification(key));
  };

  // abort
  if (!notification) return null;

  return (
    <Snackbar
      key={notification.key}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={notification.visible}
      autoHideDuration={notification.delay || consts.SNACKBAR_AUTO_HIDE_DURATION}
      onClose={handleClose(notification.key)}
      TransitionProps={{ onExited: handleExited(notification.key) }}
      message={notification.message}
      TransitionComponent={Slide}
      action={[<Button key="close" icon={CloseIcon} veriant="text" aria-label="Close" onClick={() => handleClose(notification.key)} />]}
    >
      <Alert variant="filled" onClose={handleClose(notification.key)} severity={notification.type} sx={{ width: '100%' }}>
        {notification.message}
      </Alert>
    </Snackbar>
  );
}
