import { api } from 'utils';
import { snackbarNotification, removeLoader, addLoader } from 'stores';

export const deleteObjectConfiguration = (objectConfiguration) => async (dispatch) => {
  dispatch(addLoader('deleteObjectConfiguration'));
  try {
    try {
      const res = await api.delete({
        endpoint: process.env.REACT_APP_CBT_SERVICE,
        path: 'config/object/field',
        data: objectConfiguration,
      });
      return res;
    } catch (error) {
      dispatch(snackbarNotification(error?.response?.data?.message || 'Failed to delete object configuration', 'error'));
      return error;
    }
  } finally {
    dispatch(removeLoader('deleteObjectConfiguration'));
  }
};
