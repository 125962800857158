import { api } from 'utils';
import { snackbarNotification, removeLoader, addLoader, getRequestKPIDataSuccess } from 'stores';

export const getRequestKPIData = (userEmailId) => async (dispatch) => {
  if (!userEmailId) return;

  dispatch(addLoader('Fetching KPI Data'));

  try {
    try {
      const res = await api.get({
        endpoint: `${process.env.REACT_APP_CBT_SERVICE}`,
        path: 'kpi',
        params: { userEmailId },
      });
      dispatch(getRequestKPIDataSuccess(res?.data));
      return res;
    } catch (error) {
      dispatch(snackbarNotification(error?.response?.data?.message || 'Failed to fetch KPI data', 'error'));
      return error;
    }
  } finally {
    dispatch(removeLoader('Fetching KPI Data'));
  }
};
