import { api } from 'utils';
import { snackbarNotification, removeLoader, addLoader, getAllRequestTableDataSuccess } from 'stores';

export const getAllRequestTableData = (paginationModel) => async (dispatch) => {
  dispatch(addLoader('fetching request(s)'));

  try {
    try {
      const res = await api.get({
        endpoint: `${process.env.REACT_APP_CBT_SERVICE}`,
        path: 'requests',
        params: { page: paginationModel.page, size: paginationModel.pageSize },
      });
      dispatch(getAllRequestTableDataSuccess(res?.data));
      return res;
    } catch (error) {
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      return error;
    }
  } finally {
    dispatch(removeLoader('fetching request(s)'));
  }
};
