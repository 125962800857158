import React from 'react';
import PropTypes from 'prop-types';

//mui
import { Box, Button, Grid, TextField, Typography, MenuItem, FormControl, Select, FormHelperText, Divider, styled } from '@mui/material';

//app
import * as utils from 'utils';
import { consts } from 'globalsData';
import { theme } from 'theme';

AddEditRequestFormView.propTypes = {
  fields: PropTypes.array.isRequired,
  products: PropTypes.array.isRequired,
  services: PropTypes.array.isRequired,
  formProps: PropTypes.object.isRequired,
  dropdownValues: PropTypes.object.isRequired,
  isDropDownValuesSelected: PropTypes.object.isRequired,
  productDefultValue: PropTypes.string.isRequired,
  serviceDefaultValue: PropTypes.string.isRequired,
  editFormData: PropTypes.object,
  currentIssueType: PropTypes.string.isRequired,
  cancelButtonLabel: PropTypes.string.isRequired,
  issueTypeList: PropTypes.array.isRequired,
  mandatoryFieldList: PropTypes.array.isRequired,
  mandatoryFieldValues: PropTypes.array.isRequired,
  handlers: PropTypes.shape({
    onSubmit: PropTypes.func.isRequired,
    handleDropdown: PropTypes.func.isRequired,
    cancelHandler: PropTypes.func.isRequired,
    handleIssueTypesDropDown: PropTypes.func.isRequired,
    handleOnChangeMandatoryfield: PropTypes.func.isRequired,
  }),
};

export function AddEditRequestFormView({
  fields,
  products,
  services,
  formProps,
  handlers,
  editFormData,
  currentIssueType,
  issueTypeList,
  mandatoryFieldList,
  mandatoryFieldValues,
  cancelButtonLabel,
  dropdownValues,
  productDefultValue,
  serviceDefaultValue,
  isDropDownValuesSelected,
}) {
  const { register, handleSubmit, errors } = formProps;
  const requestId = editFormData?.id;
  const textStyles = { fontSize: '14px', fontWeight: 600 };
  return (
    <Box
      sx={{ backgroundColor: '#fff', color: theme.palette.primary.textColor }}
      component="form"
      onSubmit={handleSubmit(handlers.onSubmit)}
    >
      <Box px={3} py={2} sx={{ maxHeight: '325px', height: '325px', overflowX: 'hidden', overflowY: 'auto' }}>
        <Box id="products" pb={2}>
          <Typography fontSize={16} textAlign="left" pb={0.5} sx={textStyles}>
            Product <span style={{ color: '#ff0000' }}>*</span>
          </Typography>
          {requestId ? (
            <InputField
              value={productDefultValue}
              disabled
              sx={{ backgroundColor: '#f0f0f0', width: '100%' }}
              inputProps={{ style: { cursor: 'not-allowed', padding: '8.5px 14px' } }}
            />
          ) : (
            <FormControl sx={{ width: '100%' }}>
              <Select
                value={dropdownValues.product}
                sx={{ borderRadius: '2px', '>div': { padding: '8.5px 14px' } }}
                onChange={(e) => {
                  handlers.handleDropdown(e, consts.PRODUCT);
                }}
                displayEmpty
                MenuProps={{ style: { maxHeight: 280 } }}
                inputProps={{ 'data-testid': 'product-field' }}
              >
                {products?.map((product) => (
                  <MenuItem value={product.value} key={product.value}>
                    {product.label}
                  </MenuItem>
                ))}
              </Select>
              {!isDropDownValuesSelected.product && (
                <FormHelperText sx={{ fontSize: '14px', color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                  Please select product
                </FormHelperText>
              )}
            </FormControl>
          )}
        </Box>
        <Box id="service" pb={2}>
          <Typography fontSize={16} textAlign="left" pb={0.5} sx={textStyles}>
            Service <span style={{ color: '#ff0000' }}>*</span>
          </Typography>
          {requestId ? (
            <InputField
              value={serviceDefaultValue}
              disabled
              sx={{ backgroundColor: '#f0f0f0', width: '100%' }}
              inputProps={{ style: { cursor: 'not-allowed', padding: '8.5px 14px' } }}
            />
          ) : (
            <FormControl sx={{ width: '100%' }}>
              <Select
                value={dropdownValues.service}
                sx={{ borderRadius: '2px', '>div': { padding: '8.5px 14px' } }}
                onChange={(e) => {
                  handlers.handleDropdown(e, consts.SERVICE);
                }}
                displayEmpty
                MenuProps={{ style: { maxHeight: 280 } }}
                inputProps={{ 'data-testid': 'service-field' }}
              >
                {services?.map((product) => (
                  <MenuItem value={product.value} key={product.value}>
                    {product.label}
                  </MenuItem>
                ))}
              </Select>
              {!isDropDownValuesSelected.service && (
                <FormHelperText sx={{ fontSize: '14px', color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                  Please select service
                </FormHelperText>
              )}
            </FormControl>
          )}
        </Box>
        {!requestId && (
          <>
            <Box id="issueType" pb={2}>
              <Typography fontSize={16} textAlign="left" pb={0.5} sx={textStyles}>
                Issue Type(s) <span style={{ color: '#ff0000' }}>*</span>
              </Typography>
              <FormControl fullWidth>
                <Select value={currentIssueType} size="small" sx={{ borderRadius: '2px' }} onChange={handlers.handleIssueTypesDropDown}>
                  {issueTypeList?.map((issueType) => (
                    <MenuItem value={issueType.value} key={issueType.id}>
                      {issueType.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            {mandatoryFieldList?.length > 0 &&
              mandatoryFieldList?.map((item) => (
                <Box id={item?.fieldName} key={item?.id} pb={2}>
                  <Typography textAlign="left" pb={0.5} sx={textStyles}>
                    {item?.fieldName} {item?.fieldType === 'mandatory' && <span style={{ color: '#ff0000' }}>*</span>}
                  </Typography>
                  <InputField
                    value={mandatoryFieldValues?.find((i) => i?.id === item?.id)?.fieldValue || ''}
                    size="small"
                    required={item?.fieldType === 'mandatory'}
                    fullWidth
                    onChange={(event) => {
                      handlers.handleOnChangeMandatoryfield({ ...item, fieldValue: event.target.value });
                    }}
                  />
                </Box>
              ))}
          </>
        )}
        <Box id="summary" pb={2}>
          <Typography textAlign="left" pb={0.5} sx={textStyles}>
            Summary <span style={{ color: '#ff0000' }}>*</span>
          </Typography>
          <InputField
            {...register('summary')}
            {...utils.form.getFieldProp(fields, 'summary')}
            error={!!errors?.summary?.message}
            inputProps={{
              'data-testid': 'summary-field',
            }}
          />
          {!!errors?.summary?.message && (
            <FormHelperText sx={{ fontSize: '14px', marginLeft: '0', color: (theme) => theme.palette.otherColor.errorText }}>
              {errors?.summary?.message}
            </FormHelperText>
          )}
        </Box>
        <Box id="requestDescription" pb={2}>
          <Typography textAlign="left" pb={0.5} sx={textStyles}>
            Request Description <span style={{ color: '#ff0000' }}>*</span>
          </Typography>
          <InputField
            {...register('requestDescription')}
            {...utils.form.getFieldProp(fields, 'requestDescription')}
            error={!!errors?.requestDescription?.message}
            inputProps={{
              'data-testid': 'requestDescription-field',
            }}
          />
          {!!errors?.requestDescription?.message && (
            <FormHelperText sx={{ fontSize: '14px', marginLeft: '0', color: (theme) => theme.palette.otherColor.errorText }}>
              {errors?.requestDescription?.message}
            </FormHelperText>
          )}
        </Box>
      </Box>
      <Divider />
      <Box id="actionsButtons" px={4} py={2}>
        <Grid container spacing={1} alignItems="flex-start" justifyContent="flex-end">
          <Grid item>
            {cancelButtonLabel && (
              <Button disabled={false} variant="text" sx={{ textTransform: 'none' }} onClick={handlers.cancelHandler}>
                {cancelButtonLabel}
              </Button>
            )}
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              sx={{
                textTransform: 'none',
                p: '0.4rem 1.5rem',
                boxShadow: 'none',
              }}
              type="submit"
            >
              {requestId ? 'Update' : 'Create'}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

const InputField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    borderRadius: '2px',
  },
}));
