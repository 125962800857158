import { combineReducers } from 'redux';

import userReducer from './user/user.reducers';
import uiReducer from './ui/ui.reducers';
import requestTrackingReducer from './requestTracking/requestTracking.reducers';
import configurationReducers from './configuration/configuration.reducers';
import userManagementReducers from './userManagement/userManagement.reducers';

export * from './user/user.actions';
export * from './user/user.actions.getUser';
export * from './user/user.actions.signUp';
export * from './user/user.actions.ssoLogin';

export * from './ui/ui.actions';

export * from './requestTracking/requestTracking.actions';
export * from './requestTracking/requestTracking.actions.getMyRequestTableData';
export * from './requestTracking/requestTracking.actions.getOpenRequestTableData';
export * from './requestTracking/requestTracking.actions.getClosedRequestTableData';
export * from './requestTracking/requestTracking.actions.getAllRequestTableData';
export * from './requestTracking/requestTracking.actions.createRequest.js';
export * from './requestTracking/requestTracking.actions.getRequestProductsServices';
export * from './requestTracking/requestTracking.actions.getRequestKPIData';
export * from './requestTracking/requestTracking.actions.getProductServiceFilteredData';
export * from './requestTracking/requestTracking.actions.searchRequest';
export * from './requestTracking/requestTracking.actions.getRequestDetails';

export * from './configuration/configuration.actions';
export * from './configuration/configuration.actions.getConfigurationTableData';
export * from './configuration/configuration.actions.createConfiguration';
export * from './configuration/configuration.actions.updateConfiguration';
export * from './configuration/configuration.actions.deleteConfiguration';
export * from './requestTracking/requestTracking.actions.updateRequest';
export * from './configuration/configuration.actions.searchConfiguration';
export * from './configuration/configuration.actions.createSAMLSSOSettings';
export * from './configuration/configuration.actions.getSAMLSSOSettings';
export * from './configuration/configuration.actions.updateSAMLSSOSettings';
export * from './configuration/configuration.actions.getPolarionProductsAndServices';
export * from './configuration/configuration.actions.getObjectConfiguration';
export * from './configuration/configuration.actions.createObjectConfiguration';
export * from './configuration/configuration.actions.deleteObjectConfiguration';
export * from './configuration/configuration.actions.updateObjectConfiguration';
export * from './configuration/configuration.actions.getObjectTypes';

export * from './userManagement/userManagement.actions';
export * from './userManagement/userManagement.actions.getUserManagementTableData';
export * from './userManagement/userManagement.actions.UpdateUserRole';
export * from './userManagement/userManagement.actions.searchUser';

export default combineReducers({
  ui: uiReducer,
  user: userReducer,
  requestTracking: requestTrackingReducer,
  configuration: configurationReducers,
  userManagement: userManagementReducers,
});
