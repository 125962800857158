import * as React from 'react';
import PropTypes from 'prop-types';

//mui
import { Box, Breadcrumbs as MuiBreadcrumbs } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
  paddingLeftRight: PropTypes.number,
};

Breadcrumbs.defaultProps = {
  paddingLeftRight: 0,
};

export default function Breadcrumbs({ breadcrumbs, paddingLeftRight }) {
  return (
    <Box px={paddingLeftRight} pb={1}>
      <MuiBreadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        {breadcrumbs}
      </MuiBreadcrumbs>
    </Box>
  );
}
