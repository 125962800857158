import { api } from 'utils';
import { snackbarNotification, removeLoader, addLoader } from 'stores';

export const deleteConfiguration = (configId) => async (dispatch) => {
  if (!configId) return Promise.reject({ message: 'config id is missing' });
  dispatch(addLoader({ key: 'deletingConfiguration', message: 'Deleting Configuration...' }));

  try {
    try {
      const res = await api.delete({
        endpoint: `${process.env.REACT_APP_CBT_SERVICE}`,
        path: `admin/config/${configId}`,
      });
      return res;
    } catch (error) {
      dispatch(snackbarNotification(error?.response?.data?.message || 'Failed to delete configuration', 'error'));
      return error;
    }
  } finally {
    dispatch(removeLoader('deletingConfiguration'));
  }
};
