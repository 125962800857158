const initialState = {
  configurationTableRows: {},
  searchedConfigurationTableRows: {},
  isSearched: false,
  currentPageNumber: 0,
  searchedEditedConfigData: {},
  samlSSOSettings: {},
};

const configurationReducers = (state = initialState, action = {}) => {
  switch (action.type) {
    case 'GET_CONFIGURATION_TABLE_ROWS_SUCCESS':
      return {
        ...state,
        configurationTableRows: action.payload,
      };
    case 'GET_SEARCHED_CONFIGURATION_TABLE_ROWS_SUCCESS':
      return {
        ...state,
        searchedConfigurationTableRows: action.payload,
      };
    case 'IS_SEARCHED':
      return {
        ...state,
        isSearched: action.payload,
      };
    case 'GET_CURRENT_PAGE_NUMBER':
      return {
        ...state,
        currentPageNumber: action.payload,
      };
    case 'GET_EDIT_SEARCH_CONFIG_RESULT':
      return {
        ...state,
        searchedEditedConfigData: action.payload,
      };
    case 'GET_SAML_SSO_SETTINGS':
      return {
        ...state,
        samlSSOSettings: action.payload,
      };
    default:
      return state;
  }
};

export default configurationReducers;
