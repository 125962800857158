import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import * as Yup from 'yup';

//app
import AddEditSSOFormView from './AddEditSSOForm.view';
import * as utils from 'utils';
import { createSAMLSSOSettings, getSAMLSSOSettings, snackbarNotification, updateSAMLSSOSettings } from 'stores';

AddEditSSOForm.propTypes = {
  submitHandler: PropTypes.func,
  cancelButtonLabel: PropTypes.string.isRequired,
  cancelHandler: PropTypes.func.isRequired,
};

export default function AddEditSSOForm({ data, submitHandler, cancelButtonLabel, cancelHandler }) {
  const dispatch = useDispatch();
  const samlSSOSettings = useSelector((state) => get(state, 'configuration.samlSSOSettings'));

  useEffect(() => {
    dispatch(getSAMLSSOSettings());
  }, [dispatch]);

  const samlSSOSettingsId = samlSSOSettings?.id || '';
  const singleSignOnIssuerDefaultValue = samlSSOSettings?.issuer || '';
  const identityProviderSingleSignOnURLDefaultValue = samlSSOSettings?.destinationUrl || '';
  const assertionConsumerServiceURLDefaultValue = samlSSOSettings?.assertionConsumerServiceUrl || '';
  const x509CertificateDefaultValue = samlSSOSettings?.x509Certificate || '';
  const usernameMappingDefaultValue = samlSSOSettings?.usernameMapped || '';

  const fields = [
    {
      name: 'SingleSignOnIssuer',
      id: 'SingleSignOnIssuer',
      size: 'small',
      required: true,
      defaultValue: singleSignOnIssuerDefaultValue,
      placeholder: '',
      validation: Yup.string().required('Single sign on issuer cannot be empty'),
      sx: { textAlign: 'left', width: '100%', borderRadius: '2px' },
    },
    {
      name: 'IdentityProviderSingleSignOnURL',
      id: 'IdentityProviderSingleSignOnURL',
      size: 'small',
      required: true,
      disabled: false,
      defaultValue: identityProviderSingleSignOnURLDefaultValue,
      validation: Yup.string()
        .url('Identity provider single sign-on URL must be a valid URL')
        .required('Identity provider single sign-on URL cannot be empty'),
      sx: { textAlign: 'left', width: '100%', borderRadius: '2px' },
    },
    {
      name: 'AssertionConsumerServiceURL',
      id: 'AssertionConsumerServiceURL',
      size: 'small',
      required: true,
      disabled: false,
      defaultValue: assertionConsumerServiceURLDefaultValue,
      validation: Yup.string()
        .url('Assertion consumer service URL must be a valid URL')
        .required('Assertion consumer service URL cannot be empty'),
      sx: { textAlign: 'left', width: '100%', borderRadius: '2px' },
    },
    {
      name: 'X509Certificate',
      id: 'X509Certificate',
      size: 'small',
      required: true,
      disabled: false,
      defaultValue: x509CertificateDefaultValue,
      validation: Yup.string().required('X.509 Certificate cannot be empty'),
      sx: { textAlign: 'left', width: '100%', borderRadius: '2px' },
    },
    {
      name: 'UsernameMapping',
      id: 'UsernameMapping',
      size: 'small',
      required: true,
      disabled: false,
      defaultValue: usernameMappingDefaultValue,
      validation: Yup.string().required('Username mapping cannot be empty'),
      sx: { textAlign: 'left', width: '100%', borderRadius: '2px' },
    },
  ];

  const defaultValues = utils.form.getInitialValues(fields);
  const validationSchema = utils.form.getValidationSchema(fields);

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues,
    ...(validationSchema && { resolver: yupResolver(validationSchema) }),
  });

  useEffect(() => {
    const setValuesList = [
      { name: 'SingleSignOnIssuer', value: singleSignOnIssuerDefaultValue },
      { name: 'IdentityProviderSingleSignOnURL', value: identityProviderSingleSignOnURLDefaultValue },
      { name: 'AssertionConsumerServiceURL', value: assertionConsumerServiceURLDefaultValue },
      { name: 'X509Certificate', value: x509CertificateDefaultValue },
      { name: 'UsernameMapping', value: usernameMappingDefaultValue },
    ];
    setValuesList?.forEach(({ name, value }) => {
      setValue(name, value);
    });
  }, [
    setValue,
    samlSSOSettings,
    singleSignOnIssuerDefaultValue,
    identityProviderSingleSignOnURLDefaultValue,
    assertionConsumerServiceURLDefaultValue,
    x509CertificateDefaultValue,
    usernameMappingDefaultValue,
  ]);

  const onSubmit = (formData) => {
    const { SingleSignOnIssuer, IdentityProviderSingleSignOnURL, AssertionConsumerServiceURL, X509Certificate, UsernameMapping } = formData;
    const payload = {
      issuer: SingleSignOnIssuer,
      destinationUrl: IdentityProviderSingleSignOnURL,
      x509Certificate: X509Certificate,
      assertionConsumerServiceUrl: AssertionConsumerServiceURL,
      usernameMapped: UsernameMapping,
    };

    if (samlSSOSettingsId) {
      dispatch(updateSAMLSSOSettings(payload)).then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          submitHandler();
        } else {
          dispatch(snackbarNotification(res?.response?.data?.message || 'Updating SSO Settings Failed', 'error'));
        }
      });
    } else {
      dispatch(createSAMLSSOSettings(payload)).then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          submitHandler();
        } else {
          dispatch(snackbarNotification(res?.response?.data?.message || 'Creating SSO Settings Failed', 'error'));
        }
      });
    }
  };

  return (
    <AddEditSSOFormView
      fields={fields}
      cancelButtonLabel={cancelButtonLabel}
      formProps={{ errors, register, handleSubmit }}
      samlSSOSettingsId={samlSSOSettingsId}
      handlers={{
        onSubmit,
        cancelHandler,
      }}
    />
  );
}
