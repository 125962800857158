import https from 'https';
import axios from 'axios';

const missingFetchParameters = 'Missing fetch parameters';
const agent = new https.Agent({
  rejectUnauthorized: false,
});

const utilsApi = {
  get: async ({ endpoint, path, params, headers = true, isTokenRequired = true }) => {
    if (!endpoint) return Promise.reject({ message: missingFetchParameters });
    const token = isTokenRequired ? accessToken() : null;
    return await axios.get(getUrl(endpoint, path, params), {
      headers: headers ? getHeaders(token) : {},
      httpsAgent: agent,
      data: {},
    });
  },
  post: ({ endpoint, path, params, data, headers = true, isTokenRequired = true }) => {
    if (!endpoint) return Promise.reject({ message: missingFetchParameters });
    const token = isTokenRequired ? accessToken() : null;

    return axios.post(
      getUrl(endpoint, path, params),
      {
        ...data,
      },
      {
        headers: headers ? getHeaders(token) : {},
        httpsAgent: agent,
      }
    );
  },
  multipartPost: ({ endpoint, path, params, data, headers = true, isTokenRequired = true }) => {
    if (!endpoint) return Promise.reject({ message: missingFetchParameters });
    const token = isTokenRequired ? accessToken() : null;

    return axios.post(getUrl(endpoint, path, params), data, {
      headers: headers ? getHeaders(token, 'multipart') : {},
      httpsAgent: agent,
    });
  },
  put: ({ endpoint, path, params, data, headers = true, isTokenRequired = true }) => {
    if (!endpoint) return Promise.reject({ message: missingFetchParameters });
    const token = isTokenRequired ? accessToken() : null;

    return axios.put(
      getUrl(endpoint, path, params),
      {
        ...data,
      },
      {
        headers: headers ? getHeaders(token) : {},
        httpsAgent: agent,
      }
    );
  },
  multipartPut: ({ endpoint, path, params, data, headers = true, isTokenRequired = true }) => {
    if (!endpoint) return Promise.reject({ message: missingFetchParameters });
    const token = isTokenRequired ? accessToken() : null;

    return axios.put(
      getUrl(endpoint, path, params),
      {
        ...data,
      },
      {
        headers: headers ? getHeaders(token, 'multipart') : {},
        httpsAgent: agent,
      }
    );
  },
  patch: ({ endpoint, path, params, data, headers = true, isTokenRequired = true }) => {
    if (!endpoint) return Promise.reject({ message: missingFetchParameters });
    const token = isTokenRequired ? accessToken() : null;

    return axios.patch(
      getUrl(endpoint, path, params),
      {
        ...data,
      },
      {
        headers: headers ? getHeaders(token) : {},
        httpsAgent: agent,
      }
    );
  },
  delete: ({ endpoint, path, params, data, headers = true, isTokenRequired = true }) => {
    if (!endpoint) return Promise.reject({ message: missingFetchParameters });
    const token = isTokenRequired ? accessToken() : null;

    return axios.delete(getUrl(endpoint, path, params), {
      headers: headers ? getHeaders(token) : {},
      httpsAgent: agent,
      data,
    });
  },
};

export const accessToken = () => {
  return localStorage.getItem('accessToken');
};

export const userLoggedIn = () => {
  return localStorage.getItem('userLoggedIn');
};

export const getUrl = (endpoint, path, params) => {
  const apiPath = path ? `/${path}` : '';
  if (!endpoint) return '';
  return `${endpoint}${apiPath}${getQueryString(params)}`;
};

export const getQueryString = (params) => {
  const isObject = params !== null && typeof params === 'object' && Array.isArray(params) === false;

  if (!isObject) return '';

  const paramsArray = Object.entries(params).map((param) => {
    return `${param[0]}=${param[1]}`;
  });

  return paramsArray.length > 0 ? `?${paramsArray.join('&')}` : '';
};

export const getHeaders = (token, type = 'json') => {
  return {
    ...(type === 'json' && { 'Content-Type': 'application/json' }),
    ...(type === 'multipart' && { 'Content-Type': 'multipart/form-data' }),
    ...(token && { Authorization: `Bearer ${token}` }),
  };
};

export default utilsApi;
