import React from 'react';
import PropTypes from 'prop-types';

// mui
import { Box, Button, Divider, Grid } from '@mui/material';

ConfirmView.propTypes = {
  confirmText: PropTypes.node,
  confirmButtonLabel: PropTypes.string,
  cancelButtonLabel: PropTypes.string,
  disableConfirmButton: PropTypes.bool,
  handlers: PropTypes.shape({
    handleClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  }).isRequired,
};

export function ConfirmView({ confirmText, confirmButtonLabel, cancelButtonLabel, disableConfirmButton, handlers }) {
  return (
    <Box>
      {confirmText && (
        <Box py={2} px={3}>
          {confirmText}
        </Box>
      )}

      <Divider />
      <Box m={2}>
        <Grid container justifyContent="flex-end" spacing={1}>
          <Grid item>
            {cancelButtonLabel && (
              <Button type="submit" variant="outlined" size="small" sx={{ textTransform: 'none' }} autoFocus onClick={handlers.handleClose}>
                {cancelButtonLabel}
              </Button>
            )}
          </Grid>
          <Grid item>
            {confirmButtonLabel && (
              <Button
                type="submit"
                variant="contained"
                size="small"
                disabled={disableConfirmButton}
                sx={{ textTransform: 'none' }}
                autoFocus
                onClick={handlers.onSubmit}
              >
                {confirmButtonLabel}
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
