import React from 'react';
import PropTypes from 'prop-types';

//mui
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  MenuItem,
  FormControl,
  Select,
  FormHelperText,
  Divider,
  styled,
  Chip,
} from '@mui/material';

//app
import * as utils from 'utils';
import { theme } from 'theme';

AddEditConfigurationFormView.propTypes = {
  fields: PropTypes.array.isRequired,
  platforms: PropTypes.array.isRequired,
  cancelButtonLabel: PropTypes.string.isRequired,
  editFormData: PropTypes.object,
  formProps: PropTypes.object.isRequired,
  isPlatformIsTFS: PropTypes.bool.isRequired,
  objectTypeChips: PropTypes.array.isRequired,
  isPlatformIsPolarion: PropTypes.bool.isRequired,
  polarionObjectTypes: PropTypes.array.isRequired,
  currentPolarionObjectType: PropTypes.string.isRequired,
  handlers: PropTypes.shape({
    onSubmit: PropTypes.func.isRequired,
    cancelHandler: PropTypes.func.isRequired,
    handleObjectTypes: PropTypes.func.isRequired,
    handleDeleteObjectTypes: PropTypes.func.isRequired,
    setCurrentPolarionObjectType: PropTypes.func.isRequired,
    onEnterKeyPressOnObjectTypeField: PropTypes.func.isRequired,
  }),
};

function AddEditConfigurationFormView({
  fields,
  cancelButtonLabel,
  handlers,
  editFormData,
  platforms,
  formProps,
  isPlatformIsTFS,
  objectTypeChips,
  isPlatformIsPolarion,
  currentPolarionObjectType,
  polarionObjectTypes,
}) {
  const rowUpdateId = editFormData?.configId;
  const { register, handleSubmit, errors } = formProps;
  const textStyles = { fontSize: '14px', fontWeight: 600 };

  return (
    <Box
      sx={{ backgroundColor: '#fff', color: theme.palette.primary.textColor }}
      component="form"
      onSubmit={handleSubmit(handlers.onSubmit)}
    >
      <Box px={3} py={2} sx={{ maxHeight: '325px', height: '325px', overflowX: 'hidden', overflowY: 'auto' }}>
        <Box id="platform" pb={2}>
          <Typography fontSize={16} textAlign="left" pb={0.5} sx={textStyles}>
            Platform <span style={{ color: '#ff0000' }}>*</span>
          </Typography>
          <FormControl sx={{ width: '100%' }} error={!!errors?.platform?.message}>
            <Select {...register('platform')} {...utils.form.getFieldProp(fields, 'platform')} MenuProps={{ style: { maxHeight: 200 } }}>
              {platforms?.map((platform) => (
                <MenuItem value={platform.value} key={platform.value}>
                  {platform.label}
                </MenuItem>
              ))}
            </Select>
            {!!errors?.platform?.message && (
              <FormHelperText sx={{ fontSize: '14px', color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                {errors.platform.message}
              </FormHelperText>
            )}
          </FormControl>
        </Box>
        <Box id="products" pb={2}>
          {ProductHeader()}
          <InputField
            {...register('product')}
            {...utils.form.getFieldProp(fields, 'product')}
            error={!!errors?.product?.message}
            inputProps={{
              'data-testid': 'product-field',
            }}
          />
          {!!errors?.product?.message && (
            <FormHelperText sx={{ fontSize: '14px', marginLeft: '0', color: (theme) => theme.palette.otherColor.errorText }}>
              {errors?.product?.message}
            </FormHelperText>
          )}
        </Box>
        <Box id="service" pb={2}>
          {ServiceHeader()}
          <InputField
            {...register('service')}
            {...utils.form.getFieldProp(fields, 'service')}
            error={!!errors?.service?.message}
            inputProps={{
              'data-testid': 'service-field',
            }}
          />
          {!!errors?.service?.message && (
            <FormHelperText sx={{ fontSize: '14px', marginLeft: '0', color: (theme) => theme.palette.otherColor.errorText }}>
              {errors?.service?.message}
            </FormHelperText>
          )}
        </Box>

        <Box id="email" pb={2}>
          <Typography fontSize={16} textAlign="left" pb={0.5} sx={textStyles}>
            Admin Email/Username <span style={{ color: '#ff0000' }}>*</span>
          </Typography>
          <InputField
            {...register('email')}
            {...utils.form.getFieldProp(fields, 'email')}
            error={!!errors?.email?.message}
            inputProps={{
              'data-testid': 'email-field',
            }}
          />
          {!!errors?.email?.message && (
            <FormHelperText sx={{ fontSize: '14px', marginLeft: '0', color: (theme) => theme.palette.otherColor.errorText }}>
              {errors?.email?.message}
            </FormHelperText>
          )}
        </Box>
        {isPlatformIsTFS && (
          <>
            <Box id="project" pb={2}>
              <Typography fontSize={16} textAlign="left" pb={0.5} sx={textStyles}>
                Project <span style={{ color: '#ff0000' }}>*</span>
              </Typography>
              <InputField
                {...register('project')}
                {...utils.form.getFieldProp(fields, 'project')}
                error={!!errors?.project?.message}
                inputProps={{
                  'data-testid': 'project-field',
                }}
              />
              {!!errors?.project?.message && (
                <FormHelperText sx={{ fontSize: '14px', marginLeft: '0', color: (theme) => theme.palette.otherColor.errorText }}>
                  {errors?.project?.message}
                </FormHelperText>
              )}
            </Box>
            <Box id="organization" pb={2}>
              <Typography fontSize={16} textAlign="left" pb={0.5} sx={textStyles}>
                Organization <span style={{ color: '#ff0000' }}>*</span>
              </Typography>
              <InputField
                {...register('organization')}
                {...utils.form.getFieldProp(fields, 'organization')}
                error={!!errors?.organization?.message}
                inputProps={{
                  'data-testid': 'organization-field',
                }}
              />
              {!!errors?.organization?.message && (
                <FormHelperText sx={{ fontSize: '14px', marginLeft: '0', color: (theme) => theme.palette.otherColor.errorText }}>
                  {errors?.organization?.message}
                </FormHelperText>
              )}
            </Box>
          </>
        )}
        <Box id="platformURI" pb={2}>
          <Typography fontSize={16} textAlign="left" pb={0.5} sx={textStyles}>
            Platform URI/URL <span style={{ color: '#ff0000' }}>*</span>
          </Typography>
          <InputField
            {...register('platformURI')}
            {...utils.form.getFieldProp(fields, 'platformURI')}
            error={!!errors?.platformURI?.message}
            inputProps={{
              'data-testid': 'platformURI-field',
            }}
          />
          {!!errors?.platformURI?.message && (
            <FormHelperText sx={{ fontSize: '14px', marginLeft: '0', color: (theme) => theme.palette.otherColor.errorText }}>
              {errors?.platformURI?.message}
            </FormHelperText>
          )}
        </Box>
        <Box id="platformApiKey" pb={2}>
          <Typography fontSize={16} textAlign="left" pb={0.5} sx={textStyles}>
            Platform API Key <span style={{ color: '#ff0000' }}>*</span>
          </Typography>
          <InputField
            {...register('platformApiKey')}
            {...utils.form.getFieldProp(fields, 'platformApiKey')}
            error={!!errors?.platformApiKey?.message}
            inputProps={{
              'data-testid': 'platformApiKey-field',
            }}
          />
          {!!errors?.platformApiKey?.message && (
            <FormHelperText sx={{ fontSize: '14px', marginLeft: '0', color: (theme) => theme.palette.otherColor.errorText }}>
              {errors?.platformApiKey?.message}
            </FormHelperText>
          )}
        </Box>
        <Box id="objectType" pb={isPlatformIsTFS || isPlatformIsPolarion ? 0 : 2}>
          <Typography fontSize={16} textAlign="left" pb={0.5} sx={textStyles}>
            Object Type(s) <span style={{ color: '#ff0000' }}>*</span>
          </Typography>
          {isPlatformIsPolarion && (
            <FormControl fullWidth>
              <Select
                MenuProps={{ style: { maxHeight: 200 } }}
                size="small"
                sx={{ borderRadius: '2px' }}
                value={currentPolarionObjectType}
                onChange={(event) => {
                  handlers.setCurrentPolarionObjectType(event.target.value);
                }}
              >
                {polarionObjectTypes?.map((polarionObjectType) => (
                  <MenuItem value={polarionObjectType.value} key={polarionObjectType.value}>
                    {polarionObjectType.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {!isPlatformIsPolarion && (
            <>
              <InputField
                {...register('objectType')}
                {...utils.form.getFieldProp(fields, 'objectType')}
                error={!!errors?.objectType?.message}
                inputProps={{
                  'data-testid': 'objectType-field',
                }}
                onBlur={handlers.handleObjectTypes}
                onKeyDown={handlers.onEnterKeyPressOnObjectTypeField}
              />
              <Typography variant="caption" display="block" gutterBottom>
                Please enter object type(s) separated by comma (,)
              </Typography>
              {!!errors?.objectType?.message && (
                <FormHelperText sx={{ fontSize: '14px', color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                  {errors.objectType.message}
                </FormHelperText>
              )}
              {objectTypeChips.length > 0 && (
                <Box pt={1}>
                  {objectTypeChips?.map((chip) => (
                    <Chip
                      key={chip.id}
                      label={chip.label}
                      sx={{
                        m: '0.3rem',
                      }}
                      onDelete={() => {
                        handlers.handleDeleteObjectTypes(chip);
                      }}
                    />
                  ))}
                </Box>
              )}
            </>
          )}
        </Box>
        <Box id="projectKey" pb={2} display={isPlatformIsTFS || isPlatformIsPolarion ? 'none' : 'block'}>
          <Typography fontSize={16} textAlign="left" pb={0.5} sx={textStyles}>
            Project Key <span style={{ color: '#ff0000' }}>*</span>
          </Typography>
          <InputField
            {...register('projectKey')}
            {...utils.form.getFieldProp(fields, 'projectKey')}
            error={!!errors?.projectKey?.message}
            inputProps={{
              'data-testid': 'projectKey-field',
            }}
          />
          {!!errors?.projectKey?.message && (
            <FormHelperText sx={{ fontSize: '14px', marginLeft: '0', color: (theme) => theme.palette.otherColor.errorText }}>
              {errors?.projectKey?.message}
            </FormHelperText>
          )}
        </Box>
      </Box>
      <Divider />
      <Box id="actionsButtons" px={4} py={2}>
        <Grid container spacing={1} alignItems="flex-start" justifyContent="flex-end">
          <Grid item>
            {cancelButtonLabel && (
              <Button disabled={false} variant="text" sx={{ textTransform: 'none' }} onClick={handlers.cancelHandler}>
                {cancelButtonLabel}
              </Button>
            )}
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              sx={{
                textTransform: 'none',
                p: '0.4rem 1.5rem',
                boxShadow: 'none',
              }}
              type="submit"
            >
              {rowUpdateId ? 'Update' : 'Configure'}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

const InputField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    borderRadius: '2px',
  },
}));

function ProductHeader() {
  return (
    <Typography fontSize={16} textAlign="left" pb={0.5} sx={{ fontSize: '14px', fontWeight: 600 }}>
      Product <span style={{ color: '#ff0000' }}>*</span>
    </Typography>
  );
}

function ServiceHeader() {
  return (
    <Typography fontSize={16} textAlign="left" pb={0.5} sx={{ fontSize: '14px', fontWeight: 600 }}>
      Service <span style={{ color: '#ff0000' }}>*</span>
    </Typography>
  );
}

export default React.memo(AddEditConfigurationFormView);
