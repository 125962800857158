import { api } from 'utils';
import { snackbarNotification, removeLoader, addLoader, getSearchedUserManagementTableRowsSuccess } from 'stores';

export const searchUser = (searchKey, paginationModel) => async (dispatch) => {
  dispatch(addLoader('searchUser'));

  try {
    try {
      const res = await api.get({
        endpoint: `${process.env.REACT_APP_CBT_SERVICE}`,
        path: 'searchUser',
        params: { searchKey, page: paginationModel.page, size: paginationModel.pageSize },
      });
      if (res?.status === 200) {
        dispatch(getSearchedUserManagementTableRowsSuccess(res?.data));
      }
      return res;
    } catch (error) {
      dispatch(snackbarNotification(error?.response?.data?.message || 'Failed to search user(s)', 'error'));
      return error;
    }
  } finally {
    dispatch(removeLoader('searchUser'));
  }
};
