import { api } from 'utils';
import { snackbarNotification, removeLoader, addLoader } from 'stores';

export const createRequest = (requestFormData, userEmail) => async (dispatch) => {
  dispatch(addLoader({ key: 'creatingRequest', message: 'Creating Request...' }));

  const reqPayload = {
    title: requestFormData?.summary,
    description: requestFormData?.requestDescription,
    product: requestFormData?.product,
    service: requestFormData?.service,
    userEmail,
    ...(requestFormData?.files && { file: requestFormData?.files }),
  };

  const issueTypeAndFieldId = { ...requestFormData };
  delete issueTypeAndFieldId['product'];
  delete issueTypeAndFieldId['service'];
  delete issueTypeAndFieldId['requestDescription'];
  delete issueTypeAndFieldId['summary'];

  const blob = new Blob([JSON.stringify({ ...issueTypeAndFieldId, ...reqPayload })], {
    type: 'application/json',
  });

  const data = new FormData();
  data.append('jsonPayload', blob);

  try {
    try {
      const res = await api.multipartPost({
        endpoint: `${process.env.REACT_APP_CBT_SERVICE}`,
        path: 'request',
        data,
      });
      return res;
    } catch (error) {
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      return error;
    }
  } finally {
    dispatch(removeLoader('creatingRequest'));
  }
};
