const media = {
  match: {
    mobile: (theme) => {
      return window.matchMedia(`(max-width: ${theme.breakpoints.values.sm - 0.05}px)`);
    },
    tablet: (theme) => {
      return window.matchMedia(`(min-width: ${theme.breakpoints.values.sm}px) and (max-width: ${theme.breakpoints.values.md - 0.05}px)`);
    },
    tabletUp: (theme) => {
      return window.matchMedia(`(min-width: ${theme.breakpoints.values.sm}px)`);
    },
    desktop: (theme) => {
      return window.matchMedia(`(min-width: ${theme.breakpoints.values.md}px) and (max-width: ${theme.breakpoints.values.lg - 0.05}px)`);
    },
    desktopUp: (theme) => {
      return window.matchMedia(`(min-width: ${theme.breakpoints.values.md}px)`);
    },
    wide: (theme) => {
      return window.matchMedia(`(min-width: ${theme.breakpoints.values.lg}px) and (max-width: ${theme.breakpoints.values.xl - 0.05}px)`);
    },
    wideUp: (theme) => {
      return window.matchMedia(`(min-width: ${theme.breakpoints.values.lg}px)`);
    },
    extraWide: (theme) => {
      return window.matchMedia(`(min-width: ${theme.breakpoints.values.xl}px)`);
    },
  },
};

export default media;
