import React, { useState } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';

// app
import * as utils from 'utils';
import LoginScreenView from './LoginScreen.view';
import { snackbarNotification, ssoLogin } from 'stores';

LoginScreen.propTypes = {
  loginHandler: PropTypes.func.isRequired,
};

export default function LoginScreen({ loginHandler }) {
  const [showAdminForm, setShowAdminForm] = useState(false);
  const dispatch = useDispatch();

  const fields = [
    {
      name: 'email',
      id: 'email',
      defaultValue: '',
      size: 'small',
      type: 'text',
      required: true,
      validation: Yup.string().email('Please enter valid email').required('Please enter an email'),
    },
    {
      name: 'password',
      id: 'password',
      size: 'small',
      type: 'password',
      defaultValue: '',
      required: true,
      validation: Yup.string().required('Please enter a password'),
    },
  ];

  const defaultValues = utils.form.getInitialValues(fields);
  const validationSchema = utils.form.getValidationSchema(fields);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues,
    ...(validationSchema && { resolver: yupResolver(validationSchema) }),
  });

  // const handleCreateUser = (e) => {
  //   e.stopPropagation();
  //   dispatch(
  //     showModal({
  //       component: 'SIGN_UP_FORM',
  //       props: {
  //         title: 'Create Account',
  //         maxWidth: 'sm',
  //         componentProps: {
  //           data: null,
  //           cancelButtonLabel: 'Cancel',
  //           cancelHandler: () => {
  //             dispatch(hideModal('SIGN_UP_FORM'));
  //           },
  //           submitHandler: () => {
  //             dispatch(hideModal('SIGN_UP_FORM'));
  //             dispatch(snackbarNotification('Account created successully', 'success'));
  //           },
  //         },
  //       },
  //     })
  //   );
  // };

  const onSubmit = (formData) => {
    loginHandler(formData);
  };

  const hanldeShowAdminForm = (showAdmin) => {
    setShowAdminForm(showAdmin);
  };

  const handleSSOLogin = () => {
    dispatch(ssoLogin()).then((res) => {
      if (res?.status === 200) {
        window.location.replace(`https://myid.siemens.com/idp/SSO.saml2?SAMLRequest=${res?.data}`);
      } else {
        dispatch(snackbarNotification('Login Failed', 'error'));
      }
    });
  };

  return (
    <LoginScreenView
      fields={fields}
      formProps={{ errors, register, handleSubmit }}
      showAdminForm={showAdminForm}
      handlers={{
        onSubmit,
        // handleCreateUser,
        hanldeShowAdminForm,
        handleSSOLogin,
      }}
    />
  );
}
