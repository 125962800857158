import React from 'react';
import PropTypes from 'prop-types';

//mui
import * as utils from 'utils';
import { Box, Typography, Card, CardContent, useTheme, Divider, Grid, Alert } from '@mui/material';

// app
import { Layout, Breadcrumbs, Skeleton, BootstrapTooltip } from 'components';

RequestDetailsView.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
  requestDetailsFromAPI: PropTypes.object.isRequired,
  summary: PropTypes.string,
  status: PropTypes.string,
  description: PropTypes.string,
  comments: PropTypes.array,
  isTpidGenerated: PropTypes.bool,
  isLoading: PropTypes.bool,
  currentRequestDetailsError: PropTypes.string,
  requestDeletedOnPlatformMessage: PropTypes.string,
};

export function RequestDetailsView({
  breadcrumbs,
  requestDetailsFromAPI,
  summary,
  status,
  description,
  isLoading,
  comments,
  isTpidGenerated,
  currentRequestDetailsError,
  requestDeletedMessageOnPlatform,
}) {
  const theme = useTheme();
  const { reqHeaderDeatils, reqBodyDetails } = requestDetailsFromAPI;
  return (
    <Layout>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <Box pb={4}>
        <Typography variant="h1" fontWeight={700} fontSize="1.75rem">
          Request Details
        </Typography>
      </Box>
      {!isLoading ? (
        currentRequestDetailsError ? (
          <Alert severity="error" sx={{ fontWeight: 600 }}>
            {currentRequestDetailsError}
          </Alert>
        ) : (
          <>
            {isTpidGenerated ? (
              requestDeletedMessageOnPlatform ? (
                <Alert severity="error" sx={{ fontWeight: 600 }}>
                  {requestDeletedMessageOnPlatform}
                </Alert>
              ) : (
                <Card sx={{ boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.2)' }}>
                  <CardContent sx={{ p: '1.5rem' }}>
                    {summary && (
                      <>
                        <Typography color="#6c6c6c" fontWeight={600} gutterBottom>
                          Summary
                        </Typography>
                        <Typography color={theme.palette.primary.tableTopHeader} fontWeight={700} fontSize="1.5rem" gutterBottom>
                          {summary}
                        </Typography>
                      </>
                    )}
                    {status && (
                      <Typography
                        variant="caption"
                        sx={{
                          backgroundColor: theme.palette.primary.main,
                          color: theme.palette.primary.white,
                          borderRadius: '25px',
                          padding: '4px 12px',
                        }}
                        gutterBottom
                      >
                        {status}
                      </Typography>
                    )}
                  </CardContent>
                  <Divider />
                  <CardContent sx={{ p: '1.5rem' }}>
                    <Grid container spacing={2}>
                      {reqHeaderDeatils.map((i) => (
                        <Grid item key={i.label} xs={6} md={3}>
                          <Typography gutterBottom>{i.label}</Typography>
                          <BootstrapTooltip title={i.value?.length > 30 ? i.value : ''}>
                            <Typography fontWeight={600}>{utils.app.getEllipsisString(i.value, 30)}</Typography>
                          </BootstrapTooltip>
                        </Grid>
                      ))}
                    </Grid>
                  </CardContent>
                  <Divider />
                  <CardContent sx={{ p: '1.5rem' }}>
                    <Grid container spacing={2}>
                      {reqBodyDetails.map((b) => (
                        <Grid item key={b.label} xs={6} md={3} pb={2}>
                          <Typography gutterBottom>{b.label}</Typography>
                          <BootstrapTooltip title={b.value?.length > 30 ? b.value : ''}>
                            <Typography fontWeight={600}>{utils.app.getEllipsisString(b.value, 30)}</Typography>
                          </BootstrapTooltip>
                        </Grid>
                      ))}
                    </Grid>
                  </CardContent>
                  <CardContent sx={{ p: '0 1.5rem 1.5rem 1.5rem' }}>
                    {description && (
                      <Box>
                        <Typography fontWeight={600} gutterBottom pb="0.2rem">
                          Description
                        </Typography>
                        <Typography>{description}</Typography>
                      </Box>
                    )}
                    <Box py={3}>
                      <Typography fontWeight={600} gutterBottom pb="0.2rem">
                        Comments ({comments?.length})
                      </Typography>
                      {comments?.length
                        ? comments?.map((item) => (
                            <Box pb="0.5rem" key={item}>
                              {item}
                            </Box>
                          ))
                        : ''}
                    </Box>
                  </CardContent>
                </Card>
              )
            ) : (
              <Alert severity="info" sx={{ fontWeight: 600 }}>
                Request is not created on target platform. Please check after sometime.
              </Alert>
            )}
          </>
        )
      ) : (
        <Skeleton variant="rectangular" width="100%" height={582} displayNumber={1} />
      )}
    </Layout>
  );
}
