export const getMyRequestTableDataRequest = (tableData) => {
  return {
    type: 'GET_MY_REQUEST_TABLE_DATA_REQUEST',
    payload: tableData,
  };
};

export const getMyRequestTableDataSuccess = (data) => {
  return {
    type: 'GET_MY_REQUEST_TABLE_DATA_SUCCESS',
    payload: data,
  };
};

export const getMyRequestTableDataError = (error) => {
  return {
    type: 'GET_MY_REQUEST_TABLE_DATA_FAILURE',
    payload: error,
  };
};

export const getOpenRequestTableDataSuccess = (data) => {
  return {
    type: 'GET_OPEN_REQUEST_TABLE_DATA_SUCCESS',
    payload: data,
  };
};

export const getClosedRequestTableDataSuccess = (data) => {
  return {
    type: 'GET_CLOSED_REQUEST_TABLE_DATA_SUCCESS',
    payload: data,
  };
};

export const getAllRequestTableDataSuccess = (data) => {
  return {
    type: 'GET_ALL_REQUEST_TABLE_DATA_SUCCESS',
    payload: data,
  };
};

export const getRequestProductsServicesSuccess = (data) => {
  return {
    type: 'GET_REQUEST_PRODUCTS_SERVICES',
    payload: data,
  };
};

export const getRequestKPIDataSuccess = (data) => {
  return {
    type: 'GET_REQUEST_KPI_DATA',
    payload: data,
  };
};

export const getCurrentRequestTablePage = (data) => {
  return {
    type: 'GET_CURRENT_PAGE',
    payload: data,
  };
};

export const getCurrentRequestDetailsSuccess = (data) => {
  return {
    type: 'GET_CURRENT_REQUEST_DETAILS',
    payload: data,
  };
};

export const getCurrentRequestDetailsError = (data) => {
  return {
    type: 'GET_CURRENT_REQUEST_DETAILS_ERROR',
    payload: data,
  };
};

export const getIsNavigatedFromDetailsPage = (data) => {
  return {
    type: 'NAVIGATED_FROM_DETAILS_PAGE',
    payload: data,
  };
};

export const getEditedSearchRequestResult = (data) => {
  return {
    type: 'GET_EDITED_SEARCH_REQUEST_RESULT',
    payload: data,
  };
};

export const getFilteredRequestResult = (data) => {
  return {
    type: 'GET_FILTERED_REQUEST_RESULT',
    payload: data,
  };
};

export const validateRoute = (data) => {
  return {
    type: 'VALIDATE_ROUTE',
    payload: data,
  };
};

export const getRequestSearchStatus = (data) => {
  return {
    type: 'GET_REQUEST_SEARCHING_STATUS',
    payload: data,
  };
};

export const getRequestFilterStatus = (data) => {
  return {
    type: 'GET_REQUEST_FILTER_STATUS',
    payload: data,
  };
};
