import React, { useState, useEffect, useMemo, useCallback, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { get } from 'lodash';

//mui
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

//app
import RequestTrackingView from './RequestTracking.view';
import { consts, config } from 'globalsData';
import { BootstrapTooltip, DataGridCellExpand } from 'components';
import {
  showModal,
  hideModal,
  snackbarNotification,
  getMyRequestTableData,
  getOpenRequestTableData,
  getClosedRequestTableData,
  getAllRequestTableData,
  getRequestProductsServices,
  getProductServiceFilteredData,
  getRequestKPIData,
  searchRequest,
  getCurrentRequestTablePage,
  getCurrentRequestDetailsSuccess,
  getCurrentRequestDetailsError,
  getIsNavigatedFromDetailsPage,
  getConfigTableCurrentPage,
  getRequestSearchStatus,
  getRequestFilterStatus,
  getUserManagementTableCurrentPage,
} from 'stores';

export default function RequestTracking() {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [dropdownValues, setDropdownValues] = useState({ product: '', service: '' });
  const [currentProducts, setCurrentProducts] = useState([]);
  const [currentServices, setCurrentServices] = useState([]);
  const [searchText, setSearchText] = useState('');

  const navigate = useNavigate();
  const params = useParams();

  const dispatch = useDispatch();
  const currentPage = useSelector((state) => get(state, 'requestTracking.currentPage'));
  const myRequestTableData = useSelector((state) => get(state, 'requestTracking.myRequestTableData'));
  const openRequestTableData = useSelector((state) => get(state, 'requestTracking.openRequestTableData'));
  const closedRequestTableData = useSelector((state) => get(state, 'requestTracking.closedRequestTableData'));
  const allRequestTableData = useSelector((state) => get(state, 'requestTracking.allRequestTableData'));
  const requestProductsServicesFromApi = useSelector((state) => get(state, 'requestTracking.requestProductsServices'));
  const requestProductsServices = useMemo(
    () => (requestProductsServicesFromApi && Boolean(requestProductsServicesFromApi.length) ? requestProductsServicesFromApi : []),
    [requestProductsServicesFromApi]
  );
  const userDetails = useSelector((state) => get(state, 'user.userDetails'));
  const requestKPIData = useSelector((state) => get(state, 'requestTracking.requestKPIData'));
  const isNavigatedFromDetailsPage = useSelector((state) => get(state, 'requestTracking.isNavigatedFromDetailsPage'));
  const searchedEditedRequestData = useSelector((state) => get(state, 'requestTracking.searchedEditedRequestData'));
  const filteredRequestResults = useSelector((state) => get(state, 'requestTracking.filteredRequestResults'));
  const isValidRoute = useSelector((state) => get(state, 'requestTracking.isValidRoute'));
  const isSearched = useSelector((state) => get(state, 'requestTracking.requestIsSearched'));
  const requestFiltered = useSelector((state) => get(state, 'requestTracking.requestFilteredDetails'));

  const pageSize = 10;
  const currentRequestTabFromURL = params?.request;

  const currentPageNumber = currentPage;
  const paginationModel = useMemo(() => ({ page: currentPageNumber, pageSize }), [currentPageNumber, pageSize]);
  const myRequestCount = requestKPIData?.myRequestCount || 0;
  const openRequestCount = requestKPIData?.myOpenedRequestCount || 0;
  const closedRequestCount = requestKPIData?.myClosedRequestCount || 0;
  const allRequestCount = requestKPIData?.allRequestCount || 0;
  const userEmailId = userDetails?.email || '';
  const getCurrentFilterFlag = new Map([
    [consts.MY_REQUEST, 'myRequest'],
    [consts.OPEN_REQUEST, 'Open'],
    [consts.CLOSED_REQUEST, 'Closed'],
    [consts.ALL_REQUEST, 'allRequest'],
  ]);
  const filterFlag = getCurrentFilterFlag.get(currentRequestTabFromURL);

  const getCurrentRequestTableDataFromApi = (currentPage, requestTab) => {
    switch (requestTab) {
      case consts.MY_REQUEST:
        dispatch(getMyRequestTableData({ page: currentPage, pageSize }));
        break;
      case consts.OPEN_REQUEST:
        dispatch(getOpenRequestTableData({ page: currentPage, pageSize }));
        break;
      case consts.CLOSED_REQUEST:
        dispatch(getClosedRequestTableData({ page: currentPage, pageSize }));
        break;
      case consts.ALL_REQUEST:
        dispatch(getAllRequestTableData({ page: currentPage, pageSize }));
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (userEmailId) {
      dispatch(getRequestKPIData(userEmailId));
    }
    dispatch(getCurrentRequestDetailsSuccess({}));
    dispatch(getCurrentRequestDetailsError(''));
    dispatch(getConfigTableCurrentPage(0));
    dispatch(getUserManagementTableCurrentPage(0));
  }, [dispatch, userEmailId]);

  useEffect(() => {
    dispatch(getRequestProductsServices());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isValidRoute) {
      getCurrentRequestTableDataFromApi(currentPageNumber, consts.MY_REQUEST);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidRoute]);

  useEffect(() => {
    if (requestFiltered?.product || isSearched) {
      dispatch(getRequestFilterStatus({ product: false, service: false }));
      dispatch(getRequestSearchStatus(false));
      if (!isSearched) {
        getCurrentRequestTableDataFromApi(0, currentRequestTabFromURL);
      }
    } else {
      getCurrentRequestTableDataFromApi(currentPageNumber, currentRequestTabFromURL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSearched && !searchText && currentRequestTabFromURL === consts.ALL_REQUEST) {
      dispatch(getAllRequestTableData({ page: 0, pageSize }));
      dispatch(getCurrentRequestTablePage(0));
      dispatch(getRequestSearchStatus(false));
    }
  }, [currentPageNumber, currentRequestTabFromURL, dispatch, isSearched, searchText]);

  useLayoutEffect(() => {
    const selectedTabIndex = new Map([
      [consts.MY_REQUEST, 0],
      [consts.OPEN_REQUEST, 1],
      [consts.CLOSED_REQUEST, 2],
      [consts.ALL_REQUEST, 3],
    ]);
    setCurrentTabIndex(selectedTabIndex.get(currentRequestTabFromURL) || 0);
  }, [currentRequestTabFromURL]);

  const prepareTableData = (tableData) => {
    if (tableData && Boolean(tableData?.content?.length)) {
      return tableData?.content?.map((item) => ({
        requestId: item.id,
        summary: item.title,
        product: item.product,
        service: item.service,
        status: item.status,
      }));
    } else {
      return [];
    }
  };

  const getTableRows = () => {
    if (currentRequestTabFromURL === consts.MY_REQUEST) {
      return myRequestTableData;
    } else if (currentRequestTabFromURL === consts.OPEN_REQUEST) {
      return openRequestTableData;
    } else if (currentRequestTabFromURL === consts.CLOSED_REQUEST) {
      return closedRequestTableData;
    } else {
      return allRequestTableData;
    }
  };

  let currentRequestTableData = {};
  if (requestFiltered?.product && filteredRequestResults?.content?.length) {
    let filteredUpadatedResults = [];
    if (searchedEditedRequestData?.content?.length) {
      filteredUpadatedResults = filteredRequestResults?.content?.map((filteredRequest) => {
        if (filteredRequest.id === searchedEditedRequestData?.content[0]?.id) {
          return searchedEditedRequestData?.content[0];
        } else {
          return filteredRequest;
        }
      });
    } else {
      filteredUpadatedResults = filteredRequestResults?.content;
    }
    currentRequestTableData = { content: filteredUpadatedResults, totalElements: filteredRequestResults?.totalElements };
  } else if (requestFiltered?.product && filteredRequestResults?.content?.length === 0) {
    currentRequestTableData = filteredRequestResults;
  } else if (isSearched && allRequestTableData?.content?.length) {
    let searchResults = [];
    if (searchedEditedRequestData?.content?.length) {
      searchResults = allRequestTableData?.content?.map((searchedRequest) => {
        if (searchedRequest.id === searchedEditedRequestData?.content[0]?.id) {
          return searchedEditedRequestData?.content[0];
        } else {
          return searchedRequest;
        }
      });
    } else {
      searchResults = allRequestTableData?.content;
    }
    currentRequestTableData = { content: searchResults, totalElements: allRequestTableData?.totalElements };
  } else if (isSearched && Object.entries(allRequestTableData).length === 0) {
    currentRequestTableData = {};
  } else {
    currentRequestTableData = getTableRows();
  }

  const tableRows = prepareTableData(currentRequestTableData);
  const rowCount = currentRequestTableData?.totalElements ? currentRequestTableData?.totalElements : 0;

  const getAllProductsList = useCallback(() => {
    if (requestProductsServices?.length) {
      const productsList1 = requestProductsServices?.map((item) => ({ value: item.product.toLowerCase(), label: item?.product }));
      return productsList1;
    } else {
      return [];
    }
  }, [requestProductsServices]);

  const getAllServicesList = useCallback(() => {
    if (requestProductsServices?.length) {
      const layer1 = requestProductsServices?.map(({ services }) => services);
      const layer2 = layer1.flatMap((item) => item);
      const layer3 = layer2.map((item) => ({ value: item.name.toLowerCase(), label: item.name }));
      return layer3;
    } else {
      return [];
    }
  }, [requestProductsServices]);

  useEffect(() => {
    setCurrentProducts(getAllProductsList());
    setCurrentServices(getAllServicesList());
  }, [getAllProductsList, getAllServicesList, requestProductsServices]);

  const onTabChange = (event, newValue) => {
    const clearSearchOnTabChange = () => {
      if (isSearched) {
        setSearchText('');
        dispatch(getRequestSearchStatus(false));
      }
    };

    switch (newValue) {
      case 0:
        navigate(`${config.routes.requestTracking.root}/${consts.MY_REQUEST}`);
        dispatch(getMyRequestTableData({ page: 0, pageSize }));
        clearSearchOnTabChange();
        break;
      case 1:
        navigate(`${config.routes.requestTracking.root}/${consts.OPEN_REQUEST}`);
        dispatch(getOpenRequestTableData({ page: 0, pageSize }));
        clearSearchOnTabChange();
        break;
      case 2:
        navigate(`${config.routes.requestTracking.root}/${consts.CLOSED_REQUEST}`);
        dispatch(getClosedRequestTableData({ page: 0, pageSize }));
        clearSearchOnTabChange();
        break;
      default:
        navigate(`${config.routes.requestTracking.root}/${consts.ALL_REQUEST}`);
        dispatch(getAllRequestTableData({ page: 0, pageSize }));
    }
    if (userEmailId) {
      dispatch(getRequestKPIData(userEmailId));
    }
    dispatch(getCurrentRequestTablePage(0));
    setCurrentTabIndex(newValue);
    dispatch(getRequestFilterStatus({ product: false, service: false }));
    setCurrentProducts(getAllProductsList);
    setCurrentServices(getAllServicesList);
    setDropdownValues({ product: event.target.value, service: event.target.value });
  };

  const getRowData = (requestMode, requestId) => {
    if (requestMode === consts.EDIT) {
      if ((requestFiltered?.product || isSearched) && requestId) {
        dispatch(searchRequest(requestId, paginationModel, consts.EDIT_SEARCH)).then((res) => {
          if (res?.status === 200) {
            dispatch(hideModal('ADD_EDIT_REQUEST'));
            dispatch(snackbarNotification('Updated request successully', 'success'));
          }
        });
      } else {
        let currentGetApiActionCreator = {};
        switch (currentRequestTabFromURL) {
          case consts.MY_REQUEST:
            currentGetApiActionCreator = getMyRequestTableData;
            break;
          case consts.OPEN_REQUEST:
            currentGetApiActionCreator = getOpenRequestTableData;
            break;
          case consts.CLOSED_REQUEST:
            currentGetApiActionCreator = getClosedRequestTableData;
            break;
          default:
            currentGetApiActionCreator = getAllRequestTableData;
        }
        dispatch(currentGetApiActionCreator(paginationModel)).then((res) => {
          if (res?.status === 200) {
            dispatch(hideModal('ADD_EDIT_REQUEST'));
            dispatch(snackbarNotification('Updated request successully', 'success'));
          }
        });
      }
    } else {
      dispatch(getMyRequestTableData({ page: 0, pageSize: 10 })).then((res) => {
        if (res?.status === 200) {
          dispatch(hideModal('ADD_EDIT_REQUEST'));
          dispatch(snackbarNotification('Created request successully', 'success'));
          dispatch(getCurrentRequestTablePage(0));
          navigate(`${config.routes.requestTracking.root}/${consts.MY_REQUEST}`);
          if (userEmailId) {
            dispatch(getRequestKPIData(userEmailId));
          }
        }
      });
    }
  };

  const handleCreateRquest = (e) => {
    e.stopPropagation();
    dispatch(
      showModal({
        component: 'ADD_EDIT_REQUEST',
        props: {
          title: 'Create a Request',
          maxWidth: 'sm',
          componentProps: {
            data: null,
            cancelButtonLabel: 'Cancel',
            cancelHandler: () => {
              dispatch(hideModal('ADD_EDIT_REQUEST'));
            },
            submitHandler: () => {
              getRowData(consts.CREATE);
            },
          },
        },
      })
    );
  };

  const getFilteredRowData = (tableData, currentRowData) => {
    const filteredRowData = tableData?.content?.length && tableData?.content?.filter((item) => item?.id === currentRowData.requestId);
    return filteredRowData;
  };

  const handleEdit = (e, rowData) => {
    e.stopPropagation();
    let filterRowData = getFilteredRowData(currentRequestTableData, rowData);
    if (filterRowData) {
      dispatch(
        showModal({
          component: 'ADD_EDIT_REQUEST',
          props: {
            title: 'Edit Request',
            maxWidth: 'sm',
            componentProps: {
              data: filterRowData[0],
              cancelButtonLabel: 'Cancel',
              cancelHandler: () => {
                dispatch(hideModal('ADD_EDIT_REQUEST'));
              },
              submitHandler: (requestId) => {
                getRowData(consts.EDIT, requestId);
              },
            },
          },
        })
      );
    }
  };

  const renderEditIcon = (params) => {
    return (
      <BootstrapTooltip title="Edit" placement="right">
        <IconButton sx={{ cursor: 'pointer' }} aria-label="Edit Row" onClick={(e) => handleEdit(e, params?.row)}>
          <EditIcon fontSize="small" />
        </IconButton>
      </BootstrapTooltip>
    );
  };

  const rowClickHandler = ({ row }, event) => {
    event.preventDefault();
    navigate(`${config.routes.requestTracking.root}/${currentRequestTabFromURL}/details/${row.requestId}`);
  };

  const handleDropdown = (event, dropdown) => {
    if (!event.target.value && dropdown === consts.PRODUCT) {
      setDropdownValues({ product: '', service: '' });
      dispatch(getRequestFilterStatus({ product: false, service: false }));
      return;
    }

    if (dropdown === consts.PRODUCT) {
      const filteredServices = requestProductsServices?.find((item) => item.product.toLowerCase() === event.target.value.toLowerCase());
      if (filteredServices?.services?.length) {
        const filteredCurrentServices = filteredServices?.services?.map((item) => ({ value: item.name.toLowerCase(), label: item.name }));
        setCurrentServices(filteredCurrentServices);
        const isServiceAvailable = filteredCurrentServices.find((i) => i.value === dropdownValues.service);
        if (isServiceAvailable) {
          setDropdownValues((prev) => ({
            ...prev,
            product: event.target.value,
          }));
        } else {
          setDropdownValues({ service: filteredCurrentServices[0].value, product: event.target.value });
        }
      }
    } else {
      const filteredProduct = requestProductsServices?.filter((item) => {
        const result = item?.services?.find((s) => s.name.toLowerCase() === event.target.value.toLowerCase());
        if (result) {
          return item;
        } else {
          return false;
        }
      });
      if (filteredProduct?.length) {
        const filteredCurrentProducts = filteredProduct
          .flatMap((i) => i)
          ?.map((a) => ({ value: a?.product.toLowerCase(), label: a?.product }));
        const isProductAvailable = filteredCurrentProducts.find((a) => a.value === dropdownValues.product);
        if (isProductAvailable) {
          setDropdownValues((prev) => ({
            ...prev,
            service: event.target.value,
          }));
        } else {
          setDropdownValues({ product: filteredCurrentProducts[0].value, service: event.target.value });
        }
      } else {
        setDropdownValues((prev) => ({ ...prev, service: '' }));
      }
    }
  };

  const onFilterChange = () => {
    const filteredProduct = dropdownValues.product || null;
    const filteredService = dropdownValues.service || null;
    if (isSearched) {
      setSearchText('');
      dispatch(getRequestSearchStatus(false));
    }
    dispatch(getCurrentRequestTablePage(0));

    if (filteredProduct) {
      if (filteredService) {
        dispatch(getRequestFilterStatus({ product: true, service: true }));
      } else {
        dispatch(getRequestFilterStatus({ product: true, service: false }));
      }
    }

    dispatch(getProductServiceFilteredData(filteredProduct, filteredService, filterFlag, paginationModel, currentRequestTabFromURL));
  };

  const onFilterReset = () => {
    getCurrentRequestTableDataFromApi(currentPageNumber, currentRequestTabFromURL);
    setDropdownValues({ product: '', service: '' });
    dispatch(getRequestFilterStatus({ product: false, service: false }));
  };

  const handleOnSearch = (e) => {
    e.preventDefault();
    navigate(`${config.routes.requestTracking.root}/${consts.ALL_REQUEST}`);

    if (requestFiltered?.product || requestFiltered?.service) {
      setDropdownValues({ product: '', service: '' });
      dispatch(getRequestFilterStatus({ product: false, service: false }));
    }
    dispatch(getCurrentRequestTablePage(0));
    dispatch(getRequestSearchStatus(true));
    dispatch(searchRequest(searchText, { page: 0, pageSize }));
  };

  const handleSearchReset = () => {
    setSearchText('');
  };

  const onPageChange = (currentPaginationNumber) => {
    if (!isNavigatedFromDetailsPage) {
      dispatch(getCurrentRequestTablePage(currentPaginationNumber));
      if (requestFiltered?.product || requestFiltered?.service) {
        const product = requestFiltered.product ? dropdownValues.product : null;
        const service = requestFiltered.service ? dropdownValues.service : null;
        dispatch(getProductServiceFilteredData(product, service, filterFlag, { page: currentPaginationNumber, pageSize }));
      } else if (isSearched) {
        dispatch(searchRequest(searchText, { page: currentPaginationNumber, pageSize }));
      } else {
        getCurrentRequestTableDataFromApi(currentPaginationNumber, currentRequestTabFromURL);
      }
    }
  };

  useEffect(() => {
    if (consts.ALL_REQUEST === currentRequestTabFromURL) {
      const detailsPageTimer = setTimeout(() => {
        dispatch(getIsNavigatedFromDetailsPage(false));
      }, 1000);

      return () => {
        clearTimeout(detailsPageTimer);
      };
    }
  }, [currentRequestTabFromURL, dispatch]);

  const columns = [
    {
      field: 'requestId',
      headerName: 'Request ID',
      renderHeader: () => <strong>Request ID</strong>,
      sortable: false,
      flex: 0.6,
      renderCell: (params) => <DataGridCellExpand value={params?.row?.requestId ?? ''} />,
    },
    {
      field: 'summary',
      renderHeader: () => <strong>Summary</strong>,
      sortable: false,
      flex: 1.4,
      renderCell: (params) => <DataGridCellExpand value={params?.row?.summary ?? ''} />,
    },
    {
      field: 'product',
      renderHeader: () => <strong>Product</strong>,
      sortable: false,
      flex: 1.4,
      renderCell: (params) => <DataGridCellExpand value={params?.row?.product ?? ''} />,
    },
    {
      field: 'service',
      renderHeader: () => <strong>Service</strong>,
      sortable: false,
      flex: 1.4,
      renderCell: (params) => <DataGridCellExpand value={params?.row?.service ?? ''} />,
    },
    {
      field: 'status',
      renderHeader: () => <strong>Status</strong>,
      sortable: false,
      flex: 0.8,
    },
    {
      field: 'editRow',
      headerName: '',
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: renderEditIcon,
      flex: 0.5,
    },
  ];

  const tabData = useMemo(
    () => [
      { id: 'request-tab-0', label: 'My Request', 'aria-controls': 'request-tabpanel-0' },
      { id: 'request-tab-1', label: 'My Opened Request', 'aria-controls': 'request-tabpanel-1' },
      { id: 'request-tab-2', label: 'My Closed Request', 'aria-controls': 'request-tabpanel-2' },
      { id: 'request-tab-3', label: 'All Request', 'aria-controls': 'request-tabpanel-3' },
    ],
    []
  );

  const requestList = useMemo(
    () => [
      { value: myRequestCount, label: 'My Request' },
      { value: openRequestCount, label: 'My Opened Request' },
      { value: closedRequestCount, label: 'My Closed Request' },
      { value: allRequestCount, label: 'All Request' },
    ],
    [allRequestCount, closedRequestCount, myRequestCount, openRequestCount]
  );

  const uniqRowId = 'requestId'; // unique Id in rows list

  return (
    <RequestTrackingView
      rows={tableRows}
      columns={columns}
      tabData={tabData}
      rowCount={rowCount}
      uniqRowId={uniqRowId}
      currentTabIndex={currentTabIndex}
      dropdownValues={dropdownValues}
      searchText={searchText}
      requestList={requestList}
      products={currentProducts}
      services={currentServices}
      page={paginationModel.page}
      pageSize={paginationModel.pageSize}
      handlers={{
        rowClickHandler,
        onTabChange,
        handleCreateRquest,
        handleDropdown,
        setSearchText,
        handleOnSearch,
        onPageChange,
        onFilterChange,
        onFilterReset,
        handleSearchReset,
      }}
    />
  );
}
