import { api } from 'utils';
import { addLoader, removeLoader, snackbarNotification, getUserError } from 'stores';

export const getUser =
  ({ email, password, role }) =>
  async (dispatch) => {
    dispatch(addLoader('get user'));

    const data = {
      username: email,
      password,
      role,
    };

    try {
      try {
        const res = await api.post({
          endpoint: `${process.env.REACT_APP_CBT_SERVICE}`,
          path: 'authenticate',
          data,
        });
        return res;
      } catch (error) {
        dispatch(getUserError(error));
        dispatch(
          snackbarNotification(
            Boolean(error?.response?.data?.error) && error?.response?.data?.error === 'Bad credentials'
              ? 'Invalid credentials'
              : 'Something went wrong !',
            'error'
          )
        );
        return error;
      }
    } finally {
      dispatch(removeLoader('get user'));
    }
  };
