import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';

//mui
import { Link, Typography } from '@mui/material';

//app
import * as utils from 'utils';
import { consts, config } from 'globalsData';
import { RequestDetailsView } from './RequestDetails.view';
import {
  getRequestDetails,
  searchRequest,
  getCurrentRequestDetailsError,
  getIsNavigatedFromDetailsPage,
  getCurrentRequestTablePage,
} from 'stores';

export default function RequestDetails() {
  const navigate = useNavigate();
  const { requestId, request } = useParams();
  const [isTpidGenerated, setIsTpidGenerated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();
  const currentRequestDetails = useSelector((state) => get(state, 'requestTracking.currentRequestDetails'));
  const currentRequestDetailsError = useSelector((state) => get(state, 'requestTracking.currentRequestDetailsError'));
  const isSearched = useSelector((state) => get(state, 'requestTracking.requestIsSearched'));
  const requestFiltered = useSelector((state) => get(state, 'requestTracking.requestFilteredDetails'));

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(searchRequest(requestId, { page: 0, pageSize: 10 }, consts.REQUEST_DETAILS_SEARCH)).then((res) => {
      if (res?.status === 200) {
        const tpidFromApi = res?.data?.content[0]?.tpid;
        const requestIdFromApi = res?.data?.content[0]?.id;
        if (tpidFromApi && requestIdFromApi) {
          setIsTpidGenerated(true);
          dispatch(getRequestDetails(requestIdFromApi)).then((res) => {
            if (res?.status === 200) {
              setIsLoading(false);
            } else {
              setIsLoading(false);
              dispatch(getCurrentRequestDetailsError('Failed to fetch request details'));
            }
          });
        } else {
          setIsTpidGenerated(false);
          setIsLoading(false);
        }
      } else {
        dispatch(getCurrentRequestDetailsError('Failed to fetch request details'));
        setIsLoading(false);
      }
    });
  }, [dispatch, requestId]);

  useEffect(() => {
    if (request === consts.ALL_REQUEST) {
      dispatch(getIsNavigatedFromDetailsPage(true));
    }
  }, [dispatch, request]);

  useEffect(() => {
    if (requestFiltered?.product || isSearched) {
      dispatch(getCurrentRequestTablePage(0));
    }
  }, [dispatch, isSearched, requestFiltered?.product]);

  const capitalizeFirstLetter = (str) => {
    // converting first letter to uppercase
    return str?.charAt(0)?.toUpperCase() + str?.slice(1);
  };

  const handleComments = (comments) => {
    const filteredComments = comments?.filter(
      (item) =>
        !item?.body?.includes('.png') &&
        !item?.body?.includes('.jpg') &&
        !item?.body?.includes('.jpeg') &&
        !item?.body?.includes('.gif') &&
        !item?.body?.includes('.webp') &&
        !item?.body?.includes('.svg')
    );
    return filteredComments?.map((comment) => (
      <>
        <Typography gutterBottom>{comment?.body}</Typography>
        <Typography variant="subtitle2" textAlign="left" fontSize="0.8rem" pl="0.2rem">
          <strong>- {comment?.author?.displayName}</strong>
        </Typography>
      </>
    ));
  };

  const requestDeletedMessageOnPlatform = currentRequestDetails?.message;
  const summary = currentRequestDetails?.summary;
  const status = currentRequestDetails?.status;
  const product = currentRequestDetails?.product;
  const service = currentRequestDetails?.service;
  const createdDateFromApi = currentRequestDetails?.created;
  const updatedDateFromApi = currentRequestDetails?.updated;
  const description = currentRequestDetails?.description || '';
  const comments = currentRequestDetails?.comment?.length > 0 ? handleComments(currentRequestDetails?.comment) : [];
  const createdDate = createdDateFromApi ? utils.app.formatDateTime(createdDateFromApi) : '';
  const updatedDate = updatedDateFromApi ? utils.app.formatDateTime(updatedDateFromApi) : '';
  const keyToExclude = ['summary', 'key', 'status', 'product', 'service', 'updated', 'created', 'description', 'attachment', 'comment'];

  const reqBodyDetails = Object.entries(currentRequestDetails)
    ?.filter((filteredRequestDetails) => !keyToExclude.includes(filteredRequestDetails[0]))
    ?.map((requestBodyData) => ({
      label: capitalizeFirstLetter(requestBodyData[0]),
      value: Array.isArray(requestBodyData[1]) ? requestBodyData[1][requestBodyData[1].length - 1]?.name : requestBodyData[1],
    }));

  const reqHeaderDeatilsList = [
    { ...(product && { label: 'Product', value: product }) },
    { ...(service && { label: 'Service', value: service }) },
    { ...(createdDate && { label: 'Created', value: createdDate }) },
    { ...(updatedDate && { label: 'Updated', value: updatedDate }) },
  ];
  const reqHeaderDeatils = reqHeaderDeatilsList?.filter((v) => Object.entries(v)?.length > 0);

  const handleClick = (event, link) => {
    event.preventDefault();
    navigate(link);
  };

  const requestDetailsFromAPI = {
    reqHeaderDeatils,
    reqBodyDetails,
  };

  const breadcrumbs = [
    <Link
      underline="hover"
      key="2"
      color="inherit"
      aria-label="RequestDetails Breadcrumb"
      sx={{ cursor: 'pointer' }}
      onClick={(e) => handleClick(e, `${config.routes.requestTracking.root}/${request}`)}
    >
      {consts.FEATURE_LIST_OBJ.requestTracking}
    </Link>,
    <Typography key="3" color="text.primary">
      Request ID - {requestId}
    </Typography>,
  ];

  return (
    <RequestDetailsView
      breadcrumbs={breadcrumbs}
      requestDetailsFromAPI={requestDetailsFromAPI}
      currentRequestDetailsError={currentRequestDetailsError}
      summary={summary}
      status={status}
      description={description}
      comments={comments}
      isLoading={isLoading}
      isTpidGenerated={isTpidGenerated}
      requestDeletedMessageOnPlatform={requestDeletedMessageOnPlatform}
    />
  );
}
