import React from 'react';
import { useNavigate } from 'react-router-dom';

//mui
import { Box, Button, Typography } from '@mui/material';

//app
import { consts, config } from 'globalsData';

export default function Blank() {
  const navigate = useNavigate();

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%">
      <Typography variant="h1">404</Typography>
      <Typography variant="body1" gutterBottom>
        The page you're looking for doesn't exist.
      </Typography>
      <Button
        variant="contained"
        onClick={() => {
          navigate(`${config.routes.requestTracking.root}/${consts.MY_REQUEST}`);
        }}
        sx={{ textTransform: 'none' }}
      >
        Go back to Request Tracking page
      </Button>
    </Box>
  );
}
