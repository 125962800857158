import React from 'react';
import PropTypes from 'prop-types';

//mui
import { Box, Button, Grid, TextField, Typography, FormControl, FormHelperText, Divider, styled } from '@mui/material';

//app
import * as utils from 'utils';
import { theme } from 'theme';

AddEditSSOFormView.propTypes = {
  fields: PropTypes.array.isRequired,
  cancelButtonLabel: PropTypes.string.isRequired,
  formProps: PropTypes.object.isRequired,
  samlSSOSettingsId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handlers: PropTypes.shape({
    onSubmit: PropTypes.func.isRequired,
    cancelHandler: PropTypes.func.isRequired,
  }),
};

export default function AddEditSSOFormView({ fields, cancelButtonLabel, formProps, handlers, samlSSOSettingsId }) {
  const { register, handleSubmit, errors } = formProps;
  const formControlStyle = { width: '100%', paddingBottom: '1rem' };
  const formInnerContainerStyle = { maxHeight: '320px', height: '320px', overflowX: 'hidden', overflowY: 'auto' };
  const textStyles = { fontSize: '14px', fontWeight: 600 };

  return (
    <Box
      sx={{ backgroundColor: '#fff', color: theme.palette.primary.textColor }}
      component="form"
      onSubmit={handleSubmit(handlers.onSubmit)}
    >
      <Box px={3} py={2} sx={formInnerContainerStyle}>
        <FormControl sx={formControlStyle}>
          <Typography variant="body1" color={theme.palette.primary.textColor} pb={0.5} sx={textStyles}>
            Single sign on issuer <span style={{ color: '#ff0000' }}>*</span>
          </Typography>
          <InputField
            {...register('SingleSignOnIssuer')}
            {...utils.form.getFieldProp(fields, 'SingleSignOnIssuer')}
            error={!!errors?.SingleSignOnIssuer?.message}
            inputProps={{
              'data-testid': 'SingleSignOnIssuer',
            }}
          />
          {!!errors?.SingleSignOnIssuer?.message && (
            <FormHelperText sx={{ fontSize: '14px', color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
              {errors.SingleSignOnIssuer.message}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl sx={formControlStyle}>
          <Typography variant="body1" color={theme.palette.primary.textColor} pb={0.5} sx={textStyles}>
            Identity provider single sign-on URL <span style={{ color: '#ff0000' }}>*</span>
          </Typography>
          <InputField
            {...register('IdentityProviderSingleSignOnURL')}
            {...utils.form.getFieldProp(fields, 'IdentityProviderSingleSignOnURL')}
            error={!!errors?.IdentityProviderSingleSignOnURL?.message}
            inputProps={{
              'data-testid': 'IdentityProviderSingleSignOnURL',
            }}
          />
          {!!errors?.IdentityProviderSingleSignOnURL?.message && (
            <FormHelperText sx={{ fontSize: '14px', color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
              {errors.IdentityProviderSingleSignOnURL.message}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl sx={formControlStyle}>
          <Typography variant="body1" color={theme.palette.primary.textColor} pb={0.5} sx={textStyles}>
            Assertion consumer service URL <span style={{ color: '#ff0000' }}>*</span>
          </Typography>
          <InputField
            {...register('AssertionConsumerServiceURL')}
            {...utils.form.getFieldProp(fields, 'AssertionConsumerServiceURL')}
            error={!!errors?.AssertionConsumerServiceURL?.message}
            inputProps={{
              'data-testid': 'AssertionConsumerServiceURL',
            }}
          />
          {!!errors?.AssertionConsumerServiceURL?.message && (
            <FormHelperText sx={{ fontSize: '14px', color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
              {errors.AssertionConsumerServiceURL.message}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl sx={formControlStyle}>
          <Typography variant="body1" color={theme.palette.primary.textColor} pb={0.5} sx={textStyles}>
            X.509 Certificate <span style={{ color: '#ff0000' }}>*</span>
          </Typography>
          <InputField
            {...register('X509Certificate')}
            {...utils.form.getFieldProp(fields, 'X509Certificate')}
            error={!!errors?.X509Certificate?.message}
            inputProps={{
              'data-testid': 'X509Certificate',
            }}
          />
          {!!errors?.X509Certificate?.message && (
            <FormHelperText sx={{ fontSize: '14px', color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
              {errors.X509Certificate.message}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl sx={formControlStyle}>
          <Typography variant="body1" color={theme.palette.primary.textColor} pb={0.5} sx={textStyles}>
            Username mapping <span style={{ color: '#ff0000' }}>*</span>
          </Typography>
          <InputField
            {...register('UsernameMapping')}
            {...utils.form.getFieldProp(fields, 'UsernameMapping')}
            error={!!errors?.UsernameMapping?.message}
            inputProps={{
              'data-testid': 'UsernameMapping',
            }}
          />
          {!!errors?.UsernameMapping?.message && (
            <FormHelperText sx={{ fontSize: '14px', color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
              {errors.UsernameMapping.message}
            </FormHelperText>
          )}
        </FormControl>
      </Box>
      <Divider />
      <Box id="actionsButtons" px={3} py={2}>
        <Grid container spacing={1} alignItems="flex-start" justifyContent="flex-end">
          <Grid item>
            {cancelButtonLabel && (
              <Button variant="text" sx={{ textTransform: 'none' }} onClick={handlers.cancelHandler}>
                {cancelButtonLabel}
              </Button>
            )}
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              sx={{
                textTransform: 'none',
                p: '0.4rem 1.5rem',
                boxShadow: 'none',
              }}
              type="submit"
            >
              {samlSSOSettingsId ? 'Update' : 'Create'}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

const InputField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    borderRadius: '2px',
  },
}));
