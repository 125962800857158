import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { useNavigate, useLocation } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

//app
import { consts, config } from 'globalsData';
import { LoginScreen } from 'Pages';
import { setUserLoggedIn, getUserDetails, getUser, snackbarNotification } from 'stores';

// mui
import SnackbarNotification from 'components/SnackbarNotification/SnackbarNotification';
import { accessToken, userLoggedIn } from 'utils/api/api';
import { Loader, ErrorBoundary, Modal } from 'components';

const Auth = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const ssoToken = location?.search?.split('?token=')[1];

  if (ssoToken) {
    localStorage.setItem('accessToken', ssoToken);
    localStorage.setItem('userLoggedIn', true);
  }

  const isAuthenticated = useSelector((state) => get(state, 'user.userLoggedIn'));

  const token = accessToken();
  const isUserLoggedIn = JSON.parse(userLoggedIn());

  useEffect(() => {
    if (isUserLoggedIn) {
      if (token) {
        try {
          const decoded = jwtDecode(token);
          if (decoded.exp * 1000 < new Date()) {
            throw new Error('Token has Expired. Please login again');
          } else {
            dispatch(getUserDetails({ email: decoded?.sub }));
            dispatch(setUserLoggedIn(true));
          }
        } catch (error) {
          localStorage.clear();
          dispatch(setUserLoggedIn(false));
          navigate(config.routes.home.root);
          dispatch(snackbarNotification(error?.message.split(':')[0], 'error'));
        }
      } else {
        localStorage.clear();
        dispatch(setUserLoggedIn(false));
        navigate(config.routes.home.root);
      }
    } else {
      localStorage.clear();
      dispatch(setUserLoggedIn(false));
      navigate(config.routes.home.root);
    }
  }, [token, dispatch, isUserLoggedIn, navigate]);

  const loginHandler = (loginCredential) => {
    dispatch(getUser(loginCredential)).then((res) => {
      if (res?.status === 200) {
        try {
          if (res?.data?.token) {
            const decoded = jwtDecode(res?.data?.token);
            localStorage.setItem('accessToken', res?.data?.token);
            localStorage.setItem('userLoggedIn', true);
            dispatch(getUserDetails({ email: decoded?.sub }));
            dispatch(setUserLoggedIn(true));
            navigate(`${config.routes.requestTracking.root}/${consts.MY_REQUEST}`);
          }
        } catch (error) {
          dispatch(snackbarNotification(error?.message.split(':')[0], 'error'));
        }
      }
    });
  };

  if (isUserLoggedIn || isAuthenticated) {
    if (token) {
      try {
        jwtDecode(token);
        return children;
      } catch (error) {
        dispatch(snackbarNotification(error?.message.split(':')[0], 'error'));
        return (
          <>
            <LoginScreen loginHandler={loginHandler} />
            <SnackbarNotification />
          </>
        );
      }
    } else {
      dispatch(snackbarNotification('Invalid User', 'error'));
      return (
        <>
          <LoginScreen loginHandler={loginHandler} />
          <SnackbarNotification />
        </>
      );
    }
  } else {
    return (
      <>
        <LoginScreen loginHandler={loginHandler} />
        <SnackbarNotification />
        <Loader label="Loading..." />
        <ErrorBoundary>
          <Modal />
        </ErrorBoundary>
      </>
    );
  }
};

export default Auth;
