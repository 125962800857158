import React, { Suspense, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// app
import { Header, SideDrawer, Modal, Loader, SnackbarNotification, ErrorBoundary, AccessControl } from 'components';
import { config, consts } from 'globalsData';
import { Blank, RequestTracking, Configuration, RequestDetails, UserManagement } from 'Pages';
import { validateRoute } from 'stores';

// mui
import { useTheme } from '@mui/material/styles';
import { Box, Stack } from '@mui/material';

export function AppView() {
  const theme = useTheme();

  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    const dynamicTab = location.pathname.split('/')[2];
    if (dynamicTab) {
      const isRouteMatched = [consts.MY_REQUEST, consts.OPEN_REQUEST, consts.CLOSED_REQUEST, consts.ALL_REQUEST].includes(dynamicTab);
      dispatch(validateRoute(isRouteMatched));
      if (!isRouteMatched) {
        navigate(`${config.routes.requestTracking.root}/${consts.MY_REQUEST}`);
      }
    }
  }, [location, navigate, dispatch]);

  return (
    <Box>
      <Box sx={{ background: theme.palette.primary.backgroundColor }}>
        <Header />
        <Stack direction="row">
          <SideDrawer />
          <Box
            pt={8}
            sx={{
              width: '100vw',
              minHeight: '89vh',
              backgroundColor: theme.palette.background,
            }}
          >
            <Suspense fallback={<Loader visible />}>
              <ErrorBoundary>
                <Routes>
                  <Route
                    exact
                    path={`${config.routes.home.root}`}
                    element={<Navigate to={`${config.routes.requestTracking.root}/${consts.MY_REQUEST}`} />}
                  />
                  <Route
                    exact
                    path={`${config.routes.home.root}/${config.routes.requestTracking.root}`}
                    element={<Navigate to={`${config.routes.requestTracking.root}/${consts.MY_REQUEST}`} />}
                  />
                  <Route exact path={`${config.routes.requestTracking.root}/:request`} element={<RequestTracking />} />
                  <Route exact path={`${config.routes.requestTracking.root}/:request/details/:requestId`} element={<RequestDetails />} />
                  <Route
                    exact
                    path={`${config.routes.configuration.root}`}
                    element={
                      <AccessControl>
                        <Configuration />
                      </AccessControl>
                    }
                  />
                  <Route
                    exact
                    path={`${config.routes.userManagement.root}`}
                    element={
                      <AccessControl>
                        <UserManagement />
                      </AccessControl>
                    }
                  />
                  <Route path="*" element={<Blank />} />
                </Routes>
              </ErrorBoundary>
            </Suspense>
          </Box>
        </Stack>
        <ErrorBoundary>
          <Modal />
        </ErrorBoundary>
        <Loader label="Loading..." />
        <SnackbarNotification />
      </Box>
    </Box>
  );
}
