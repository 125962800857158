import { api } from 'utils';
import { snackbarNotification, removeLoader, addLoader, getConfigurationTableRowsSuccess } from 'stores';

export const getConfigurationTableData = (paginationModel) => async (dispatch) => {
  dispatch(addLoader('fetching table row(s)'));

  try {
    try {
      const res = await api.get({
        endpoint: `${process.env.REACT_APP_CBT_SERVICE}`,
        path: 'admin/config',
        params: { page: paginationModel.page, size: paginationModel.pageSize },
      });
      dispatch(getConfigurationTableRowsSuccess(res?.data));
      return res;
    } catch (error) {
      dispatch(snackbarNotification(error?.response?.data?.message || 'Failed to fetch configuration(s)', 'error'));
      return error;
    }
  } finally {
    dispatch(removeLoader('fetching table row(s)'));
  }
};
