import React from 'react';
import PropTypes from 'prop-types';

// app
import * as utils from 'utils';

// mui
import { Box, Button, Grid, Typography, useTheme, TextField, FormControl, FormHelperText, styled, Divider } from '@mui/material';

SignUpFormView.propTypes = {
  fields: PropTypes.array.isRequired,
  cancelButtonLabel: PropTypes.string.isRequired,
  formProps: PropTypes.object.isRequired,
  isValidConfirmPassword: PropTypes.bool.isRequired,
  handlers: PropTypes.shape({
    onSubmit: PropTypes.func.isRequired,
    cancelHandler: PropTypes.func.isRequired,
  }),
};

export default function SignUpFormView({ fields, cancelButtonLabel, isValidConfirmPassword, formProps, handlers }) {
  const theme = useTheme();
  const { register, handleSubmit, errors } = formProps;
  const textStyles = { fontSize: '14px', fontWeight: 600 };

  return (
    <Box
      sx={{ backgroundColor: '#fff', color: theme.palette.primary.textColor }}
      component="form"
      onSubmit={handleSubmit(handlers.onSubmit)}
    >
      <Box px={3} py={2} sx={{ maxHeight: '240px', height: '240px', overflowX: 'hidden', overflowY: 'auto' }}>
        <FormControl sx={{ width: '100%', paddingBottom: '1rem' }}>
          <Typography variant="body1" color={theme.palette.primary.textColor} pb={0.5} sx={textStyles}>
            Email <span style={{ color: '#ff0000' }}>*</span>
          </Typography>
          <InputField
            {...register('email')}
            {...utils.form.getFieldProp(fields, 'email')}
            error={!!errors?.email?.message}
            inputProps={{
              'data-testid': 'email',
            }}
          />
          {!!errors?.email?.message && (
            <FormHelperText sx={{ fontSize: '14px', color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
              {errors.email.message}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl sx={{ width: '100%', paddingBottom: '1rem' }}>
          <Typography variant="body1" color={theme.palette.primary.textColor} pb={0.5} sx={textStyles}>
            Password <span style={{ color: '#ff0000' }}>*</span>
          </Typography>
          <InputField
            {...register('password')}
            {...utils.form.getFieldProp(fields, 'password')}
            error={!!errors?.password?.message}
            inputProps={{
              'data-testid': 'password',
            }}
          />
          {!!errors?.password?.message && (
            <FormHelperText sx={{ fontSize: '14px', color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
              {errors.password.message}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl sx={{ width: '100%', paddingBottom: '1rem' }}>
          <Typography variant="body1" color={theme.palette.primary.textColor} pb={0.5} sx={textStyles}>
            Confirm Password <span style={{ color: '#ff0000' }}>*</span>
          </Typography>
          <InputField
            {...register('confirmPassword')}
            {...utils.form.getFieldProp(fields, 'confirmPassword')}
            error={!!errors?.confirmPassword?.message}
            inputProps={{
              'data-testid': 'confirmPassword',
            }}
          />
          {!!errors?.confirmPassword?.message && (
            <FormHelperText sx={{ fontSize: '14px', color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
              {errors.confirmPassword.message}
            </FormHelperText>
          )}
          {!isValidConfirmPassword && (
            <FormHelperText sx={{ fontSize: '14px', color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
              Your password and confirmation password do not match
            </FormHelperText>
          )}
        </FormControl>
      </Box>
      <Divider />
      <Box id="actionsButtons" px={3} py={2}>
        <Grid container spacing={1} alignItems="flex-start" justifyContent="flex-end">
          <Grid item>
            {cancelButtonLabel && (
              <Button variant="text" sx={{ textTransform: 'none' }} onClick={handlers.cancelHandler}>
                {cancelButtonLabel}
              </Button>
            )}
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              sx={{
                textTransform: 'none',
                p: '0.4rem 1.5rem',
                boxShadow: 'none',
              }}
              type="submit"
            >
              Create Account
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

const InputField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    borderRadius: '2px',
  },
}));
