import { api } from 'utils';
import { snackbarNotification, removeLoader, addLoader } from 'stores';

export const updateSAMLSSOSettings = (samlSSOSettings) => async (dispatch) => {
  dispatch(addLoader({ key: 'updatingSamlSSOSettings', message: 'Updating SAML SSO settings...' }));

  try {
    try {
      const res = await api.put({
        endpoint: `${process.env.REACT_APP_CBT_SERVICE}`,
        path: 'saml/configuration',
        data: samlSSOSettings,
      });
      return res;
    } catch (error) {
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      return error;
    }
  } finally {
    dispatch(removeLoader('updatingSamlSSOSettings'));
  }
};
