import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';

//mui
import { IconButton, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsIcon from '@mui/icons-material/Settings';

//app
import { consts } from 'globalsData';
import ConfigurationView from './Configuration.view';
import { BootstrapTooltip, DataGridCellExpand } from 'components';
import {
  showModal,
  getConfigurationTableData,
  searchConfiguration,
  deleteConfiguration,
  hideModal,
  snackbarNotification,
  isSearchedTableSuccess,
  getConfigTableCurrentPage,
  getCurrentRequestTablePage,
  getIsNavigatedFromDetailsPage,
  getSearchedConfigurationTableRowsSuccess,
  getSAMLSSOSettings,
  getUserManagementTableCurrentPage,
} from 'stores';

export default function Configuration() {
  const [searchText, setSearchText] = useState('');

  const dispatch = useDispatch();
  const currentPageNumber = useSelector((state) => get(state, 'configuration.currentPageNumber'));
  const configurationTableData = useSelector((state) => get(state, 'configuration.configurationTableRows'));
  const searchedConfigurationTableData = useSelector((state) => get(state, 'configuration.searchedConfigurationTableRows'));
  const isTableDataSearched = useSelector((state) => get(state, 'configuration.isSearched'));
  const searchedEditedConfigData = useSelector((state) => get(state, 'configuration.searchedEditedConfigData'));
  const samlSSOSettings = useSelector((state) => get(state, 'configuration.samlSSOSettings'));

  const paginationModel = useMemo(() => ({ page: currentPageNumber, pageSize: 10 }), [currentPageNumber]);

  useEffect(() => {
    dispatch(getConfigurationTableData({ page: 0, pageSize: 10 }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCurrentRequestTablePage(0));
    dispatch(getUserManagementTableCurrentPage(0));
    dispatch(getIsNavigatedFromDetailsPage(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!searchText && isTableDataSearched) {
      dispatch(getConfigurationTableData({ page: 0, pageSize: 10 }));
      dispatch(getConfigTableCurrentPage(0));
      dispatch(isSearchedTableSuccess(false));
    }
  }, [dispatch, searchText, isTableDataSearched]);

  const prepareTableData = (tableData) => {
    if (tableData && Boolean(tableData?.content?.length)) {
      return tableData?.content?.map((item) => ({
        id: item.configId,
        product: item.product,
        service: item.service,
        platform: item.platform,
        platformURI: item.platformURI,
        platformApiKey: item.platformApiKey,
        objectType: item.objectTypes,
        email: item.email,
      }));
    } else {
      return [];
    }
  };

  let currentConfigurationTableData = {};

  if (searchedConfigurationTableData?.content?.length && isTableDataSearched) {
    let searchResults = [];
    if (searchedEditedConfigData?.content?.length) {
      searchResults = searchedConfigurationTableData?.content?.map((searchedConfig) => {
        if (searchedConfig.configId === searchedEditedConfigData?.content[0]?.configId) {
          return searchedEditedConfigData?.content[0];
        } else {
          return searchedConfig;
        }
      });
    } else {
      searchResults = searchedConfigurationTableData?.content;
    }
    currentConfigurationTableData = { content: searchResults, totalElements: searchedConfigurationTableData?.totalElements };
  } else if (Object.entries(searchedConfigurationTableData).length === 0 && isTableDataSearched) {
    currentConfigurationTableData = {};
  } else {
    currentConfigurationTableData = configurationTableData;
  }

  const tableRows = prepareTableData(currentConfigurationTableData);
  const rowCount = currentConfigurationTableData?.totalElements ? currentConfigurationTableData?.totalElements : 0;

  const configurationDeleteHandler = (rowToDelete) => {
    dispatch(deleteConfiguration(rowToDelete?.id)).then((res) => {
      if (res?.status === 204) {
        dispatch(hideModal('CONFIRM'));
        dispatch(snackbarNotification('Deleted configuration successully', 'success'));
        if (isTableDataSearched) {
          dispatch(searchConfiguration(searchText, paginationModel))?.then((res) => {
            if (res?.status !== 200 && paginationModel?.page > 0) {
              document.querySelector('[aria-label="Go to previous page"]').click();
            } else if (res?.status === 204) {
              dispatch(getSearchedConfigurationTableRowsSuccess({}));
            } else {
              dispatch(getSearchedConfigurationTableRowsSuccess(res?.data));
            }
          });
        } else {
          dispatch(getConfigurationTableData(paginationModel))?.then((res) => {
            if (res?.status === 204 && paginationModel?.page > 0) {
              document.querySelector('[aria-label="Go to previous page"]').click();
            }
          });
        }
      } else {
        dispatch(hideModal('CONFIRM'));
        dispatch(snackbarNotification(res?.response?.data?.message || 'configuration deletion failed', 'error'));
      }
    });
  };

  const getRowData = (configMode, configId) => {
    if (configMode === consts.EDIT && isTableDataSearched && configId) {
      dispatch(searchConfiguration(configId, { page: 0, pageSize: 10 }, consts.EDIT_SEARCH)).then((res) => {
        if (res?.status === 200) {
          dispatch(hideModal('ADD_EDIT_CONFIGURATION'));
          dispatch(snackbarNotification(`${configMode === consts.CREATE ? 'Created' : 'Updated'} configuration successully`, 'success'));
        }
      });
    } else {
      dispatch(getConfigurationTableData({ ...paginationModel, ...(configMode === consts.CREATE && { page: 0 }) })).then((res) => {
        if (res?.status === 200) {
          if (configMode === consts.CREATE) {
            dispatch(getConfigTableCurrentPage(0));
          }
          dispatch(hideModal('ADD_EDIT_CONFIGURATION'));
          dispatch(snackbarNotification(`${configMode === consts.CREATE ? 'Created' : 'Updated'} configuration successully`, 'success'));
        }
      });
    }
  };

  const handleObjectTypesConfiguration = (e, rowData) => {
    e.stopPropagation();
    const filterConfigRowData =
      currentConfigurationTableData?.content?.length &&
      currentConfigurationTableData?.content?.filter((item) => item?.configId === rowData.id);

    if (filterConfigRowData) {
      dispatch(
        showModal({
          component: 'ADD_EDIT_OBJECT_TYPES_CONFIG_FORM',
          props: {
            title: 'Object Configuration',
            maxWidth: 'lg',
            componentProps: {
              data: filterConfigRowData[0],
              cancelButtonLabel: 'Cancel',
              cancelHandler: () => {
                dispatch(hideModal('ADD_EDIT_OBJECT_TYPES_CONFIG_FORM'));
              },
              submitHandler: (configId) => {
                dispatch(hideModal('ADD_EDIT_OBJECT_TYPES_CONFIG_FORM'));
              },
            },
          },
        })
      );
    }
  };

  const renderSettingsIcon = (params) => {
    const disableObjectConfigIcon = params?.row?.platform === 'polarion' || params?.row?.platform === 'Polarion';
    return (
      <>
        {disableObjectConfigIcon && (
          <IconButton aria-label="Configure Object Types" disabled>
            <SettingsIcon fontSize="small" />
          </IconButton>
        )}
        {!disableObjectConfigIcon && (
          <BootstrapTooltip title="Configure Object Type(s)" placement="left">
            <IconButton
              sx={{ cursor: 'pointer' }}
              aria-label="Configure Object Types"
              onClick={(e) => handleObjectTypesConfiguration(e, params?.row)}
            >
              <SettingsIcon fontSize="small" />
            </IconButton>
          </BootstrapTooltip>
        )}
      </>
    );
  };

  const handleEdit = (e, rowData) => {
    e.stopPropagation();
    const filterRowData =
      currentConfigurationTableData?.content?.length &&
      currentConfigurationTableData?.content?.filter((item) => item?.configId === rowData.id);

    if (filterRowData) {
      dispatch(
        showModal({
          component: 'ADD_EDIT_CONFIGURATION',
          props: {
            title: 'Edit Configuration',
            maxWidth: 'sm',
            componentProps: {
              data: filterRowData[0],
              cancelButtonLabel: 'Cancel',
              cancelHandler: () => {
                dispatch(hideModal('ADD_EDIT_CONFIGURATION'));
              },
              submitHandler: (configId) => {
                getRowData(consts.EDIT, configId);
              },
            },
          },
        })
      );
    }
  };

  const renderEditIcon = (params) => {
    return (
      <BootstrapTooltip title="Edit" placement="left">
        <IconButton sx={{ cursor: 'pointer' }} aria-label="Edit Row" onClick={(e) => handleEdit(e, params?.row)}>
          <EditIcon fontSize="small" />
        </IconButton>
      </BootstrapTooltip>
    );
  };

  const handleDelete = (e, rowData) => {
    e.stopPropagation();
    if (rowData) {
      dispatch(
        showModal({
          component: 'CONFIRM',
          props: {
            title: 'Delete Configuration',
            maxWidth: 'sm',
            componentProps: {
              data: null,
              confirmText: (
                <Typography variant="subtitle2">{`Are you sure to delete the configuration ${rowData?.id} as it may impact the associated request(s)?`}</Typography>
              ),
              cancelButtonLabel: 'No',
              confirmButtonLabel: 'Yes',
              cancelHandler: () => {
                dispatch(hideModal('CONFIRM'));
              },
              submitHandler: () => {
                configurationDeleteHandler(rowData);
              },
            },
          },
        })
      );
    }
  };

  const renderDeletetIcon = (params) => {
    return (
      <BootstrapTooltip title="Delete" placement="right">
        <IconButton sx={{ cursor: 'pointer' }} aria-label="Delete Row" onClick={(e) => handleDelete(e, params?.row)}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      </BootstrapTooltip>
    );
  };

  const handleCreateConfiguration = (e) => {
    e.stopPropagation();
    dispatch(
      showModal({
        component: 'ADD_EDIT_CONFIGURATION',
        props: {
          title: 'New Configuration',
          maxWidth: 'sm',
          componentProps: {
            data: null,
            cancelButtonLabel: 'Cancel',
            cancelHandler: () => {
              dispatch(hideModal('ADD_EDIT_CONFIGURATION'));
            },
            submitHandler: () => {
              getRowData(consts.CREATE);
            },
          },
        },
      })
    );
  };

  const handleCreateSSOSettings = (e) => {
    e.stopPropagation();
    dispatch(
      showModal({
        component: 'ADD_EDIT_SSO_FORM',
        props: {
          title: 'SAML SSO Settings',
          maxWidth: 'sm',
          componentProps: {
            data: null,
            cancelButtonLabel: 'Cancel',
            cancelHandler: () => {
              dispatch(hideModal('ADD_EDIT_SSO_FORM'));
            },
            submitHandler: () => {
              dispatch(getSAMLSSOSettings());
              dispatch(hideModal('ADD_EDIT_SSO_FORM'));
              dispatch(
                snackbarNotification(
                  `SSO configuration ${Object.keys(samlSSOSettings).length ? 'updated' : 'saved'} successfully`,
                  'success'
                )
              );
            },
          },
        },
      })
    );
  };

  const handleOnSearch = (e) => {
    e.preventDefault();
    dispatch(getConfigTableCurrentPage(0));
    dispatch(isSearchedTableSuccess(true));
    dispatch(searchConfiguration(searchText, { page: 0, pageSize: 10 }));
  };

  const handleSearchReset = () => {
    setSearchText('');
    dispatch(getConfigTableCurrentPage(0));
    dispatch(isSearchedTableSuccess(false));
  };

  const onPageChange = (page) => {
    dispatch(getConfigTableCurrentPage(page));
    if (isTableDataSearched) {
      dispatch(searchConfiguration(searchText, { page, pageSize: 10 }));
    } else {
      dispatch(getConfigurationTableData({ page, pageSize: 10 }));
    }
  };

  const columns = [
    {
      field: 'id',
      renderHeader: () => <strong>ID</strong>,
      sortable: false,
      flex: 0.5,
      renderCell: (params) => <DataGridCellExpand value={params?.row?.id ?? ''} />,
    },
    {
      field: 'product',
      renderHeader: () => <strong>Product</strong>,
      sortable: false,
      flex: 1,
      renderCell: (params) => <DataGridCellExpand value={params?.row?.product ?? ''} />,
    },
    {
      field: 'service',
      renderHeader: () => <strong>Service</strong>,
      sortable: false,
      flex: 1,
      renderCell: (params) => <DataGridCellExpand value={params?.row?.service ?? ''} />,
    },
    {
      field: 'email',
      renderHeader: () => <strong>Email/Username</strong>,
      sortable: false,
      flex: 1,
      renderCell: (params) => <DataGridCellExpand value={params?.row?.email ?? ''} />,
    },
    {
      field: 'platform',
      renderHeader: () => <strong>Platform</strong>,
      sortable: false,
      flex: 0.7,
      renderCell: (params) => <DataGridCellExpand value={params?.row?.platform ?? ''} />,
    },
    {
      field: 'platformURI',
      renderHeader: () => <strong>Platform URI/URL</strong>,
      sortable: false,
      flex: 1,
      renderCell: (params) => <DataGridCellExpand value={params?.row?.platformURI ?? ''} />,
    },
    {
      field: 'platformApiKey',
      renderHeader: () => <strong>Platform API key</strong>,
      sortable: false,
      flex: 1,
      renderCell: (params) => <DataGridCellExpand value={params?.row?.platformApiKey ?? ''} />,
    },
    {
      field: 'objectType',
      renderHeader: () => <strong>Object Type(s)</strong>,
      sortable: false,
      flex: 0.8,
      renderCell: (params) => <DataGridCellExpand value={params?.row?.objectType ?? ''} />,
    },
    {
      field: 'configureObjectTypes',
      headerName: '',
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: renderSettingsIcon,
      flex: 0.3,
    },
    {
      field: 'editRow',
      headerName: '',
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: renderEditIcon,
      flex: 0.3,
    },
    {
      field: 'deleteRow',
      headerName: '',
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: renderDeletetIcon,
      flex: 0.3,
    },
  ];

  return (
    <ConfigurationView
      rows={tableRows}
      columns={columns}
      rowCount={rowCount}
      page={paginationModel.page}
      pageSize={paginationModel.pageSize}
      searchText={searchText}
      handlers={{ handleOnSearch, handleCreateConfiguration, setSearchText, onPageChange, handleSearchReset, handleCreateSSOSettings }}
    />
  );
}
