export const getConfigurationTableRowsSuccess = (data) => {
  return {
    type: 'GET_CONFIGURATION_TABLE_ROWS_SUCCESS',
    payload: data,
  };
};

export const getSearchedConfigurationTableRowsSuccess = (data) => {
  return {
    type: 'GET_SEARCHED_CONFIGURATION_TABLE_ROWS_SUCCESS',
    payload: data,
  };
};

export const isSearchedTableSuccess = (data) => {
  return {
    type: 'IS_SEARCHED',
    payload: data,
  };
};

export const getConfigTableCurrentPage = (data) => {
  return {
    type: 'GET_CURRENT_PAGE_NUMBER',
    payload: data,
  };
};

export const getOnEditSearchConfigResult = (data) => {
  return {
    type: 'GET_EDIT_SEARCH_CONFIG_RESULT',
    payload: data,
  };
};

export const getSAMLSSOSettingsSuccess = (data) => {
  return {
    type: 'GET_SAML_SSO_SETTINGS',
    payload: data,
  };
};
