import React from 'react';
import PropTypes from 'prop-types';

// app
import { BootstrapTooltip } from 'components';
import * as utils from 'utils';
import UserIcon from 'assets/svg/UserIcon.svg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SiemensLogo from 'assets/svg/logo.svg';

// mui
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import { MenuItem, Menu, Grid, Typography, Toolbar, IconButton, Divider, Box, Hidden } from '@mui/material';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

HeaderView.propTypes = {
  anchorEl: PropTypes.object,
  userEmail: PropTypes.string,
  mobile: PropTypes.bool.isRequired,
  handlers: PropTypes.shape({
    handleDrawerOpen: PropTypes.func.isRequired,
    handleMenu: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    appLogoClick: PropTypes.func.isRequired,
  }).isRequired,
};

export function HeaderView({ anchorEl, userEmail, handlers }) {
  return (
    <AppBar
      position="fixed"
      open={false}
      sx={{ boxShadow: 'none', backgroundColor: (theme) => theme.palette.primary.white, borderBottom: '1px solid #ccc' }}
    >
      <Toolbar variant="dense">
        <Grid container spacing={1} alignItems="center" justifyContent="space-between">
          <Grid item xs={6} sm={6}>
            <Box display="flex" alignItems="center">
              <Typography variant="h6" component="div" fontWeight={600}>
                <img
                  src={SiemensLogo}
                  alt="Siemens Logo"
                  width={100}
                  height={20}
                  onClick={() => handlers.appLogoClick()}
                  style={{ cursor: 'pointer' }}
                />
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={6} textAlign="right">
            <Hidden smDown>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="160px"
                m="auto 0 auto auto"
                borderRadius="32px"
                p="3px"
                sx={{ cursor: 'pointer' }}
                onClick={handlers.handleMenu}
              >
                <BootstrapTooltip title={userEmail?.length > 10 ? userEmail : ''}>
                  <Typography
                    pr="4px"
                    pl="8px"
                    variant="body2"
                    color={(theme) => theme.palette.primary.requestHeaderTextColor}
                    sx={{ cursor: 'pointer' }}
                  >
                    {utils.app.getEllipsisString(userEmail, 10)}
                  </Typography>
                </BootstrapTooltip>
                <IconButton size="small" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true">
                  <KeyboardArrowDownIcon />
                </IconButton>
              </Box>
            </Hidden>
            <Hidden smUp>
              <IconButton
                size="small"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                sx={{ padding: '0 8px 0 4px' }}
                onClick={handlers.handleMenu}
              >
                <img src={UserIcon} alt="User Icon" width={18} height={18} />
                <KeyboardArrowDownIcon fontSize="small" />
              </IconButton>
            </Hidden>

            <Menu
              id="menu-appbar"
              sx={{ mt: '35px' }}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handlers.handleClose}
            >
              {userEmail && (
                <Box pb={1}>
                  <Box py={1} px={2} display="flex">
                    <EmailOutlinedIcon />
                    <BootstrapTooltip title={userEmail?.length > 25 ? userEmail : ''}>
                      <Typography pl={1}>{utils.app.getEllipsisString(userEmail, 25)}</Typography>
                    </BootstrapTooltip>
                  </Box>
                  <Divider />
                </Box>
              )}
              <MenuItem onClick={handlers.logout}>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography pl={0.1}>Logout</Typography>
                  </Grid>
                  <Grid item>
                    <LogoutOutlinedIcon sx={{ color: (theme) => theme.palette.primary.main }} />
                  </Grid>
                </Grid>
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));
