import React from 'react';
import PropTypes from 'prop-types';

//mui
import {
  Box,
  styled,
  TextField,
  Divider,
  Grid,
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  IconButton,
} from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

//app
import { theme } from 'theme';
import { BootstrapTooltip } from 'components';

AddEditObjectTypesConfigurationFormView.propTypes = {
  fields: PropTypes.array.isRequired,
  cancelButtonLabel: PropTypes.string.isRequired,
  formProps: PropTypes.object.isRequired,
  displayFlagList: PropTypes.array.isRequired,
  currentObjectType: PropTypes.string.isRequired,
  dropDownObjectTypeList: PropTypes.array.isRequired,
  mandatoryFieldList: PropTypes.array.isRequired,
  handlers: PropTypes.shape({
    onSubmit: PropTypes.func.isRequired,
    cancelHandler: PropTypes.func.isRequired,
    handleObjectTypeDropDown: PropTypes.func.isRequired,
    handleFormState: PropTypes.func.isRequired,
  }),
};

function AddEditObjectTypesConfigurationFormView({
  cancelButtonLabel,
  handlers,
  dropDownObjectTypeList,
  currentObjectType,
  fields,
  formProps,
  displayFlagList,
  mandatoryFieldList,
}) {
  const textStyles = { fontSize: '14px', fontWeight: 600 };
  const { register, handleSubmit, errors } = formProps;
  const customRowHeight = Object.keys(errors)?.length > 0 ? '92px' : '65px';
  const fieldWrapperStyles = { flex: 1, mr: 2, height: customRowHeight };

  return (
    <>
      <Box display="flex" px={3} pt={2}>
        <Box id="objectTypes" flex={1.985} mr={2}>
          <Typography fontSize={16} textAlign="left" pb={0.5} sx={textStyles}>
            Object Type <span style={{ color: '#ff0000' }}>*</span>
          </Typography>
          <FormControl fullWidth>
            <Select
              MenuProps={{ style: { maxHeight: 200 } }}
              size="small"
              sx={{ borderRadius: '2px' }}
              value={currentObjectType}
              displayEmpty
              onChange={handlers.handleObjectTypeDropDown}
            >
              {dropDownObjectTypeList?.map((objectType) => (
                <MenuItem value={objectType.value} key={objectType.id}>
                  {objectType.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {currentObjectType && (
          <Box id="addRowIcon" display="flex" alignItems="flex-end" height="65px">
            <BootstrapTooltip title="Add row" placement="top">
              <IconButton sx={{ cursor: 'pointer', height: '40px' }} aria-label="add row" onClick={handlers.handleAddRow}>
                <AddCircleOutlineIcon fontSize="medium" />
              </IconButton>
            </BootstrapTooltip>
          </Box>
        )}
      </Box>
      <Box
        sx={{ backgroundColor: '#fff', color: theme.palette.primary.textColor }}
        component="form"
        onSubmit={handleSubmit(handlers.onSubmit)}
      >
        <Box px={3} py={2} sx={{ maxHeight: '180px', height: '180px', overflowX: 'hidden', overflowY: 'auto' }}>
          {fields?.map((field, index) => (
            <Box key={field.id} display="flex" py={1}>
              <Box id="dataType" {...fieldWrapperStyles}>
                <Typography variant="body1" color={theme.palette.primary.textColor} pb={0.5} sx={textStyles}>
                  Data Type <span style={{ color: '#ff0000' }}>*</span>
                </Typography>
                <InputField
                  {...register(`formRowsData.${index}.dataType`, { required: 'Data Type is required' })}
                  onBlur={handlers.handleFormState}
                  size="small"
                  error={Object.keys(errors)?.length > 0 ? !!errors?.formRowsData[index]?.dataType?.message : false}
                />
                {Object.keys(errors)?.length > 0 && !!errors?.formRowsData[index]?.dataType?.message && (
                  <FormHelperText sx={{ fontSize: '14px', color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                    {errors?.formRowsData[index]?.dataType?.message}
                  </FormHelperText>
                )}
              </Box>
              <Box id="fieldId" {...fieldWrapperStyles}>
                <Typography variant="body1" color={theme.palette.primary.textColor} pb={0.5} sx={textStyles}>
                  Field ID <span style={{ color: '#ff0000' }}>*</span>
                </Typography>
                <InputField
                  {...register(`formRowsData.${index}.fieldId`, { required: 'Field ID is required' })}
                  onBlur={handlers.handleFormState}
                  size="small"
                  error={Object.keys(errors)?.length > 0 ? !!errors?.formRowsData[index]?.fieldId?.message : false}
                />
                {Object.keys(errors)?.length > 0 && !!errors?.formRowsData[index]?.fieldId?.message && (
                  <FormHelperText sx={{ fontSize: '14px', color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                    {errors?.formRowsData[index]?.fieldId?.message}
                  </FormHelperText>
                )}
              </Box>
              <Box id="fieldName" {...fieldWrapperStyles}>
                <Typography variant="body1" color={theme.palette.primary.textColor} pb={0.5} sx={textStyles}>
                  Field Name <span style={{ color: '#ff0000' }}>*</span>
                </Typography>
                <InputField
                  {...register(`formRowsData.${index}.fieldName`, { required: 'Field Name is required' })}
                  onBlur={handlers.handleFormState}
                  size="small"
                  error={Object.keys(errors)?.length > 0 ? !!errors?.formRowsData[index]?.fieldName?.message : false}
                />
                {Object.keys(errors)?.length > 0 && !!errors?.formRowsData[index]?.fieldName?.message && (
                  <FormHelperText sx={{ fontSize: '14px', color: (theme) => theme.palette.otherColor.errorText, marginLeft: '0' }}>
                    {errors?.formRowsData[index]?.fieldName?.message}
                  </FormHelperText>
                )}
              </Box>
              <Box id="mandatory" {...fieldWrapperStyles}>
                <Typography variant="body1" color={theme.palette.primary.textColor} pb={0.5} sx={textStyles}>
                  Mandatory <span style={{ color: '#ff0000' }}>*</span>
                </Typography>
                <FormControl fullWidth>
                  <Select
                    {...register(`formRowsData.${index}.fieldType`)}
                    defaultValue={field?.fieldType}
                    size="small"
                    sx={{ borderRadius: '2px' }}
                    onBlur={handlers.handleFormState}
                  >
                    {mandatoryFieldList?.map((mandatoryField) => (
                      <MenuItem value={mandatoryField.value} key={mandatoryField.id}>
                        {mandatoryField.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box id="defaultValue" {...fieldWrapperStyles}>
                <Typography variant="body1" color={theme.palette.primary.textColor} pb={0.5} sx={textStyles}>
                  Default Value
                </Typography>
                <InputField {...register(`formRowsData.${index}.defaultValue`)} onBlur={handlers.handleFormState} size="small" />
              </Box>
              <Box id="displayFlag" {...fieldWrapperStyles}>
                <Typography variant="body1" color={theme.palette.primary.textColor} pb={0.5} sx={textStyles}>
                  Display Flag
                </Typography>
                <FormControl fullWidth>
                  <Select
                    {...register(`formRowsData.${index}.displayFlag`)}
                    defaultValue={field?.displayFlag}
                    size="small"
                    sx={{ borderRadius: '2px' }}
                    onBlur={handlers.handleFormState}
                  >
                    {displayFlagList?.map((displayFlag) => (
                      <MenuItem value={displayFlag.value} key={displayFlag.id}>
                        {displayFlag.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box id="removeRowIcon" display="flex" alignItems="flex-end" height="65px">
                <BootstrapTooltip title="Remove row" placement="top">
                  <IconButton
                    sx={{ cursor: 'pointer', height: '40px' }}
                    aria-label="remove row"
                    onClick={() => {
                      handlers.handleDeleteRow(index);
                    }}
                  >
                    <RemoveCircleOutlineIcon fontSize="medium" />
                  </IconButton>
                </BootstrapTooltip>
              </Box>
            </Box>
          ))}
        </Box>
        <Divider />
        <Box id="actionsButtons" px={4} py={2}>
          <Grid container spacing={1} alignItems="flex-start" justifyContent="flex-end">
            <Grid item>
              {cancelButtonLabel && (
                <Button disabled={false} variant="text" sx={{ textTransform: 'none' }} onClick={handlers.cancelHandler}>
                  {cancelButtonLabel}
                </Button>
              )}
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                sx={{
                  textTransform: 'none',
                  p: '0.4rem 1.5rem',
                  boxShadow: 'none',
                }}
                type="submit"
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}

const InputField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    borderRadius: '2px',
  },
}));

export default React.memo(AddEditObjectTypesConfigurationFormView);
