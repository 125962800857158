import React from 'react';
import PropTypes from 'prop-types';

// app
import { BootstrapTooltip } from 'components';

// mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, List, ListItem, ListItemButton, ListItemIcon } from '@mui/material';

SideDrawerList.propTypes = {
  sideBarListFiltered: PropTypes.array.isRequired,
  handleSideDrawerIconClick: PropTypes.func.isRequired,
};

export default function SideDrawerList({ open, sideBarListFiltered, handleSideDrawerIconClick }) {
  const theme = useTheme();

  return (
    <React.Fragment>
      <DrawerHeader />
      <Box>
        <List disablePadding>
          {sideBarListFiltered.map((item) => (
            <BootstrapTooltip title={item?.title} placement="right" key={item.title}>
              <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  sx={{
                    minHeight: 52,
                    justifyContent: open ? 'initial' : 'center',
                    pl: 2,
                  }}
                  onClick={() => handleSideDrawerIconClick(item.link)}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 2 : 'auto',
                      justifyContent: 'center',
                      ...(item.isSelected && { color: theme.palette.primary.main }),
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            </BootstrapTooltip>
          ))}
        </List>
      </Box>
    </React.Fragment>
  );
}

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
