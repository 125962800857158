import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

// app
import { config, consts } from 'globalsData';
import { SideDrawerList } from 'components';
import { accessToken } from 'utils/api/api';

// mui
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import SubjectIcon from '@mui/icons-material/Subject';
import SettingsIcon from '@mui/icons-material/Settings';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

export default function SideDrawer() {
  const location = useLocation();
  const navigate = useNavigate();

  const token = accessToken();
  const decoded = jwtDecode(token);
  const isAdmin = decoded?.ADMIN || false;

  const locationParams = location.pathname.split('/');
  const locationFirstParam = locationParams.length > 0 && locationParams[1] ? locationParams[1] : null;

  const requestTrackingPageLink = () => {
    if (location.pathname.split('/')[1] === consts.CONFIGURATION) {
      return `${config.routes.requestTracking.root}/${consts.MY_REQUEST}`;
    } else {
      return `${config.routes.requestTracking.root}/${location.pathname.split('/')[2]}`;
    }
  };

  const sidebarList = [
    {
      name: consts.REQUESTTRACKING,
      title: consts.FEATURE_LIST_OBJ.requestTracking,
      icon: <SubjectIcon />,
      link: requestTrackingPageLink(),
    },
  ];

  if (isAdmin) {
    sidebarList.push(
      {
        name: consts.CONFIGURATION,
        title: consts.FEATURE_LIST_OBJ.configuration,
        icon: <SettingsIcon />,
        link: `${config.routes.configuration.root}`,
      },
      {
        name: consts.USER_MANAGEMENT,
        title: consts.FEATURE_LIST_OBJ.userManagement,
        icon: <ManageAccountsIcon />,
        link: `${config.routes.userManagement.root}`,
      }
    );
  }

  const sideBarListFiltered = sidebarList.map((item) => {
    const isDashboard = item.name?.toLowerCase() === locationFirstParam;
    item.isSelected = isDashboard;
    return item;
  });

  const handleSideDrawerIconClick = (link) => {
    navigate(link);
  };

  return (
    <React.Fragment>
      <Drawer
        variant="permanent"
        sx={{ width: 'calc(47px + 1px) !important' }}
        PaperProps={{ sx: { width: 'calc(47px + 1px) !important' } }}
      >
        <SideDrawerList sideBarListFiltered={sideBarListFiltered} handleSideDrawerIconClick={handleSideDrawerIconClick} />
      </Drawer>
    </React.Fragment>
  );
}

const openedMixin = (theme) => ({
  width: consts.SIDE_DRAWER_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: consts.SIDE_DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));
