import React from 'react';

//mui
import { styled } from '@mui/material/styles';
import { Tooltip, tooltipClasses } from '@mui/material';

const BootstrapTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.grey,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.grey,
    maxWidth: 150,
  },
}));

export default BootstrapTooltip;
