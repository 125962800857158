const consts = {
  APP_TITLE: 'Siemens BacklogOne',
  MOBILE_APP_TITLE_ELLIPSIS_LENGTH: 20,
  TABLET_APP_TITLE_ELLIPSIS_LENGTH: 15,
  SIDEBAR_MENU_TITLE_ELLIPISIS_LENGTH: 19,
  SIDE_DRAWER_WIDTH: 240,
  SNACKBAR_AUTO_HIDE_DURATION: 3000,
  MAX_FILE_SIZE: 2000000,
  MAX_FILE_COUNT: 5,
  PRODUCT: 'product',
  SERVICE: 'service',
  MY_REQUEST: 'my-request',
  OPEN_REQUEST: 'open-request',
  CLOSED_REQUEST: 'closed-request',
  ALL_REQUEST: 'all-request',
  CONFIGURATION: 'configuration',
  REQUESTTRACKING: 'request-tracking',
  USER_MANAGEMENT: 'user-management',
  EDIT_SEARCH: 'edit-search',
  REQUEST_DETAILS_SEARCH: 'request-details-search',
  ADMIN: 'Admin',
  USER: 'User',
  ROLE_ADMIN: 'ROLE_ADMIN',
  ROLE_USER: 'ROLE_USER',
  EDIT: 'edit',
  CREATE: 'create',
  FEATURE_LIST_OBJ: {
    home: 'Home',
    requestTracking: 'Request Tracking',
    configuration: 'Configuration',
    userManagement: 'User Management',
  },
  allowedFileTypes: ['jpeg', 'jpg', 'png', 'webp', 'pdf'],
  acceptFileTypesAttr: ['image/jpeg', 'image/jpg', 'image/png', 'image/webp', '.pdf'],
};
export default consts;
