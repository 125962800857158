import { api } from 'utils';
import { snackbarNotification, removeLoader, addLoader } from 'stores';

export const updateRequest = (updatedRequestFormData, userEmail) => async (dispatch) => {
  const reqPayload = {
    title: updatedRequestFormData?.summary,
    description: updatedRequestFormData?.requestDescription,
    ...(updatedRequestFormData?.files && { file: updatedRequestFormData?.files }),
  };
  const requestId = updatedRequestFormData?.id;

  dispatch(addLoader({ key: 'updatingRequest', message: 'Updating Request...' }));

  try {
    try {
      const res = await api.put({
        endpoint: `${process.env.REACT_APP_CBT_SERVICE}`,
        path: `request/${requestId}/${userEmail}`,
        data: reqPayload,
      });
      return res;
    } catch (error) {
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      return error;
    }
  } finally {
    dispatch(removeLoader('updatingRequest'));
  }
};
