import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

// redux
import { Provider } from 'react-redux';
import { applyMiddleware, legacy_createStore as createStore, compose } from 'redux';
import thunk from 'redux-thunk';

// app
import reducer from './stores';
import './index.css';
import { App, ErrorBoundary, Auth } from 'components';
import { theme } from './theme';

// mui
import { ThemeProvider } from '@mui/material';

// middleware
const middleware = [thunk];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducer, composeEnhancers(applyMiddleware(...middleware)));

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <ErrorBoundary>
          <Auth>
            <App />
          </Auth>
        </ErrorBoundary>
      </BrowserRouter>
    </ThemeProvider>
  </Provider>
);
