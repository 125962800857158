import { api } from 'utils';
import { addLoader, removeLoader, snackbarNotification } from 'stores';

export const signUp =
  ({ email, password, confirmPassword, role }) =>
  async (dispatch) => {
    dispatch(addLoader('signup user'));
    const data = {
      username: email,
      password,
      confirmPassword,
      role,
    };

    try {
      try {
        const res = await api.post({
          endpoint: `${process.env.REACT_APP_CBT_SERVICE}`,
          path: 'register',
          data,
        });
        return res;
      } catch (error) {
        dispatch(snackbarNotification(error?.response?.data?.error, 'error'));
        return error;
      }
    } finally {
      dispatch(removeLoader('signup user'));
    }
  };
