import { api } from 'utils';
import { consts } from 'globalsData';
import { snackbarNotification, removeLoader, addLoader, getAllRequestTableDataSuccess, getEditedSearchRequestResult } from 'stores';

export const searchRequest = (searchKey, paginationModel, searchContext) => async (dispatch) => {
  dispatch(
    addLoader({
      key: 'searchingRequest',
      message: `${searchContext === consts.REQUEST_DETAILS_SEARCH ? 'Loading Request Details...' : 'Loading...'}`,
    })
  );

  try {
    try {
      const res = await api.get({
        endpoint: `${process.env.REACT_APP_CBT_SERVICE}`,
        path: 'request/search',
        params: { searchKey, page: paginationModel.page, size: paginationModel.pageSize },
      });
      if (searchContext === consts.EDIT_SEARCH) {
        dispatch(getEditedSearchRequestResult(res?.data));
      } else {
        dispatch(getAllRequestTableDataSuccess(res?.data));
      }
      return res;
    } catch (error) {
      dispatch(
        snackbarNotification(
          error?.response?.data?.message ||
            `${searchContext === consts.REQUEST_DETAILS_SEARCH ? 'Failed to fetch request details' : 'Failed to search request(s)'}`,
          'error'
        )
      );
      return error;
    }
  } finally {
    dispatch(removeLoader('searchingRequest'));
  }
};
