import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';

//mui
import { IconButton, Menu, MenuItem, Box, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonIcon from '@mui/icons-material/Person';
import DeleteIcon from '@mui/icons-material/Delete';

//app
import UserManagementView from './UserManagement.view';
import { BootstrapTooltip, DataGridCellExpand } from 'components';
import { consts } from 'globalsData';
import { theme } from 'theme';
import {
  getUserManagementTableData,
  getUserManagementTableCurrentPage,
  updateUserRole,
  snackbarNotification,
  searchUser,
  isUserManagementTableSearched,
  getSearchedUserManagementTableRowsSuccess,
  getConfigTableCurrentPage,
  getCurrentRequestTablePage,
} from 'stores';

export default function UserManagement() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [currentTableRow, setCurrentTableRow] = useState({});

  const dispatch = useDispatch();
  const isTableDataSearched = useSelector((state) => get(state, 'userManagement.isSearched'));
  const currentPageNumber = useSelector((state) => get(state, 'userManagement.currentPageNumber'));
  const userManagementTableData = useSelector((state) => get(state, 'userManagement.userManagementTableData'));
  const searchedUserManagementTableData = useSelector((state) => get(state, 'userManagement.searchedUserManagementTableData'));
  const userDetails = useSelector((state) => get(state, 'user.userDetails', {}));

  const paginationModel = useMemo(() => ({ page: currentPageNumber, pageSize: 10 }), [currentPageNumber]);
  const userEmailId = userDetails?.email;

  useEffect(() => {
    dispatch(getUserManagementTableData({ page: 0, pageSize: 10 }));
    dispatch(getConfigTableCurrentPage(0));
    dispatch(getCurrentRequestTablePage(0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!searchText && isTableDataSearched) {
      dispatch(getSearchedUserManagementTableRowsSuccess({}));
      dispatch(isUserManagementTableSearched(false));
      dispatch(getUserManagementTableCurrentPage(0));
      dispatch(getUserManagementTableData({ page: 0, pageSize: 10 }));
    }
  }, [dispatch, isTableDataSearched, searchText]);

  const prepareTableData = (tableData) => {
    if (tableData && Boolean(tableData?.content?.length)) {
      return tableData?.content?.map((item) => ({
        id: item.id,
        name: item.name || '---',
        email: item.username,
        role: item.role === consts.ROLE_ADMIN ? consts.ADMIN : consts.USER,
        modifiedOn: item.modifieddate || '---',
        modifiedBy: item.modifiedby || '---',
      }));
    } else {
      return [];
    }
  };

  let currentUserManagementTableData = {};

  if (searchedUserManagementTableData?.content?.length && isTableDataSearched) {
    currentUserManagementTableData = searchedUserManagementTableData;
  } else if (Object.entries(searchedUserManagementTableData).length === 0 && isTableDataSearched) {
    currentUserManagementTableData = {};
  } else {
    currentUserManagementTableData = userManagementTableData;
  }

  const tableRows = prepareTableData(currentUserManagementTableData);
  const rowCount = currentUserManagementTableData?.totalElements ? currentUserManagementTableData?.totalElements : 0;

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleAdminRole = (role) => {
    handleMenuClose();
    const payload = {
      username: currentTableRow?.email,
      role,
    };
    if (currentTableRow?.email && userEmailId) {
      dispatch(updateUserRole(payload, userEmailId)).then((res) => {
        if (res?.status === 200) {
          if (res?.data === "User doesn't exist") {
            dispatch(snackbarNotification(res?.data, 'error'));
          } else {
            if (isTableDataSearched) {
              dispatch(searchUser(searchText, paginationModel));
            } else {
              dispatch(getUserManagementTableData(paginationModel)).then((res) => {
                if (res?.status === 200) {
                  dispatch(snackbarNotification('User role updated successfully', 'success'));
                } else {
                  dispatch(snackbarNotification('Failed to fetch data', 'error'));
                }
              });
            }
          }
        } else {
          dispatch(snackbarNotification('Failed to update user role', 'error'));
        }
      });
    }
  };

  const adminMenuItems = [
    {
      title: 'Make as Admin',
      role: consts.ROLE_ADMIN,
      icon: <PersonIcon sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
      handleMenuItemClick: handleAdminRole,
      disabled: currentTableRow.role === consts.ADMIN,
    },
    {
      title: 'Remove as Admin',
      role: consts.ROLE_USER,
      icon: <DeleteIcon sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
      handleMenuItemClick: handleAdminRole,
      disabled: currentTableRow.role === consts.USER,
    },
  ];

  const renderMoreVertIcon = (params) => {
    return (
      <Box>
        <BootstrapTooltip title="Modify Role" placement="left">
          <IconButton
            sx={{ cursor: 'pointer' }}
            aria-label="Modify Role"
            onClick={(e) => {
              e.stopPropagation();
              setCurrentTableRow(params?.row);
              setAnchorEl(e.currentTarget);
            }}
          >
            <MoreVertIcon fontSize="small" />
          </IconButton>
        </BootstrapTooltip>
        <Menu
          id="admin-menu"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          PaperProps={{
            sx: {
              minWidth: 200,
              'ul li:nth-of-type(1)': {
                borderBottom: `1px solid rgba(0, 0, 0, 0.12)`,
              },
              boxShadow: '0px 1px 4px #c6c6c6',
            },
          }}
        >
          {adminMenuItems.map(({ title, icon, role, handleMenuItemClick, disabled }) => (
            <MenuItem
              onClick={() => {
                handleMenuItemClick(role);
              }}
              key={title}
              disabled={disabled}
            >
              {icon}
              <Typography variant="caption" flex={1} pl={2} fontSize="1rem" color={theme.palette.primary.textColor}>
                {title}
              </Typography>
            </MenuItem>
          ))}
        </Menu>
      </Box>
    );
  };

  const handleOnSearch = (e) => {
    e.preventDefault();
    dispatch(getSearchedUserManagementTableRowsSuccess({}));
    dispatch(getUserManagementTableCurrentPage(0));
    dispatch(isUserManagementTableSearched(true));
    dispatch(searchUser(searchText, { page: 0, pageSize: 10 }));
  };

  const handleSearchReset = () => {
    setSearchText('');
    dispatch(isUserManagementTableSearched(false));
    dispatch(getUserManagementTableCurrentPage(0));
    dispatch(getUserManagementTableData({ page: 0, pageSize: 10 }));
  };

  const onPageChange = (page) => {
    dispatch(getUserManagementTableCurrentPage(page));
    if (isTableDataSearched) {
      dispatch(searchUser(searchText, { page, pageSize: 10 }));
    } else {
      dispatch(getUserManagementTableData({ page, pageSize: 10 }));
    }
  };

  const columns = [
    {
      field: 'id',
      renderHeader: () => <strong>ID</strong>,
      sortable: false,
      flex: 0.3,
      renderCell: (params) => <DataGridCellExpand value={params?.row?.id ?? ''} />,
    },
    {
      field: 'name',
      renderHeader: () => <strong>Name</strong>,
      sortable: false,
      flex: 1,
      renderCell: (params) => <DataGridCellExpand value={params?.row?.name ?? ''} />,
    },
    {
      field: 'email',
      renderHeader: () => <strong>Email</strong>,
      sortable: false,
      flex: 1.2,
      renderCell: (params) => <DataGridCellExpand value={params?.row?.email ?? ''} />,
    },
    {
      field: 'role',
      renderHeader: () => <strong>Role</strong>,
      sortable: false,
      flex: 0.8,
    },
    {
      field: 'modifiedOn',
      renderHeader: () => <strong>Modified on</strong>,
      sortable: false,
      flex: 1,
      renderCell: (params) => <DataGridCellExpand value={params?.row?.modifiedOn ?? ''} />,
    },
    {
      field: 'modifiedBy',
      renderHeader: () => <strong>Modified by</strong>,
      sortable: false,
      flex: 1,
      renderCell: (params) => <DataGridCellExpand value={params?.row?.modifiedBy ?? ''} />,
    },
    {
      field: 'modifyRole',
      headerName: '',
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: renderMoreVertIcon,
      flex: 0.5,
    },
  ];

  return (
    <UserManagementView
      rows={tableRows}
      columns={columns}
      rowCount={rowCount}
      anchorEl={anchorEl}
      searchText={searchText}
      page={paginationModel.page}
      pageSize={paginationModel.pageSize}
      handlers={{
        onPageChange,
        setSearchText,
        handleOnSearch,
        handleSearchReset,
      }}
    />
  );
}
