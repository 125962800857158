import React, { useEffect, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { v4 as uuidv4 } from 'uuid';

//app
import * as utils from 'utils';
import AddEditConfigurationFormView from './AddEditConfigurationForm.view';
import { createConfiguration, updateConfiguration, snackbarNotification } from 'stores';

AddEditConfigurationForm.propTypes = {
  data: PropTypes.object,
  submitHandler: PropTypes.func.isRequired,
  cancelButtonLabel: PropTypes.string.isRequired,
  cancelHandler: PropTypes.func.isRequired,
};

export default function AddEditConfigurationForm({ data, submitHandler, cancelButtonLabel, cancelHandler }) {
  const requestId = data?.configId;
  const isUpdateData = requestId ? Boolean(data?.product && data?.service) : false;

  const dispatch = useDispatch();

  const [currentPlatform, setCurrentPlatform] = useState(isUpdateData ? data?.platform.toLowerCase() : 'jira');
  const [objectTypeChips, setObjectTypeChips] = useState([]);

  const platforms = useMemo(
    () => [
      { value: 'jira', label: 'Jira' },
      { value: 'polarion', label: 'Polarion' },
      { value: 'tfs', label: 'TFS' },
    ],
    []
  );
  const isPlatformIsTFS = platforms[2]?.value === currentPlatform;
  const isPlatformIsPolarion = platforms[1]?.value === currentPlatform;

  const polarionObjectTypeDefaultValue = isUpdateData && isPlatformIsPolarion ? data?.objectTypes : 'PR';

  const [currentPolarionObjectType, setCurrentPolarionObjectType] = useState(polarionObjectTypeDefaultValue);

  const productDefultValue = isUpdateData ? data?.product : '';
  const serviceDefaultValue = isUpdateData ? data?.service : '';
  const platformDefaultValue = isUpdateData ? data?.platform.toLowerCase() : 'jira';
  const platformURIDefaultValue = isUpdateData ? data?.platformURI : '';
  const platformAPIKeysDefaultValue = isUpdateData ? data?.platformApiKey : '';
  const objectTypeDefaultValue = isUpdateData ? data?.objectTypes : '';
  const projectKeyDefaultValue = isUpdateData ? data?.projectKey : '';
  const emailDefaultValue = isUpdateData ? data?.email : '';
  const tfsProjectDefaultValue = isUpdateData ? data?.project : '';
  const tfsOrganizationDefaultValue = isUpdateData ? data?.organization : '';

  const polarionObjectTypes = useMemo(() => [{ id: uuidv4(), value: 'PR', label: 'PR' }], []);

  const capitalizeFirstLetter = (str) => {
    // converting first letter to uppercase
    return str?.charAt(0)?.toUpperCase() + str?.slice(1);
  };

  const getIsObjectTypeIsRequired = () => !objectTypeChips?.length;

  const fields = [
    {
      name: 'product',
      id: 'product',
      size: 'small',
      required: true,
      disabled: isUpdateData || false,
      defaultValue: productDefultValue,
      validation: Yup.string().required('Please enter the product'),
      inputProps: {
        style: {
          cursor: isUpdateData ? 'not-allowed' : 'default',
        },
      },
      sx: { textAlign: 'left', width: '100%', background: isUpdateData ? '#f0f0f0' : 'none', borderRadius: '2px' },
    },
    {
      name: 'service',
      id: 'service',
      size: 'small',
      required: true,
      disabled: isUpdateData || false,
      defaultValue: serviceDefaultValue,
      validation: Yup.string().required('Please enter the service'),
      inputProps: {
        style: {
          cursor: isUpdateData ? 'not-allowed' : 'default',
        },
      },
      sx: {
        textAlign: 'left',
        width: '100%',
        borderRadius: '2px',
        background: isUpdateData ? '#f0f0f0' : 'none',
      },
    },
    {
      name: 'email',
      id: 'email',
      size: 'small',
      required: true,
      disabled: false,
      defaultValue: emailDefaultValue,
      validation: Yup.string().required('Please enter a email'),
      sx: { textAlign: 'left', width: '100%' },
    },
    {
      name: 'platform',
      id: 'platform',
      size: 'small',
      required: true,
      disabled: isUpdateData || false,
      defaultValue: platformDefaultValue,
      validation: Yup.string().required('Platform cannot be empty'),
      inputProps: {
        style: {
          cursor: isUpdateData ? 'not-allowed' : 'default',
        },
      },
      sx: { textAlign: 'left', width: '100%', background: isUpdateData ? '#f0f0f0' : 'none', borderRadius: '2px' },
    },
    {
      name: 'platformURI',
      id: 'platformURI',
      size: 'small',
      required: true,
      disabled: false,
      defaultValue: platformURIDefaultValue,
      validation: Yup.string().required('Please enter the platform URI/URL'),
      sx: { textAlign: 'left', width: '100%' },
    },
    {
      name: 'platformApiKey',
      id: 'platformApiKey',
      size: 'small',
      required: true,
      disabled: false,
      defaultValue: platformAPIKeysDefaultValue,
      validation: Yup.string().required('Please enter the platform API key'),
      sx: { textAlign: 'left', width: '100%' },
    },
    {
      name: 'objectType',
      id: 'objectType',
      size: 'small',
      required: getIsObjectTypeIsRequired(),
      disabled: false,
      defaultValue: '',
      validation: getIsObjectTypeIsRequired() ? Yup.string().required('Please enter the object type(s)') : Yup.string(),
      sx: { textAlign: 'left', width: '100%', borderRadius: '2px' },
    },
  ];

  if (isPlatformIsTFS) {
    fields.push(
      {
        name: 'project',
        id: 'project',
        size: 'small',
        required: true,
        disabled: false,
        defaultValue: tfsProjectDefaultValue,
        validation: Yup.string().required('Please enter the project'),
        sx: {
          textAlign: 'left',
          width: '100%',
        },
      },
      {
        name: 'organization',
        id: 'organization',
        size: 'small',
        required: true,
        disabled: false,
        defaultValue: tfsOrganizationDefaultValue,
        validation: Yup.string().required('Please enter the organization'),
        sx: {
          textAlign: 'left',
          width: '100%',
        },
      }
    );
  }
  if (!isPlatformIsTFS && !isPlatformIsPolarion) {
    fields.push({
      name: 'projectKey',
      id: 'projectKey',
      size: 'small',
      required: true,
      disabled: false,
      defaultValue: projectKeyDefaultValue,
      validation: Yup.string().required('Please enter the project key'),
      sx: {
        textAlign: 'left',
        width: '100%',
      },
    });
  }
  if (isPlatformIsPolarion) {
    fields.splice(6, 1);
  }

  const defaultValues = utils.form.getInitialValues(fields);
  const validationSchema = utils.form.getValidationSchema(fields);

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm({
    defaultValues,
    ...(validationSchema && { resolver: yupResolver(validationSchema) }),
  });

  const watchPlatform = watch('platform');
  const watchObjectTypes = watch('objectType');

  useEffect(() => {
    if (objectTypeDefaultValue?.length) {
      setObjectTypeChips(
        objectTypeDefaultValue
          ?.split(',')
          ?.filter((i) => i)
          ?.map((label) => ({
            id: uuidv4(),
            label,
          }))
      );
      setValue('objectType', ' ');
    }
  }, [objectTypeDefaultValue, setValue]);

  useEffect(() => {
    if (watchPlatform) {
      setCurrentPlatform(watchPlatform);
    }
  }, [watchPlatform]);

  const handleObjectTypes = () => {
    if (watchObjectTypes?.trim()) {
      const trimedObjectTypes = watchObjectTypes?.replace(/\s/g, '');
      const chips = trimedObjectTypes
        ?.split(',')
        ?.filter((i) => i)
        ?.map((label) => ({
          id: uuidv4(),
          label: capitalizeFirstLetter(label),
        }));
      setObjectTypeChips((prevChips) => [...prevChips, ...chips]);
      setValue('objectType', ' ');
    } else {
      setValue('objectType', '');
    }
  };

  const handleDeleteObjectTypes = (chipToDelete) => {
    const filteredChips = objectTypeChips.filter((currentChip) => currentChip.id !== chipToDelete.id);
    if (!filteredChips?.length) {
      setValue('objectType', '');
    }
    setObjectTypeChips(filteredChips);
  };

  const onEnterKeyPressOnObjectTypeField = (event) => {
    if (event.key === 'Enter') {
      if (!watchObjectTypes && objectTypeChips?.length) {
        setValue('objectType', ' ');
      } else {
        handleObjectTypes();
      }
    }
  };

  const onSubmit = (formData) => {
    const prepareFinalPayload = () => {
      if (isPlatformIsTFS || isPlatformIsPolarion) {
        delete formData['projectKey'];
      }
      if (!isPlatformIsTFS) {
        delete formData['project'];
        delete formData['organization'];
      }
      delete formData['objectType'];
      return {
        ...formData,
        ...(data && { configId: requestId }),
        ...(!isPlatformIsPolarion && { objectTypes: objectTypeChips.map((i) => i.label).join(',') }),
        ...(isPlatformIsPolarion && { objectTypes: currentPolarionObjectType }),
        dateAndTime: new Date(),
      };
    };

    const finalReqPayload = prepareFinalPayload();

    if (data) {
      dispatch(updateConfiguration(finalReqPayload)).then((res) => {
        if (res?.status === 201 || res?.status === 200) {
          submitHandler(finalReqPayload?.configId);
        } else {
          dispatch(snackbarNotification(res?.response?.data?.message || 'Configuration updation failed', 'error'));
        }
      });
    } else {
      dispatch(createConfiguration(finalReqPayload)).then((res) => {
        if (res?.status === 201 || res?.status === 200) {
          submitHandler();
        } else {
          dispatch(snackbarNotification(res?.response?.data?.message || 'Configuration creation failed', 'error'));
        }
      });
    }
  };

  return (
    <AddEditConfigurationFormView
      fields={fields}
      platforms={platforms}
      isPlatformIsTFS={isPlatformIsTFS}
      objectTypeChips={objectTypeChips}
      isPlatformIsPolarion={isPlatformIsPolarion}
      polarionObjectTypes={polarionObjectTypes}
      editFormData={data}
      cancelButtonLabel={cancelButtonLabel}
      currentPolarionObjectType={currentPolarionObjectType}
      formProps={{ errors, register, handleSubmit, control }}
      handlers={{
        onSubmit,
        cancelHandler,
        handleObjectTypes,
        handleDeleteObjectTypes,
        setCurrentPolarionObjectType,
        onEnterKeyPressOnObjectTypeField,
      }}
    />
  );
}
