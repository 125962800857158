const initialState = {
  userManagementTableData: {},
  searchedUserManagementTableData: {},
  isSearched: false,
  currentPageNumber: 0,
};

const userManagementReducers = (state = initialState, action = {}) => {
  switch (action.type) {
    case 'GET_USER_MANAGEMENT_TABLE_ROWS_SUCCESS':
      return {
        ...state,
        userManagementTableData: action.payload,
      };
    case 'GET_SEARCHED_USER_MANAGEMENT_TABLE_ROWS_SUCCESS':
      return {
        ...state,
        searchedUserManagementTableData: action.payload,
      };
    case 'IS_USER_MANAGEMENT_TABLE_SEARCHED':
      return {
        ...state,
        isSearched: action.payload,
      };
    case 'GET_USER_MANAGEMENT_CURRENT_PAGE_NUMBER':
      return {
        ...state,
        currentPageNumber: action.payload,
      };
    default:
      return state;
  }
};

export default userManagementReducers;
