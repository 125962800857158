import React from 'react';
import axios from 'axios';

// app
import { AppView } from './App.view';

const interceptor = () => {
  axios.interceptors.request.use(
    (req) => {
      return req;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response?.status === 401) {
        return Promise.reject(error);
      }
      return Promise.reject(error);
    }
  );
};

interceptor();

export default function App() {
  return <AppView />;
}
