import { createTheme } from '@mui/material';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#009999',
      backgroundColor: '#f3f3f8',
      sidebarBackground: '#004241',
      titleColor: '#33378f',
      textColor: '#5a5a5a',
      white: '#fff',
      tableTopHeader: '#5a5a5f',
      requestHeaderTextColor: '#686868',
      requestCountTextColor: '#262626',
      statusBadge: '#a1a1a1',
    },
    secondary: {
      main: '#54c8f0',
    },
    otherColor: {
      main: '#999',
      errorText: '#D32F2F',
      blue10: '#f0f1f7',
      blue30: '#9C9ECA',
      blue50: '#6366AD',
      blue70: '#33378F',
      blue90: '#171C8F',
      blue95: '#0F1362',
      grey90: '#1E1F20',
      textGrey1: '#343536',
      textGrey2: '#66696B',
      textGrey3: '#9EA1A7',
      silver30: '#DADCE0',
      silver50: '#CED0D4',
      silver75: '#7A7C7E',
      silver90: '#97999B',
    },
  },
  typography: {
    fontFamily: 'Open Sans',
  },
});
