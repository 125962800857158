import React from 'react';
import { useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

//app
import { consts, config } from 'globalsData';
import { accessToken } from 'utils/api/api';

//mui
import { Box, Grid, Typography, Button } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';

export default function AccessControl({ children }) {
  const navigate = useNavigate();

  const token = accessToken();
  const decoded = jwtDecode(token);

  const isAdmin = decoded?.ADMIN || false;

  if (!isAdmin) {
    return (
      <Box textAlign="center" mt="5%">
        <Grid container direction="column" justifyContent="center" alignItems="center">
          <Grid item>
            <LockIcon sx={{ fontSize: '6rem' }} />
            <Typography fontWeight="600" fontSize="50px" variant="body1" color={(theme) => theme.palette.otherColor.grey90}>
              Access Denied
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h2" fontSize="16px" color={(theme) => theme.palette.otherColor.grey90} mb={2}>
              You don't have permission to view this page
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={() => {
                navigate(`${config.routes.requestTracking.root}/${consts.MY_REQUEST}`);
              }}
              sx={{ textTransform: 'none' }}
            >
              Go back to Request Tracking page
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  }

  return children;
}
