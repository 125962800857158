import { api } from 'utils';
import { snackbarNotification, removeLoader, addLoader, getSAMLSSOSettingsSuccess } from 'stores';

export const getSAMLSSOSettings = () => async (dispatch) => {
  dispatch(addLoader('getSAMLSSOSettings'));
  try {
    try {
      const res = await api.get({
        endpoint: `${process.env.REACT_APP_CBT_SERVICE}`,
        path: 'saml/configuration',
      });
      dispatch(getSAMLSSOSettingsSuccess(res?.data));
      return res;
    } catch (error) {
      dispatch(snackbarNotification(error?.response?.data?.message || 'Failed to get SAML SSO Settings', 'error'));
      return error;
    }
  } finally {
    dispatch(removeLoader('getSAMLSSOSettings'));
  }
};
