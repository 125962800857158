import { api } from 'utils';
import { snackbarNotification, removeLoader, addLoader } from 'stores';

export const createConfiguration = (configFormData) => async (dispatch) => {
  if (!configFormData) return Promise.reject({ message: 'Form Data are missing' });
  dispatch(addLoader({ key: 'creatingConfiguration', message: 'Creating Configuration...' }));

  try {
    try {
      const res = await api.post({
        endpoint: `${process.env.REACT_APP_CBT_SERVICE}`,
        path: 'admin/config',
        data: configFormData,
      });
      return res;
    } catch (error) {
      dispatch(snackbarNotification(error?.response?.data?.message || error?.message, 'error'));
      return error;
    }
  } finally {
    dispatch(removeLoader('creatingConfiguration'));
  }
};
