import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm, useFieldArray } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { isEqual } from 'lodash';
import { useDispatch } from 'react-redux';

//app
import {
  createObjectConfiguration,
  getObjectConfiguration,
  updateObjectConfiguration,
  deleteObjectConfiguration,
  snackbarNotification,
} from 'stores';
import AddEditObjectTypesConfigurationFormView from './AddEditObjectTypesConfigurationForm.view';

AddEditObjectTypesConfigurationForm.propTypes = {
  data: PropTypes.object,
  submitHandler: PropTypes.func.isRequired,
  cancelButtonLabel: PropTypes.string.isRequired,
  cancelHandler: PropTypes.func.isRequired,
};

export default function AddEditObjectTypesConfigurationForm({ data, submitHandler, cancelButtonLabel, cancelHandler }) {
  const dispatch = useDispatch();

  const [currentObjectType, setCurrentObjectType] = useState('');
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [isDataAvailable, setIsDataAvailable] = useState(false);
  const [currentFormDeafult, setCurrentFormDeafult] = useState([]);

  const configId = data?.configId;
  const isUpdateData = configId ? Boolean(data?.product && data?.service) : false;
  const objectTypeList = isUpdateData ? data?.objectTypes : '';

  let dropDownObjectTypeList = [];
  if (objectTypeList) {
    dropDownObjectTypeList = objectTypeList
      ?.split(',')
      ?.filter((i) => i)
      ?.map((item) => ({
        id: uuidv4(),
        value: item,
        label: item,
      }));
    dropDownObjectTypeList.unshift({ id: uuidv4(), value: '', label: 'Select Object Type' });
  }

  const displayFlagList = useMemo(
    () => [
      {
        id: uuidv4(),
        value: 'true',
        label: 'true',
      },
      {
        id: uuidv4(),
        value: 'false',
        label: 'false',
      },
    ],
    []
  );

  const mandatoryFieldList = useMemo(
    () => [
      {
        id: uuidv4(),
        value: 'mandatory',
        label: 'Yes',
      },
      {
        id: uuidv4(),
        value: 'optional',
        label: 'No',
      },
    ],
    []
  );

  const getDisplayFlagStatusInString = (val) => (val ? 'true' : 'false');
  const getDisplayFlagStatusInBoolean = (val) => val === 'true';
  const getFiltereFieldDataToAppend = (allFields, objectType) => {
    return allFields
      ?.flatMap((i) => i)
      ?.filter((item) => item?.cbtObjectTypeId?.objectType === objectType)
      ?.map((item) => {
        let filteredDataFieldsToAppend = { ...item, objectType: item?.cbtObjectTypeId?.objectType };
        delete filteredDataFieldsToAppend['cbtObjectTypeId'];
        return { ...filteredDataFieldsToAppend, displayFlag: getDisplayFlagStatusInString(item?.displayFlag) };
      });
  };
  const fieldDefaultData = {
    dataType: '',
    fieldId: '',
    fieldName: '',
    fieldType: 'mandatory',
    defaultValue: '',
    displayFlag: 'true',
    cbtObjectFieldId: null,
  };

  const {
    control,
    register,
    reset,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      formRowsData: [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: 'formRowsData',
    control,
  });

  useEffect(() => {
    if (isDataAvailable) {
      setFormIsDirty(false);
    }
  }, [isDataAvailable]);

  const handleFormState = () => {
    const currentFormData = watch('formRowsData');
    const isformIsDirty = !isEqual(currentFormDeafult, currentFormData);

    if (currentFormData?.length && !isDataAvailable) {
      setFormIsDirty(isformIsDirty);
    } else if (currentFormData?.length && isDataAvailable) {
      setFormIsDirty(isformIsDirty);
    } else if (!currentFormData?.length && isDataAvailable) {
      setFormIsDirty(isformIsDirty);
    } else {
      setFormIsDirty(false);
    }
  };

  const handleObjectTypeDropDown = (e) => {
    if (formIsDirty) {
      alert('Please save or discard the changes made');
      return;
    }
    const objectType = e.target.value;
    setCurrentObjectType(objectType);
    reset();
    const fieldDefaultDataObj = [
      {
        ...fieldDefaultData,
        objectType,
      },
    ];

    if (objectType && configId) {
      dispatch(getObjectConfiguration(configId))?.then((res) => {
        if (res?.status === 200) {
          if (Object.keys(res?.data)?.length) {
            const currentFormaData = getFiltereFieldDataToAppend(res?.data, objectType);
            if (currentFormaData?.length) {
              setIsDataAvailable(true);
              setCurrentFormDeafult(currentFormaData);
              append(currentFormaData);
            } else {
              setIsDataAvailable(false);
              setCurrentFormDeafult(fieldDefaultDataObj);
              append(fieldDefaultDataObj);
            }
          } else {
            setIsDataAvailable(false);
            setCurrentFormDeafult(fieldDefaultDataObj);
            append(fieldDefaultDataObj);
          }
        }
      });
    } else {
      remove();
    }
  };

  const handleAddRow = () => {
    append([
      {
        ...fieldDefaultData,
        objectType: currentObjectType,
      },
    ]);
  };

  const handleDeleteRow = (rowIndexToDelete) => {
    remove(rowIndexToDelete);
    handleFormState();
  };

  const onSubmit = (formData) => {
    //Delete all
    if (currentObjectType && isDataAvailable && !formData?.formRowsData?.length) {
      const deleteAllPayload = {
        fields: currentFormDeafult?.map((item) => ({ cbtObjectFieldId: item?.cbtObjectFieldId })),
      };
      if (deleteAllPayload?.fields?.length) {
        dispatch(deleteObjectConfiguration(deleteAllPayload))?.then((res) => {
          if (res?.status === 200) {
            reset();
            setIsDataAvailable(false);
            setCurrentFormDeafult([]);
            setFormIsDirty(false);
          }
        });
      }
      return;
    }

    const services = [];
    const savedCbtObjectFieldIds = currentFormDeafult?.map((data) => data?.cbtObjectFieldId);

    if (formData?.formRowsData?.length) {
      const alreadySavedRowsFromForm = formData.formRowsData?.filter((item) => savedCbtObjectFieldIds?.includes(item?.cbtObjectFieldId));
      const savedCbtObjectFieldIdsFromForm = alreadySavedRowsFromForm?.map((data) => data?.cbtObjectFieldId);

      if (isDataAvailable) {
        //Delete
        const deletedRows = currentFormDeafult?.filter((item) => !savedCbtObjectFieldIdsFromForm?.includes(item?.cbtObjectFieldId));
        if (deletedRows?.length) {
          const deletePayload = {
            fields: deletedRows.map((item) => ({ cbtObjectFieldId: item?.cbtObjectFieldId })),
          };
          services.push({ delete: true, deletePayload });
        }

        //Edit
        const editedFieldsFromForm = alreadySavedRowsFromForm?.filter((formField) => {
          let fieldSaved = currentFormDeafult?.find((item) => item?.cbtObjectFieldId === formField?.cbtObjectFieldId);
          return !isEqual(formField, fieldSaved);
        });

        if (editedFieldsFromForm?.length) {
          const editpayload = {
            configId,
            fields: editedFieldsFromForm?.map((item) => ({ ...item, displayFlag: getDisplayFlagStatusInBoolean(item.displayFlag) })),
          };
          services.push({ edit: true, editpayload });
        }
      }

      //Create
      const newlyAddedRows = formData.formRowsData?.filter((item) => item?.cbtObjectFieldId === null);
      if (newlyAddedRows?.length) {
        const createPayload = {
          configId,
          fields: newlyAddedRows?.map((item) => {
            delete item['cbtObjectFieldId'];
            return {
              ...item,
              displayFlag: getDisplayFlagStatusInBoolean(item.displayFlag),
            };
          }),
        };
        services.push({ create: true, createPayload });
      }
    }

    const promiseList = [];
    const deleteStatus = services?.find((item) => item?.delete);
    const editStatus = services?.find((item) => item?.edit);
    const createStatus = services?.find((item) => item?.create);

    if (deleteStatus?.delete) {
      promiseList.push(dispatch(deleteObjectConfiguration(deleteStatus?.deletePayload)));
    }
    if (editStatus?.edit) {
      promiseList.push(dispatch(updateObjectConfiguration(editStatus?.editpayload)));
    }
    if (createStatus?.create) {
      promiseList.push(dispatch(createObjectConfiguration(createStatus?.createPayload)));
    }

    Promise.all(promiseList).then((values) => {
      if (values?.length) {
        values?.forEach((item) => {
          if (item?.config?.method === 'post') {
            if (item?.status === 201) {
              dispatch(snackbarNotification(item?.data?.statusMessage || 'Created object configuration successfully', 'success'));
            } else {
              dispatch(snackbarNotification(item?.data?.statusMessage || 'Failed to create object configuration', 'error'));
            }
          } else if (item?.config?.method === 'delete') {
            if (item?.status === 200) {
              dispatch(snackbarNotification(item?.data?.statusMessage || 'Deleted object configuration successfully', 'success'));
            } else {
              dispatch(snackbarNotification(item?.data?.statusMessage || 'Failed to delete object configuration', 'error'));
            }
          } else if (item?.config?.method === 'put') {
            if (item?.status === 200) {
              dispatch(snackbarNotification(item?.data?.statusMessage || 'Updated object configuration successfully', 'success'));
            } else {
              dispatch(snackbarNotification(item?.data?.statusMessage || 'Failed to update object configuration', 'error'));
            }
          }
        });

        const apisSatus = values?.map((data) => data?.status);
        const isAllApicallsAreSuccess = apisSatus?.every((status) => status === 200 || status === 201);

        if (isAllApicallsAreSuccess) {
          dispatch(getObjectConfiguration(configId))?.then((res) => {
            if (res?.status === 200) {
              const currentFormaData = getFiltereFieldDataToAppend(res?.data, currentObjectType);
              reset();
              remove();
              setIsDataAvailable(true);
              setCurrentFormDeafult(currentFormaData);
              append(currentFormaData);
            }
          });
        }
      } else if (currentObjectType) {
        dispatch(snackbarNotification('Object configuration(s) already has been saved successfully', 'success'));
      } else {
        dispatch(snackbarNotification('Please select Object type', 'error'));
      }
      setFormIsDirty(false);
    });
  };

  return (
    <AddEditObjectTypesConfigurationFormView
      fields={fields}
      cancelButtonLabel={cancelButtonLabel}
      dropDownObjectTypeList={dropDownObjectTypeList}
      currentObjectType={currentObjectType}
      displayFlagList={displayFlagList}
      mandatoryFieldList={mandatoryFieldList}
      formProps={{ errors, register, handleSubmit, control }}
      handlers={{
        cancelHandler,
        setCurrentObjectType,
        handleObjectTypeDropDown,
        handleFormState,
        handleAddRow,
        handleDeleteRow,
        onSubmit,
      }}
    />
  );
}
